import { useTranslation } from "react-i18next";
import { LoadingIcon } from "./loading-icon";
import { history } from "src/stores";

export const LoginUsernameForm = (props: any) => {
  const { t } = useTranslation();
  const { username, setUsername, handleCheckUsername, loading } = props;
  return (
    <div>
      <p className="content">{t("username")}</p>
      <input
        placeholder={t("account")}
        className="text-input drop-shadow"
        value={username}
        onChange={(e) => setUsername(e.target?.value)}
      />
      <div className="mt-[45px]">
        <button className="button-gradient" onClick={() => handleCheckUsername()}>
          {loading ? <LoadingIcon size={"25"} /> : t("button.continue")}
        </button>
      </div>
      <div className="flex mt-[15px]">
        <h4>{t("without_account")}</h4>
        <p
          className="text-[14px] text-primary hover:text-primary no-underline cursor-pointer ml-[5px]"
          onClick={() => history.push("/apply-now")}
        >
          {t("apply_here")}
        </p>
      </div>
    </div>
  );
};

