import { getAssetName, getCurrencyIcon } from "src/utils";
import { CURRENCY_LIST } from "src/config/currency";
import { IOnshoreAccount } from "src/types/onshoreAccount";
import { Tooltip } from "antd";
import { IUser } from "src/types/user";
import { useTranslation } from "react-i18next";

interface IOnshoreWalletTokenProps {
  setAction: (value: string) => void;
  onshoreAccounts: IOnshoreAccount[];
  selectedAcc: IOnshoreAccount | null | undefined;
  user: IUser;
  resetFormCb?: () => void;
  setShowActivateAccModal: (val: boolean) => void;
  setConfirmOpenAccModal: (val: boolean) => void;
}

export const OnshoreWalletToken = (props: IOnshoreWalletTokenProps) => {
  const { t } = useTranslation();
  const {
    onshoreAccounts,
    setAction,
    resetFormCb,
    selectedAcc,
    user,
    setShowActivateAccModal,
    setConfirmOpenAccModal,
  } = props;

  const handleAction = (value: string) => {
    // Reset form amount when deposit form is selected
    resetFormCb && resetFormCb();
    setAction(value);
  };

  return (
    <div className="mt-m pr-0 lg:pr-m w-full">
      {CURRENCY_LIST.length > 0 &&
        CURRENCY_LIST.map((item: any, i: number) => {
          const isActivate = onshoreAccounts.find(
            (acc) => acc.currency === item
          );
          const balance = isActivate?.amount;
          const freezeAmount = isActivate?.freeze_amount;
          const currency = isActivate?.currency.toLowerCase() || "";
          const isSelecting = selectedAcc?.currency === item;
          return (
            <div
              className={`flex justify-between items-center ${
                i === CURRENCY_LIST.length - 1 ? "" : `mb-xs`
              }`}
              key={i}
            >
              <div className="flex flex-row items-center">
                <div className="overflow-hidden rounded-full shadow-lg">
                  <img
                    src={getCurrencyIcon(item)}
                    alt="currency"
                    className="w-m h-m object-fit"
                  />
                </div>
                <p className="pl-xs lg:mr-0 mr-xxxs">
                  {getAssetName(item)}
                  <span className="text-xs text-primary ml-xxxs">
                    {isSelecting && t("onshore_wallet.selecting")}
                  </span>
                </p>
              </div>
              <div className="flex flex-row flex-1 justify-end">
                {isActivate && (
                  <>
                    <p className={$titleStyle}>
                      {/* onShore Balance */}
                      {balance ? Number(balance).toFixed(2) : "0.00"}
                      {getAssetName(item)}
                    </p>
                    <Tooltip title={t("card.freeze_balance")}>
                      <p
                        className={`${$titleStyle} !text-link underline decoration-dotted cursor-pointer ml-xs`}
                      >
                        {freezeAmount} {getAssetName(item)}
                      </p>
                    </Tooltip>
                    <p
                      className={`${$titleStyle} !text-primary cursor-pointer ml-xs`}
                      onClick={() => setAction(`detail_${currency}`)}
                    >
                      {t("onshore_wallet.account_detail")}
                    </p>
                    <p
                      className={`${$titleStyle} !text-primary cursor-pointer ml-xs`}
                      onClick={() => {
                        if (!user?.kyc_level || user?.kyc_level === "0") {
                          return setShowActivateAccModal(true);
                        }
                        handleAction(`deposit_${currency}`);
                      }}
                    >
                      {t("onshore_wallet.deposit")}
                    </p>
                    <p
                      className={`${$titleStyle} !text-primary cursor-pointer ml-xs`}
                      onClick={() => {
                        if (!user?.kyc_level || user?.kyc_level === "0") {
                          return setShowActivateAccModal(true);
                        }
                        setAction(`withdraw_${currency}`);
                      }}
                    >
                      {t("onshore_wallet.withdraw")}
                    </p>
                  </>
                )}

                {!isActivate && (
                  <>
                    <p
                      className={`${$titleStyle} !text-primary cursor-pointer ml-xs`}
                      onClick={() => {
                        setConfirmOpenAccModal(true);
                        setAction(`open_account_${item}`);
                        return;
                      }}
                    >
                      {t("onshore_wallet.open_account")}
                    </p>
                  </>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

const $titleStyle = "text-txt text-xs text-right";
