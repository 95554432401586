import { t } from "i18next";
import moment from "moment";
import { openWarningNotification } from "src/components";

export const checkEmpty = (array: string[]) => {
  for (let item of array) {
    if (item.length === 0) {
      return false;
    }
  }
  return true;
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateFormData = (formData: any) => {
  for (const [key, value] of Object.entries(formData)) {
    // Check Is Field Empty
    if (key !== "addressLine2" && !value) {
      // Handle corresponding camelCase keys for i18n
      let field = key;
      switch (key) {
        case "lastName":
          field = "surname_en";
          break;
        case "firstName":
          field = "first_name_en";
          break;
        case "lastNameTc":
          field = "surname_tc";
          break;
        case "firstNameTc":
          field = "first_name_tc";
          break;
        case "addressLine1":
          field = "address_line_1";
          break;
        case "zipCode":
          field = "zip_code";
          break;
        case "countryCode":
          field = "country_code";
          break;
        case "phoneNum":
          field = "phone_num";
          break;
        case "income":
          field = "annual_income";
          break;
        case "fundsSource":
          field = "funds_source";
          break;
        case "documentType":
          field = "document_type";
          break;
        case "documentId":
          field = "document_id";
          break;
        case "documentIdExpiryDate":
          field = "document_id_expiry_date";
          break;
        default:
          break;
      }
      return openWarningNotification(
        t("message.missing_required_field", { field: t(`profile.${field}`) })
      );
    }
  }

  // Email Validation
  if (!validateEmail(formData.email))
    return openWarningNotification(t("message.email_format_incorrect"));

  // Date Validation
  if (moment(formData.dob).isAfter(moment()))
    return openWarningNotification(
      t("message.invalid_date_range", { field: t("profile.dob") })
    );
  if (moment(formData.documentIdExpiryDate).isBefore(moment()))
    return openWarningNotification(
      t("message.invalid_date_range", {
        field: t("profile.documentIdExpiryDate"),
      })
    );

  return true;
};
