import { useMemo, useState } from "react";
import {
  TXN_TYPE_LIST,
  TXN_STATUS_LIST,
  CRYPTO_TXN_TYPE_LIST,
  ONSHORE_WALLET_TYPE_LIST,
} from "src/config/transaction";
import { SelectDropdown } from "./select-dropdown";
import { Button } from "./button";
import { RedoOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export const TransactionFilterButtons = (props: any) => {
  const { t } = useTranslation();
  const { fromPage, setTransactions, getTransactionsCb, setIsLoading } = props;
  const [isSpin, setIsSpin] = useState(false);
  const [txnType, setTxnType] = useState("all");
  const [txnStatus, setTxnStatus] = useState("all");

  const reload = async () => {
    setIsSpin(true);
    setIsLoading(true);

    let body = {
      status: txnStatus,
      type: txnType,
    };

    if (fromPage === "crypto") {
      body.type = "crypto_all";
    }

    if (fromPage === "onshore") {
      body.type = "onshore";
    }

    const result = await getTransactionsCb(body);
    setIsLoading(false);
    setIsSpin(false);
    if (!result) return;
    setTransactions(result);
  };

  const handleOnChange = async (eventType: string, value: string) => {
    // Select Dropdown Hook
    if (eventType === "txn-type") setTxnType(value);
    if (eventType === "txn-status") setTxnStatus(value);

    setIsLoading(true);
    await getTransactionsCb({
      status: eventType === "txn-status" ? value : txnStatus,
      type: eventType === "txn-type" ? value : txnType,
    });
    setIsLoading(false);
  };

  const selectTxnTypeList = useMemo(() => {
    if (!fromPage) return;
    if (fromPage === "crypto") return CRYPTO_TXN_TYPE_LIST;
    if (fromPage === "onshore") return ONSHORE_WALLET_TYPE_LIST;

    return TXN_TYPE_LIST; // default show all transaction type
  }, [fromPage]);

  return (
    <div className="mt-xs flex flex-row items-center">
      <div className="flex-1">
        <Button
          value={t("button.reload")}
          txtColorStyle="!text-[#000000A6]"
          suffix={<RedoOutlined spin={isSpin} className="pl-xs" />}
          handleOnClick={() => reload()}
        />
      </div>

      <div className="transaction-select-dropdown hidden flex-2 lg:flex">
        <div className="mr-xs">
          <SelectDropdown
            options={selectTxnTypeList}
            value={t(`type.${txnType}`)}
            handleOnChange={(e: any) => handleOnChange("txn-type", e)}
          />
        </div>

        <SelectDropdown
          options={TXN_STATUS_LIST}
          value={t(`status.${txnStatus}`)}
          handleOnChange={(e: any) => handleOnChange("txn-status", e)}
        />
      </div>
    </div>
  );
};
