import { useState } from "react";
import { OnshoreWalletDepositBankForm } from "./onshore-wallet-deposit-bank-form";
import { OnshoreWalletDepositQRForm } from "./onshore-wallet-deposit-qr-form";
import { OnshoreWalletDepositMethod } from "./onshore-wallet-deposit-method";
import { useTranslation } from "react-i18next";

interface IOnshoreWalletDeposit {
  currency: string;
  reference: string;
  qrCode: string;
  amount: number;
  errorMessage: string;
  setAmount: (value: number) => void;
  setQrCode: (value: string) => void;
  showErrorMessage: (value: string) => void;
}
const qrMethod = ["unionpay", "alipay", "wechatpay"];

export const OnshoreWalletDeposit = (props: IOnshoreWalletDeposit) => {
  const {
    currency,
    amount,
    qrCode,
    reference,
    errorMessage,
    setAmount,
    setQrCode,
    showErrorMessage,
  } = props;
  const { t } = useTranslation();
  
  const [method, setMethod] = useState<string>("unionpay");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleMethod = async (name: string) => {
    setMethod(name);
    // Reset to default when the method has changed
    setAmount(0);
    showErrorMessage("");
    if (qrMethod.indexOf(method) > -1) setQrCode("");
    return;
  };

  return (
    <div className="w-full bg-white rounded-m drop-shadow p-m">
      <h3 className="normal-case">
      {`${t(`onshore_wallet.deposit`)}${t((currency).toLowerCase())}`}
      </h3>

      {/* Payment Methods */}
      <OnshoreWalletDepositMethod
        fromPage="onshore-deposit"
        isLoading={isLoading}
        method={method}
        handleOnClick={handleMethod}
      />

      {/* Bank Deposit Form */}
      {method === "bank_deposit" && (
        <OnshoreWalletDepositBankForm
          currency={currency}
          amount={Number(amount)}
          reference={reference}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setAmount={setAmount}
          showErrorMessage={showErrorMessage}
        />
      )}

      {/* Payment QR Form */}
      {qrMethod.indexOf(method) > -1 && (
        <OnshoreWalletDepositQRForm
          currency={currency}
          amount={amount}
          method={method}
          qrCode={qrCode}
          isLoading={isLoading}
          setAmount={setAmount}
          setQrCode={setQrCode}
          setIsLoading={setIsLoading}
          errorMessage={errorMessage}
          showErrorMessage={showErrorMessage}
        />
      )}
    </div>
  );
};
