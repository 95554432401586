import { useTranslation } from "react-i18next";
import { InputField } from "./input-field";
import { IApplicationPanelProps } from "./application-panel";
import moment from "moment";
import { openWarningNotification } from ".";
import { SelectDropdown } from "./select-dropdown";
import { documentTypeList, genderList } from "../config/register-account";
const { DatePicker } = require("antd");

interface IApplicationFormProps {
  formData: IApplicationPanelProps;
  setFormData: (data: IApplicationPanelProps) => void;
}

export const ApplicationForm = (props: IApplicationFormProps) => {
  const { t } = useTranslation();
  const { formData, setFormData } = props;

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    return;
  };

  const today = moment();
  const fortyYearsAgo = today.clone().subtract(40, "years");
  const futureFiveYears = today.clone().add(5, "years");

  const handleDob = (date: any, dateString: any) => {
    if (!date) return setFormData({ ...formData, dob: String(fortyYearsAgo) });

    const formattedDate = moment(dateString).format("MM-DD-YYYY");

    const isTimeAfterToday = moment(dateString).isAfter(moment());
    if (isTimeAfterToday)
      return openWarningNotification(
        t("message.invalid_date_range", {
          field: t("profile.document_id_expiry_date"),
        })
      );

    setFormData({ ...formData, dob: String(formattedDate) });
  };

  const handleDocIdExpiryDate = (date: any, dateString: any) => {
    if (!date)
      return setFormData({
        ...formData,
        documentIdExpiryDate: String(futureFiveYears),
      });

    const formattedDate = moment(dateString).format("MM-DD-YYYY");
    const isTimeBeforeToday = moment(dateString).isBefore(moment());
    if (isTimeBeforeToday)
      return openWarningNotification(
        t("message.invalid_date_range", {
          field: t("profile.document_id_expiry_date"),
        })
      );

    setFormData({ ...formData, documentIdExpiryDate: String(formattedDate) });
  };

  const handleSelect = (value: any, name: string) =>
    setFormData({ ...formData, [name]: value });

  const disabledDate = (
    current: moment.Moment | null,
    name: string
  ): boolean => {
    if (!current) return false;
    if (name === "documentIdExpiryDate")
      return current < today || current > today.clone().add(100, "years");

    if (name === "dob")
      return current > today || current > today.clone().subtract(20, "years");

    return false;
  };

  return (
    <div className="w-full lg:w-[800px] p-m">
      <h3 className="normal-case"> {t("profile.info")}</h3>
      <div className="mt-s">
        <div className="flex flex-row">
          <InputField
            name="username"
            value={formData.username}
            title={t("profile.username")}
            onChange={handleFormChange}
          />
          <div className="flex-1 flex-col ml-l">
            <p className="label pb-xxs">{t("profile.gender")}</p>
            <SelectDropdown
              width="100%"
              className="register-account-select-dropdown !bg-transparent w-full"
              options={genderList}
              value={t(formData.gender)}
              handleOnChange={(value: string) => handleSelect(value, "gender")}
            />
          </div>
        </div>

        <div className="flex mt-xs">
          <InputField
            name="lastName"
            value={formData.lastName}
            title={t("profile.surname_en")}
            onChange={handleFormChange}
          />
          <InputField
            name="firstName"
            value={formData.firstName}
            title={t("profile.first_name_en")}
            customStyle="ml-l"
            onChange={handleFormChange}
          />
        </div>

        <div className="flex mt-xs">
          <InputField
            name="lastNameTc"
            value={formData.lastNameTc}
            title={t("profile.surname_tc")}
            onChange={handleFormChange}
          />
          <InputField
            name="firstNameTc"
            value={formData.firstNameTc}
            title={t("profile.first_name_tc")}
            customStyle="ml-l"
            onChange={handleFormChange}
          />
        </div>

        {/* Address */}
        <div className="flex mt-xs">
          <InputField
            name="addressLine1"
            value={formData.addressLine1}
            title={t("profile.address_line_1")}
            onChange={handleFormChange}
          />
        </div>

        <div className="flex mt-xs">
          <InputField
            name="addressLine2"
            value={formData.addressLine2 || ""}
            title={t("profile.address_line_2")}
            onChange={handleFormChange}
            required={false}
          />
        </div>

        <div className="flex mt-xs">
          <InputField
            name="city"
            value={formData.city}
            title={t("profile.city")}
            onChange={handleFormChange}
          />
          <InputField
            name="state"
            value={formData.state}
            title={t("profile.state")}
            customStyle="ml-l"
            onChange={handleFormChange}
          />
        </div>

        <div className="flex mt-xs">
          <InputField
            name="zipCode"
            type="number"
            value={String(formData.zipCode)}
            title={t("profile.zip_code")}
            onChange={handleFormChange}
          />
          <InputField
            name="country"
            value={formData.country}
            title={t("profile.country")}
            customStyle="ml-l"
            onChange={handleFormChange}
          />
        </div>

        {/* Phone */}
        <div className="flex mt-xs">
          <InputField
            name="countryCode"
            value={formData.countryCode}
            placeholder="+852"
            title={t("profile.country_code")}
            onChange={handleFormChange}
          />
          <InputField
            name="phoneNum"
            value={formData.phoneNum}
            title={t("profile.phone_num")}
            customStyle="ml-l"
            onChange={handleFormChange}
          />
        </div>

        <div className="flex mt-xs">
          {/* DOB */}
          <div className="flex-1">
            <p className="label">{t("profile.dob")}</p>
            <DatePicker
              name="dob"
              onChange={handleDob}
              picker="date"
              defaultPickerValue={moment(fortyYearsAgo, "MM-DD-YYYY")} // Default set 40 years ago
              className="border bg-transparent w-full h-[40px]"
              placeholder={t("please_select")}
              value={
                formData.dob
                  ? moment(formData.dob, "MM-DD-YYYY")
                  : moment(fortyYearsAgo, "MM-DD-YYYY")
              }
              style={{
                borderRadius: 5,
                marginTop: 4,
                borderWidth: 0.5,
              }}
              disabledDate={(value: any) => disabledDate(value, "dob")}
            />
          </div>
          <InputField
            name="email"
            value={formData.email}
            title={t("profile.email")}
            customStyle="ml-l"
            onChange={handleFormChange}
          />
        </div>

        <div className="flex mt-xs">
          <InputField
            name="position"
            value={formData.position}
            title={t("profile.position")}
            onChange={handleFormChange}
          />
          <InputField
            name="occupation"
            value={formData.occupation}
            title={t("profile.occupation")}
            customStyle="ml-l"
            onChange={handleFormChange}
          />
        </div>

        <div className="flex mt-xs">
          <InputField
            name="income"
            value={formData.income}
            title={t("profile.annual_income")}
            onChange={handleFormChange}
          />
          <InputField
            name="fundsSource"
            value={formData.fundsSource}
            title={t("profile.funds_source")}
            customStyle="ml-l"
            onChange={handleFormChange}
          />
        </div>

        {/* KYC Documents */}
        <div className="flex flex-row mt-xs">
          <div className="flex-1 flex-col">
            <p className="label pb-xxs">{t("profile.document_type")}</p>
            <SelectDropdown
              width="100%"
              className="register-account-select-dropdown !bg-transparent focus-within:!outline-none focus:!outline-none"
              options={documentTypeList}
              value={t(formData.documentType)}
              handleOnChange={(value: any) =>
                handleSelect(value, "documentType")
              }
            />
          </div>
          <InputField
            name="nationality"
            value={formData.nationality}
            customStyle="ml-l"
            title={t("profile.nationality")}
            onChange={handleFormChange}
          />
        </div>

        <div className="flex mt-xs">
          <InputField
            name="documentId"
            value={formData.documentId}
            title={t("profile.document_id")}
            onChange={handleFormChange}
          />

          <div className="flex-1 ml-l">
            <p className="label">{t("profile.document_id_expiry_date")}</p>
            <DatePicker
              name="documentIdExpiryDate"
              onChange={handleDocIdExpiryDate}
              picker="date"
              defaultPickerValue={moment(futureFiveYears, "MM-DD-YYYY")} // Default set future 20 years
              className="border bg-transparent w-full h-[40px]"
              value={
                formData.documentIdExpiryDate
                  ? moment(formData.documentIdExpiryDate, "MM-DD-YYYY")
                  : moment(futureFiveYears, "MM-DD-YYYY")
              }
              style={{
                borderRadius: 5,
                marginTop: 4,
                borderWidth: 0.5,
              }}
              disabledDate={(value: any) =>
                disabledDate(value, "documentIdExpiryDate")
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
