import { useEffect, useState } from "react";
import i18n from "src/i18n";
import { SelectDropdown } from "./select-dropdown";
import { LANG_MENU } from "../config/lang-menu";
import { useTranslation } from "react-i18next";

export const ChangeLangButton = (props: any) => {
  const { from = "", width, btnStyle, prefix } = props;
  const [currLang, setCurrLang] = useState("tc");
  const { t } = useTranslation();

  useEffect(() => {
    const lang: any = localStorage.getItem("rich-dragon-language") || "tc";
    setCurrLang(lang);
    i18n.changeLanguage(lang);
  }, []);

  const handleSelect = (lang: string) => {
    localStorage.setItem("rich-dragon-language", lang);
    i18n.changeLanguage(lang);
    setCurrLang(lang);
  };

  return (
    <div
      className={`language-select-dropdown-${from} ${btnStyle}`}
      style={{ transition: "0.7s", boxShadow: "0px 0px 6px 2px #ccc" }}
    >
      <SelectDropdown
        width={`${width}px`}
        value={t(currLang)}
        options={LANG_MENU}
        handleOnChange={(val: any) => handleSelect(val)}
        prefix={prefix}
      />
    </div>
  );
};
