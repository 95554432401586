import { useTranslation } from "react-i18next";

export const UpdateProfilePersonalInfo = (props: any) => {
  const { t } = useTranslation();
  const { user } = props;

  const {
    username,
    dob,
    email,
    country_code,
    phone_num,
    position,
    occupation,
    first_name,
    last_name,
    first_name_tc,
    last_name_tc,
    address,
    gender,
    nationality,
    income,
    funds_source,
    document_type,
    document_id,
    document_id_expiry_date,
  } = user;
  const CustomInputField = ({ value, title, customStyle }: any) => {
    let val = value;
    if (!value) {
      val = "n/a";
    }
    return (
      <div
        className={`w-full ${customStyle ? `${customStyle} flex-1` : "flex-1"}`}
      >
        <p className="content">{title}</p>
        <div className="w-full">
          <input
            className="disabled-text-input"
            type="text"
            value={val}
            disabled={true}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="w-full lg:w-[545px] min-h-[100px] bg-white rounded-s drop-shadow p-lg">
      <h3 className="normal-case"> {t("profile.info")}</h3>
      <div className="mt-l">
        <div className="flex">
          <CustomInputField value={username} title={t("profile.username")} />
          <CustomInputField
            value={gender}
            title={t("profile.gender")}
            customStyle="ml-l"
          />
        </div>

        <div className="flex mt-s">
          <CustomInputField value={last_name} title={t("profile.surname_en")} />
          <CustomInputField
            value={first_name}
            title={t("profile.first_name_en")}
            customStyle="ml-l"
          />
        </div>

        <div className="flex mt-s">
          <CustomInputField
            value={last_name_tc}
            title={t("profile.surname_tc")}
          />
          <CustomInputField
            value={first_name_tc}
            title={t("profile.first_name_tc")}
            customStyle="ml-l"
          />
        </div>

        <div className="flex mt-[10px]">
          <CustomInputField
            value={nationality}
            title={t("profile.nationality")}
          />
          <CustomInputField
            value={email}
            title={t("profile.email")}
            customStyle="ml-[30px]"
          />
        </div>

        <div className="flex mt-[10px]">
          <CustomInputField
            value={"+" + country_code}
            title={t("profile.country_code")}
          />
          <CustomInputField
            value={phone_num}
            title={t("profile.phone_num")}
            customStyle="ml-l"
          />
        </div>

        <div className="flex mt-s">
          <CustomInputField value={dob} title={t("profile.dob")} />
          <CustomInputField
            value={address}
            title={t("profile.residential_address")}
            customStyle="ml-l"
          />
        </div>

        <div className="flex mt-s">
          <CustomInputField value={position} title={t("profile.position")} />
          <CustomInputField
            value={occupation}
            title={t("profile.occupation")}
            customStyle="ml-l"
          />
        </div>

        <div className="flex mt-s">
          <CustomInputField value={income} title={t("profile.annual_income")} />
          <CustomInputField
            value={funds_source}
            title={t("profile.funds_source")}
            customStyle="ml-l"
          />
        </div>

        <div className="flex mt-s">
          <CustomInputField
            value={document_type}
            title={t("profile.document_type")}
          />
        </div>

        <div className="flex mt-s">
          <CustomInputField
            value={document_id}
            title={t("profile.document_id")}
          />
          <CustomInputField
            value={document_id_expiry_date}
            title={t("profile.document_id_expiry_date")}
            customStyle="ml-l"
          />
        </div>
      </div>
    </div>
  );
};
