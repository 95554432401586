import { useState } from "react";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { SelectDropdown } from "./select-dropdown";
import { TransferOutLocalBankDetailsForm } from "./transfer-out-local-bank-details-form";
import { TransferOutIntBankDetailsForm } from "./transfer-out-Int-bank-details-form";
import { LoadingIcon } from "./loading-icon";
import { BANK_TYPE_LIST } from "../config/bank-details";
import { TransferTotalReceiveAmount } from "./transfer-total-receive-amount";
import { getTotalReceiveAmount } from "../config/charges";
import { useTranslation } from "react-i18next";

export const TransferOutForm = (props: any) => {
  const { t } = useTranslation();
  const { transferOut, amount, setAmount, currency, bankType, setBankType } = props;

  // Local & Int Bank Form
  const [bankInfo, setBankInfo] = useState<any>({
    type: "local", // local or international
    bankName: "",
    payeeAccountName: "",
    payeeAccountNo: "",
    payeeAddress: "",
    payeeCountry: "",
    payeeCity: "",
    bsbCode: "",
    swiftCode: "",
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    // Validate Form Fields
    if (!bankInfo.type)
      return openWarningNotification(t("message.missing_bank_type"));
    if (!amount) return openWarningNotification(t("message.missing_amount"));
    if (amount <= 0)
      return openWarningNotification(t("message.invalid_amount"));

    // Require Form Fields
    if (!bankInfo.payeeAccountName)
      return openWarningNotification(t("message.missing_account_name"));
    if (!bankInfo.payeeAccountNo)
      return openWarningNotification(t("message.missing_account_number"));
    if (!bankInfo.bankName)
      return openWarningNotification(t("message.missing_bank_name"));

    if (bankInfo.type === "international") {
      if (!bankInfo.payeeCountry)
        return openWarningNotification(t("message.missing_recipient_country"));
      if (!bankInfo.payeeCity)
        return openWarningNotification(t("message.missing_recipient_city"));
      if (!bankInfo.payeeAddress)
        return openWarningNotification(t("message.missing_recipient_address"));
      if (!bankInfo.bsbCode)
        return openWarningNotification(t("message.missing_bsb_code"));
      if (!bankInfo.swiftCode)
        return openWarningNotification(t("message.missing_swift_code"));
    }
    setIsLoading(true);
    const payload = {
      amount: amount,
      currency: currency,
      bankForm: {
        // Local
        type: bankInfo.type,
        bankName: bankInfo.bankName,
        payeeAccountName: bankInfo.payeeAccountName,
        payeeAccountNo: bankInfo.payeeAccountNo,
        // Int
        ...(bankInfo.payeeCountry && { payeeCountry: bankInfo.payeeCountry }),
        ...(bankInfo.payeeCity && { payeeCity: bankInfo.payeeCity }),
        ...(bankInfo.payeeAddress && { payeeAddress: bankInfo.payeeAddress }),
        ...(bankInfo.bsbCode && { bsbCode: bankInfo.bsbCode }),
        ...(bankInfo.swiftCode && { bsbCode: bankInfo.swiftCode }),
      },
    };
    const result = await transferOut(payload);
    setIsLoading(false);
    if (!result) return openWarningNotification(t("message.submission_failed"));

    // Success
    openSuccessNotification(t("message.submission_successful"));
    return resetForm();
  };

  const resetForm = () =>
    setBankInfo({
      type: "",
      bankName: "",
      payeeAccountName: "",
      payeeAccountNo: "",
      payeeAddress: "",
      payeeCountry: "",
      payeeCity: "",
      bsbCode: "",
      swiftCode: "",
    });

  return (
    <div className="w-full lg:w-[650px] min-h-[100px] bg-white rounded-s drop-shadow p-lg">
      <h3 className="normal-case">{t("transfer_out.form")}</h3>
      <div className="mt-lg">
        <p className="content">{t("transfer_out.select_currency")}</p>
        <input
          className="text-input"
          type="text"
          value={currency}
          disabled={true}
        />
      </div>
      <div className="mt-s">
        <p className="content">{t("transfer_out.transfer_amount")}</p>
        <input
          className="text-input"
          type="number"
          value={amount}
          min="0"
          pattern="[0-9]"
          onChange={(e: any) => setAmount(e.target?.value)}
        />
      </div>

      <div className="mt-s">
        <p className="content">{t("transfer_out.transfer_to")}</p>
        <div className="transfer-out-select-dropdown mt-xs">
          <SelectDropdown
            width="100%"
            options={BANK_TYPE_LIST}
            value={t(bankType)}
            handleOnChange={(val: any) => {
              setBankType(val);
              resetForm();
              return;
            }}
          />
        </div>
      </div>

      {/* Bank Details Form */}
      {bankType === "local" && (
        <TransferOutLocalBankDetailsForm
          bankInfo={bankInfo}
          setBankInfo={setBankInfo}
        />
      )}
      {bankType === "international" && (
        <TransferOutIntBankDetailsForm
          bankInfo={bankInfo}
          setBankInfo={setBankInfo}
        />
      )}

      <div className="mt-lg" onClick={() => handleSubmit()}>
        <button className="button-gradient">
          {isLoading ? <LoadingIcon size={"25"} /> : t("button.submit")}
        </button>
      </div>

      <TransferTotalReceiveAmount
        type={bankType}
        amount={getTotalReceiveAmount(bankType, amount)}
        currency={currency}
      />
    </div>
  );
};