import { useTranslation } from "react-i18next";
import { ChangeLangButton } from "./change-lang-button";
import { LoginRightPanel } from "./login-right-panel";
import { useState } from "react";
import { ApplicationPanel } from "./application-panel";
import { UpdatePasswordPanel } from "./update-password-panel";

interface ILoginContainer {
  from: "login" | "apply-now" | "update-password";
}

export const LoginContainer = (props: ILoginContainer) => {
  const { from } = props;
  const [stage, setStage] = useState("username"); // username -> password

  const { t } = useTranslation();
  return (
    <div className="w-full w-full min-h-screen h-full relative p-[20px] lg:p-[5%] overflow-hidden flex flex-col gap-m">
      {/* BG Video */}
      <div className="w-screen h-screen fixed top-0 left-0">
        <video
          autoPlay
          muted
          playsInline
          loop
          className="relative w-full h-full object-cover"
        >
          <source src={"/assets/bg.mp4"} type="video/mp4" />
        </video>
      </div>

      {/* Overlay */}
      <div
        className={`bg-[#000]/50 absolute left-0 top-0 w-full h-full z-[10]`}
      />

      {/* Logo & Lang Selection */}
      <div className="flex flex-row relative z-[15] flex-1">
        {/* Language */}
        <ChangeLangButton
          from="login"
          width="100"
          btnStyle="border-[1px] border-[#CCCCCC]"
          prefix={
            <div className="pl-[10px]">
              <img src={"/assets/earth_white_color.svg"} alt="earth-icon" />
            </div>
          }
        />
      </div>

      {/* Right Col - Login */}
      <div className="relative z-[15]">
        {from === "login" && (
          <LoginRightPanel stage={stage} setStage={setStage} />
        )}
        {from === "apply-now" && (
          <ApplicationPanel stage={stage} setStage={setStage} />
        )}
        {from === "update-password" && (
          <UpdatePasswordPanel stage={stage} setStage={setStage} />
        )}
      </div>

      {/* Title & Description  */}
      <div className="flex flex-col relative z-[15] w-full">
        <h2 className="normal-case text-white text-[30px]">{t("desc1")}</h2>
        <div className="mt-[30px]">
          <h4 className="text-white">
            {t("desc2")}
            <br></br>
            <br></br>
            {t("desc3")}
          </h4>
        </div>
      </div>
    </div>
  );
};
