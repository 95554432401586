
interface ICryptoAssetAggregateBalanceProps {
  balance: number | null;
  balanceInHkd: number | null;
}

export const CryptoAssetAggregateBalance = (props: ICryptoAssetAggregateBalanceProps) => {
  const { balance = 0, balanceInHkd = 0 } = props;

  return (
    <div className="mt-lg">
      <div className="flex flex-col">
        <div className="flex items-end">
          <h2>{balance ? Number(balance).toFixed(2) : "0.00"}</h2>
          <p className="pl-xs relative top-[2px]">USDT</p>
        </div>
        <p className={`title mt-xs`}>
          &#8776;${balanceInHkd ? Number(balanceInHkd).toFixed(2) : "0.00"} HKD
        </p>
      </div>
    </div>
  );
};

