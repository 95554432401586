import { t } from "i18next";

export const CRYPTO_ASSET_ACTION_LIST = [
  "type.deposit",
  "type.withdraw",
  "type.convert",
];

export const CRYPTO_ASSET_SELECT_TOKEN_LIST = ["USDT", "USDC", "ETH", "BTC"];

export const CONVERT_GET_TOKEN_BALANCE_LIST = [
  "USDT_ERC",
  "USDT_TRC",
  "USDC_ERC",
  "ETH",
  "BTC",
];

export const CONVERT_SELECT_TOKEN_LIST = [
  { value: "HKD" },
  { value: "USD" },
  { value: "USDT" },
];

export const CONVERT_SELECT_FROM_TOKEN_LIST = [
  { value: "USDT_ERC", label: "USDT (ERC20)" },
  { value: "USDT_TRC", label: "USDT (TRC20)" },
  { value: "USDC_ERC", label: "USDC" },
];

export const CONVERT_SELECT_TO_TOKEN_LIST = [
  { value: "HKD" },
  { value: "USD" },
];

export const CRYPTO_ASSET_BSC_NETWORK = [
  {
    value: "選擇虛擬網絡",
    label: t("crypto_asset.choose_network"),
    name: "選擇虛擬網絡",
    chain_id: -1,
  },
  {
    value: "BNB_MAINET",
    label: "BNB (BNB Smart Chain Mainnet)",
    name: "BNB Smart Chain Mainnet",
    chain_id: 56,
  },
];
export const CRYPTO_ASSET_BITCOIN_NETWORK = [
  {
    value: "選擇虛擬網絡",
    label: "crypto_asset.choose_network",
    name: "選擇虛擬網絡",
    type: "",
    chain_id: -1,
  },
  { label: "BTC (Bitcoin EVM)", name: "Bitcoin EVM", value: "BITCOIN" },
];

export const CRYPTO_ASSET_ETHEREUM_NETWORK = [
  {
    value: "選擇虛擬網絡",
    label: "crypto_asset.choose_network",
    name: "選擇虛擬網絡",
    type: "",
  },
  {
    label: "ETH (Ethereum ERC20)",
    name: "Ethereum Mainnet",
    value: "ETHEREUM",
  },
];

export const CRYPTO_ASSET_USDT_NETWORK = [
  {
    value: "選擇虛擬網絡",
    label: "crypto_asset.choose_network",
    name: "選擇虛擬網絡",
  },
  {
    label: "ETH (Ethereum ERC20)",
    name: "Ethereum Mainnet",
    value: "ETHEREUM",
  },
  {
    label: "TRC (Tron TRC20)",
    name: "Tron",
    value: "TRON",
  },
];

export const CRYPTO_ASSET_WALLET_TOKEN_LIST = [
  { asset: "USDT", balance: 0 },
  { asset: "USDC", balance: 0 },
  { asset: "Ethereum", balance: 0 },
  { asset: "Bitcoin", balance: 0 },
];

// export const CRYPTO_ASSET_TRANSFER_IN_FOOTNOTE = [
//   "Address Updated. Please deposit to the above address",
//   "Deposits will be credited to your nominated RD Trust's wallet within 2 business days",
//   "Deposits will be unlocked and available for withdraw / other activities",
// ];

export const CRYPTO_ASSET_DEPOSIT_FOOTNOTE = [
  "transfer_to_address_footnote_1",
  "transfer_to_address_footnote_2",
  "transfer_to_address_footnote_3",
];

export const CRYPTO_ASSET_WITHDRAW_FOOTNOTE = [
  "withdraw_crypto_footnote_1",
  "withdraw_crypto_footnote_2",
  "withdraw_crypto_footnote_3",
];
