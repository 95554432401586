export const BANK_TRANSFER_IN_NOTE = [
  "transfer_in.bank_transfer_note_1",
  "transfer_in.bank_transfer_note_2",
  "transfer_in.bank_transfer_note_3",
];

export const BANK_DETAILS_INFO = [
  { label: "transfer_in.account_name", name: "Rich Dragon Consultants Ltd" },
  { label: "transfer_in.bank_name", name: "Heng Seng Bank" },
  { label: "transfer_in.bank_code", name: "024" },
  { label: "transfer_in.account_number", name: "369-728381-883" },
];

// TO BE UPDATED: overseas bank details
export const OVERSEAS_BANK_DETAILS_INFO = [
  { label: "transfer_in.account_name", name: "Rich Dragon Consultants Ltd" },
  { label: "transfer_in.bank_name", name: "Heng Seng Bank" },
  { label: "transfer_in.bank_code", name: "024" },
  { label: "transfer_out.swift_code", name: "HASEHKHH" },
  { label: "transfer_in.account_number", name: "369-728381-883" },
];

export const BANK_TYPE_LIST = [
  { value: "local", label: "transfer_out.local_bank" },
  { value: "international", label: "transfer_out.international_bank" },
];

// TODO Fix the translation
export const LOCAL_BANK_LIST = [
  {
    value: "中國銀行(香港)有限公司 (012)",
    name_sc: "中國銀行(香港)有限公司",
    name_en: "Bank of China (Hong Kong)",
    code: "012",
  },
  {
    value: "交通銀行(香港)有限公司 (027)",
    name_sc: "交通銀行(香港)有限公司",
    name_en: "Bank of Communications (Hong Kong)",
    code: "027",
  },
  {
    value: "東亞銀行有限公司 (015)",
    name_sc: "東亞銀行有限公司",
    name_en: "Bank of East Asia",
    code: "015",
  },
  {
    value: "中信銀行國際有限公司 (018)",
    name_sc: "中信銀行國際有限公司",
    name_en: "China CITIC Bank International",
    code: "018",
  },
  {
    value: "中國建設銀行(亞洲)股份有限公司 (009)",
    name_sc: "中國建設銀行(亞洲)股份有限公司",
    name_en: "China Construction Bank (Asia)",
    code: "009",
  },
  {
    value: "集友銀行有限公司 (039)",
    name_sc: "集友銀行有限公司",
    name_en: "Chiyu Banking Corporation",
    code: "039",
  },
  {
    value: "創興銀行有限公司 (041)",
    name_sc: "創興銀行有限公司",
    name_en: "Chong Hing Bank",
    code: "041",
  },

  {
    value: "花旗銀行(香港)有限公司 (250)",
    name_sc: "花旗銀行(香港)有限公司",
    name_en: "Citibank (Hong Kong)",
    code: "250",
  },
  {
    value: "大新銀行有限公司 (040)",
    name_sc: "大新銀行有限公司",
    name_en: "Dah Sing Bank",
    code: "040",
  },
  {
    value: "星展銀行(香港)有限公司 (016)",
    name_sc: "星展銀行(香港)有限公司",
    name_en: "DBS Bank (Hong Kong)",
    code: "016",
  },
  {
    value: "富邦銀行(香港)有限公司 (128)",
    name_sc: "富邦銀行(香港)有限公司",
    name_en: "Fubon Bank (Hong Kong)",
    code: "128",
  },
  {
    value: "恒生銀行有限公司 (024)",
    name_sc: "恒生銀行有限公司",
    name_en: "Hang Seng Bank",
    code: "024",
  },
  {
    value: "中國工商銀行(亞洲)有限公司 (072)",
    name_sc: "中國工商銀行(亞洲)有限公司",
    name_en: "Hongkong and Shanghai Banking Corporation",
    code: "072",
  },
  {
    value: "南洋商業銀行有限公司 (043)",
    name_sc: "南洋商業銀行有限公司",
    name_en: "Nanyang Commercial Bank",
    code: "043",
  },
  {
    value: "華僑銀行(香港)有限公司 (035)",
    name_sc: "華僑銀行(香港)有限公司",
    name_en: "OCBC Bank (Hong Kong)",
    code: "035",
  },

  {
    value: "大眾銀行(香港)有限公司 (028)",
    name_sc: "大眾銀行(香港)有限公司",
    name_en: "Public Bank (Hong Kong)",
    code: "028",
  },
  {
    value: "上海商業銀行有限公司 (025)",
    name_sc: "上海商業銀行有限公司",
    name_en: "Shanghai Commercial Bank",
    code: "025",
  },
  {
    value: "渣打銀行(香港)有限公司 (003)",
    name_sc: "渣打銀行(香港)有限公司",
    name_en: "Standard Chartered Hong Kong",
    code: "003",
  },
  {
    value: "大有銀行有限公司 (038)",
    name_sc: "大有銀行有限公司",
    name_en: "Tai Sang Bank",
    code: "038",
  },
  {
    value: "招商永隆銀行有限公司 (020)",
    name_sc: "招商永隆銀行有限公司",
    name_en: "CMB Wing Lung Bank",
    code: "020",
  },
];

export const LOCAL_VIRTUAL_BANK_LIST = [
  {
    value: "天星銀行有限公司 (395)",
    name_sc: "天星銀行有限公司",
    name_en: "Airstar Bank Limited",
    code: "395",
  },
  {
    value: "螞蟻銀行(香港)有限公司 (393)",
    name_sc: "螞蟻銀行(香港)有限公司",
    name_en: "Ant Bank (Hong Kong) Limited",
    code: "393",
  },
  {
    value: "富融銀行有限公司 (091)",
    name_sc: "富融銀行有限公司",
    name_en: "Fusion Bank Limited",
    code: "091",
  },
  {
    value: "理慧銀行有限公司 (388)",
    name_sc: "理慧銀行有限公司",
    name_en: "Livi Bank Limited",
    code: "388",
  },
  {
    value: "Mox Bank (389)",
    name_sc: "Mox Bank",
    name_en: "Mox Bank",
    code: "389",
  },
  {
    value: "平安壹賬通銀行(香港)有限公司 (392)",
    name_sc: "平安壹賬通銀行(香港)有限公司",
    name_en: "Ping An OneConnect Bank (Hong Kong) Limited",
    code: "392",
  },
  {
    value: "匯立銀行有限公司 (390)",
    name_sc: "匯立銀行有限公司",
    name_en: "WeLab Bank Limited",
    code: "390",
  },
  {
    value: "眾安銀行有限公司 (387)",
    name_sc: "眾安銀行有限公司",
    name_en: "ZA Bank Limited",
    code: "387",
  },
];

// export const RECEIVER_COUNTRY_LIST = [
//   { value: "Australia" },
//   { value: "Bahrain" },
//   { value: "Bermuda" },
//   { value: "Egypt" },
//   { value: "Guernsey" },
//   { value: "India" },
//   { value: "Indonesia" },
//   { value: "Isle of Man" },
//   { value: "Jersey" },
//   { value: "Mainland China" },
//   { value: "Malaysia" },
//   { value: "Malta" },
//   { value: "New Zealand" },
//   { value: "Philippines" },
//   { value: "Qatar" },
//   { value: "Singapore" },
//   { value: "Sri Lanka" },
//   { value: "Taiwan" },
//   { value: "United Arab Emirates" },
//   { value: "United Kingdom" },
//   { value: "United States" },
//   { value: "Vietnam" },
// ];

export const RECEIVER_COUNTRY_LIST = [
  { value: "Australia", label: "australia" },
  { value: "German", label: "german" },
  { value: "India", label: "india" },
  { value: "Indonesia", label: "indonesia" },
  { value: "Mainland China", label: "china" },
  { value: "Malaysia", label: "malaysia" },
  { value: "New Zealand", label: "new-zealand" },
  { value: "Philippines", label: "philippines" },
  { value: "Singapore", label: "singapore" },
  { value: "Taiwan", label: "taiwan" },
  { value: "United Kingdom", label: "united-kingdom" },
  { value: "United States", label: "united-states" },
  { value: "Vietnam", label: "vietnam" },
  { value: "Other", label: "other" },
];
