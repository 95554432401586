import { CreditCardOutlined, MoneyCollectOutlined } from "@ant-design/icons";
const $labelIcon = "text-[30px] !text-[#835506]";

export const BASIC_FUNCTION_LIST = [
  {
    name: "crypto_asset.crypto_asset_label",
    icon: <MoneyCollectOutlined className={$labelIcon} />,
    desc: "crypto_asset.crypto_asset_summary",
    page: "/crypto-asset",
  },
  {
    name: "main_menu_item.master_card",
    icon: <CreditCardOutlined className={$labelIcon} />,
    desc: "dashboard.card_desc",
    page: null,
  },
];
