import { getBase64 } from "src/utils";
import ImgCrop from "antd-img-crop";
import { useTranslation } from "react-i18next";
const { Upload: AntdUpload } = require("antd");

export const UploadModal = (props: any) => {
  const { t } = useTranslation();
  const { children, title, setIsUpLoading, setData } = props;

  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setIsUpLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setIsUpLoading(false);
        setData(url);
      });
    }
  };

  const customRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <ImgCrop
      shape="rect"
      modalTitle={title}
      modalOk={t("button.confirm")}
      modalCancel={t("button.cancel")}
    >
      <AntdUpload
        name="avatar"
        className="w-full h-full block flex flex-col justify-center items-center"
        onChange={handleChange}
        disableUpload={true}
        customRequest={customRequest} // Disabled auto image upload
        showUploadList={false}
      >
        {children}
      </AntdUpload>
    </ImgCrop>
  );
};
