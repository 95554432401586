import { useState } from "react";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { ModalBankDetails } from "./modal-bank-details";
import { useTranslation } from "react-i18next";

import { getTotalReceiveAmount } from "src/config/charges";
import { TransferTotalReceiveAmount } from "./transfer-total-receive-amount";
import { IDepositRequest } from "src/types/onshoreDeposit";
import { useUserStore } from "src/stores";

interface IOnshoreWalletDepositBankForm {
  amount: number;
  currency: string;
  reference: string;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  setAmount: (value: number) => void;
  showErrorMessage: (value: string) => void;
}

export const OnshoreWalletDepositBankForm = (
  props: IOnshoreWalletDepositBankForm
) => {
  const { t } = useTranslation();
  const { onshoreDeposit } = useUserStore();
  const {
    amount,
    currency,
    reference,
    isLoading,
    setIsLoading,
    setAmount,
    showErrorMessage,
  } = props;
  
  const [modalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleClose = () => {
    setIsModalOpen(false);
    // Reset to default
    showErrorMessage("");
    return;
  };

  const handleConfirm = async () => {
    if (!amount) return openWarningNotification(t("message.missing_amount"));
    if (amount < 0) return openWarningNotification(t("message.invalid_amount"));

    const payload: IDepositRequest = {
      type: "bank",
      amount: Number(amount),
      currency,
    };
    // Submit Bank Deposit Request
    const result = await onshoreDeposit(payload);
    setIsLoading(false);
    if (!result)
      return openWarningNotification(t("message.submit_bank_deposit_failed"));
    handleClose();
    openSuccessNotification(t("message.submit_bank_deposit_success"));
    // Reset to default
    setAmount(0);
    return;
  };

  const openBankInfoModal = () => {
    if (amount <= 0)
      return openWarningNotification(t("message.invalid_amount"));
    setIsModalOpen(true);
  };

  return (
    <div className="w-auto min-h-[100px] bg-white rounded-xs mt-m">
      <h3 className="normal-case">{t("onshore_wallet.bank_deposit")}</h3>
      <div className="mt-m">
        <p className="label">{t("onshore_wallet.currency")}</p>
        <input className="input" type="text" value={currency} disabled={true} />
      </div>
      <div className="mt-xs">
        <p className="label">{t("onshore_wallet.onshore_deposit_transfer_amount")}</p>
        <input
          className="input"
          type="number"
          value={amount}
          min="0"
          pattern="[0-9]"
          onChange={(e: any) => setAmount(Number(e.target?.value))}
        />
      </div>

      <div className="mt-m" onClick={() => openBankInfoModal()}>
        <button className="gradient">{t("button.submit")}</button>
      </div>

      <TransferTotalReceiveAmount
        type="onshore-deposit"
        amount={getTotalReceiveAmount("onshore-deposit", amount)}
        currency={currency}
      />

      {/* Bank Details Modal */}
      <ModalBankDetails
        isLoading={isLoading}
        modalOpen={modalOpen}
        reference={reference}
        currency={currency}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </div>
  );
};
