import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { BankDetails } from "./bank-details";
import { IOnshoreAccount } from "src/types/onshoreAccount";
import { useUserStore } from "src/stores";
import { OnshoreWalletDepositMethod } from "./onshore-wallet-deposit-method";
import { IDepositRequest, IPaymentResponse } from "src/types/onshoreDeposit";
import { PaymentQr } from "./payment-qr";

interface IModalActivate {
  isModalOpen: boolean;
  setIsModalOpen: (val: boolean) => void;
}

export const ModalActivate = (props: IModalActivate) => {
  const { isModalOpen, setIsModalOpen } = props;
  const [method, setMethod] = useState("unionpay");
  const [onshoreAccounts, setOnshoreAccounts] = useState<IOnshoreAccount[]>([]);
  const [qrCode, setQrCode] = useState<string>("");
  const [errorMessage, showErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const { getOnShoreAccounts, onshoreDeposit } = useUserStore();

  const firstTimeDepositAmount = 5000;
  const depositCurrency = "HKD";
  const qrMethod = ["unionpay", "alipay", "wechatpay"];

  const createPaymentQrCb = useCallback(
    async (name: string) => {
      if (!name) return;
      const payload: IDepositRequest = {
        type: "qr",
        amount: firstTimeDepositAmount,
        currency: depositCurrency,
        method: name,
      };
      setIsLoading(true);
      // Create Payment QR
      const result: IPaymentResponse = await onshoreDeposit(payload);
      setIsLoading(false);
      if (!result || !result?.qr_code) {
        showErrorMessage("message.create_payment_qr_failed");
      }
      const { qr_code } = result;
      setQrCode(qr_code);
      return;
    },
    [onshoreDeposit, setIsLoading, showErrorMessage, setQrCode]
  );

  useEffect(() => {
    getOnShoreAccounts().then((result: IOnshoreAccount[]) => {
      if (!result) return;
      setOnshoreAccounts(result);
    });
  }, [getOnShoreAccounts]);

  const selectedAcc = useMemo(() => {
    if (!onshoreAccounts) return null;
    let currency = "HKD";
    if (method === "bank_deposit") currency = "HKD";
    return onshoreAccounts.find(
      (item) => item?.currency.toLowerCase() === currency.toLowerCase()
    );
  }, [method, onshoreAccounts]);

  const handleClose = () => {
    setIsModalOpen(false);
    // Reset to default
    setMethod("unionpay");
    showErrorMessage("");
    setQrCode("");
  };

  const handleMethod = (name: string) => {
    setMethod(name);
    showErrorMessage("");
    setQrCode("");

    if (name === "bank_deposit") return;
    if (name === "qr" && !method)
      return openWarningNotification(t("message.missing_payment_method"));
    createPaymentQrCb(name);
  };

  const handleConfirm = async () => {
    const payload: IDepositRequest = {
      type: "bank",
      amount: firstTimeDepositAmount,
      currency: depositCurrency,
    };
    // Submit Bank Deposit Request
    const result = await onshoreDeposit(payload);
    setIsLoading(false);
    if (!result)
      return openWarningNotification(t("message.submit_bank_deposit_failed"));
    handleClose();
    openSuccessNotification(t("message.submit_bank_deposit_success"));
    return;
  };

  return (
    <Modal
      title={``}
      visible={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={handleClose}
      footer={null}
      centered={true}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleClose} />

      <div className="flex flex-col justify-start items-start min-h-[200px]">
        {/* Title */}
        <div>
          <h3 className="my-m text-center normal-case">
            {t("modal.require_kyc_one_title")}
          </h3>
        </div>
        {/* Content */}
        <div className="mt-xs">
          <p className="text-xs text-txt">{t("modal.require_kyc_one_text")}</p>
        </div>

        {/* Payment Method */}
        <OnshoreWalletDepositMethod
          isLoading={isLoading}
          method={method}
          handleOnClick={handleMethod}
          createPaymentQrCb={createPaymentQrCb}
        />

        {/* Bank Deposit */}
        <div className="mt-m w-full">
          {method === "bank_deposit" &&
            selectedAcc?.personal_account_number && (
              <BankDetails
                isLoading={isLoading}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                reference={selectedAcc?.personal_account_number}
              />
            )}

          {/* Payment QR */}
          {qrMethod.indexOf(method) > -1 && (
            <div>
              {!isLoading && errorMessage && (
                <div className="mt-xs">
                  <p className="text-txt">{t(errorMessage)}</p>
                </div>
              )}
              <PaymentQr
                isLoading={isLoading}
                method={method}
                paymentCode={qrCode}
                extra={
                  <div className="w-full mt-m" onClick={() => handleClose()}>
                    <button className="cancel" disabled={isLoading}>
                      {t("button.cancel")}
                    </button>
                  </div>
                }
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
