import { useTranslation } from "react-i18next";
import { BASIC_FUNCTION_LIST } from "src/config/dashboard";
import { history } from "src/stores";
import { IUser } from "src/types/user";

interface IDashboardBasicManualFn {
  user: IUser;
  setShowActivateAccModal: (val: boolean) => void;
}

export const DashboardBasicManualFn = (props: IDashboardBasicManualFn) => {
  const { user, setShowActivateAccModal } = props;
  const { t } = useTranslation();
  return (
    <div className="w-full lg:w-[545px] bg-white rounded-xs drop-shadow p-m">
      <div>
        <h3 className="normal-case">{t("dashboard.basic_operations")}</h3>
      </div>
      <div className="flex flex-row justify-center flex-wrap mt-[5px]">
        {BASIC_FUNCTION_LIST.map((item, i) => {
          return (
            <div
              className="mt-[15px] cursor-pointer hover:drop-shadow transition-all duration-300"
              key={i}
              onClick={
                item?.page
                  ? () => {
                      if (item?.page === "/master-card") {
                        if (!user?.kyc_level || user?.kyc_level === "0") {
                          return setShowActivateAccModal(true);
                        }
                      }
                      history.push(item.page);
                    }
                  : () => null
              }
            >
              <div className="flex justify-center items-center bg-gradient-to-r from-[#FFD6D9] to-[#D41E44] w-[230px] rounded-m py-[15px] mr-m">
                <div className="flex flex-row w-full items-center">
                  <div className="pl-m">{item.icon}</div>

                  <div className="pl-xs">
                    <p className="font-[700]">
                      {t(item.name)} {!item?.page && `(${t("coming_soon")})`}
                    </p>
                    <p>{t(item.desc)}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
