import { getAssetName, getCurrencyIcon } from "src/utils";
import { CRYPTO_ASSET_WALLET_TOKEN_LIST } from "src/config/crypto-asset";
import { ICryptoBalanceProps } from "src/type/crypto";

interface ICryptoAssetWalletTokenProps {
  walletTokenList: Array<ICryptoBalanceProps>;
}

interface ICryptoTokenList {
  asset: string;
  balance: number;
}

export const CryptoAssetWalletToken = (props: ICryptoAssetWalletTokenProps) => {
  const { walletTokenList = [] } = props;
  return (
    <div className="mt-lg pr-0 lg:pr-lg">
      <div className="w-full lg:w-[440px]">
        {CRYPTO_ASSET_WALLET_TOKEN_LIST.length > 0 &&
          CRYPTO_ASSET_WALLET_TOKEN_LIST.map((item: ICryptoTokenList, i: number) => {
            const exist = walletTokenList.find(
              (wallet: ICryptoBalanceProps) => item.asset === wallet.asset
            );
            const displayBalance = exist
              ? Number(exist.balance).toFixed(2)
              : (0).toFixed(2);
            return (
              <div
                className={`flex justify-between items-center ${
                  i === CRYPTO_ASSET_WALLET_TOKEN_LIST.length - 1
                    ? ""
                    : `mb-s`
                }`}
                key={i}
              >
                <div className="flex flex-row items-center">
                  <div className="overflow-hidden rounded-full">
                    <img
                      src={getCurrencyIcon(item.asset)}
                      alt="currency"
                      className="w-[20px] h-[20px] object-fit"
                    />
                  </div>
                  <p className="pl-s lg:mr-0 mr-xs">
                    {getAssetName(item.asset)}
                  </p>
                </div>
                <p className="label">
                  {displayBalance} {getAssetName(item.asset)}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
};
