import { useUserStore } from "src/stores";
import { useState } from "react";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { UpdatePasswordForm } from "./update-password-form";
import { history } from "src/stores";
import { useTranslation } from "react-i18next";
const md5 = require("md5");

export const UpdatePasswordPanel = (props: any) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState<string | null>("");
  const [newPassword, setNewPassword] = useState<string | null>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string | null>(
    ""
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { updatePassword } = useUserStore();

  const handleConfirmPwdUpdate = async () => {
    // Validate Form
    if (!password)
      return openWarningNotification(t("message.please_fill_in_old_password"));
    if (!newPassword)
      return openWarningNotification(t("message.please_fill_in_new_password"));
    if (!confirmNewPassword)
      return openWarningNotification(
        t("message.please_fill_in_confirm_new_password")
      );

    if (password === newPassword)
      return openWarningNotification(
        t("message.please_fill_in_different_password")
      );
    if (newPassword !== confirmNewPassword)
      return openWarningNotification(t("message.password_not_match"));

    setLoading(true);
    const result = await updatePassword({
      password: md5(password),
      newPassword: md5(confirmNewPassword),
    });
    setLoading(false);
    if (typeof result !== "boolean") return;
    openSuccessNotification(t("message.update_success"));
    history.push("/dashboard");
    return;
  };

  return (
    <div className="p-lg bg-white flex flex-col items-start justify-center w-full lg:w-fit rounded-lg shadow-lg">
      <div className="w-fit text-left flex flex-col gap-m">
        <div className="flex items-start flex-1">
          <img
            src="/assets/doo.svg"
            className="w-[130px] object-contain"
            alt="rich-dragon-icon"
          />
        </div>
        <div>
          <div className="flex flex-col items-center justify-center bg-[#EFEFEF] px-[15px] py-[10px]">
            <p className="text-[13px] font-[600]">{t("form_header")}</p>
            <a
              className="font-[600] no-underline text-[#274673] hover:!text-[#274673]"
              href="https://trust.doo.io"
            >
              https://trust.doo.io
            </a>
          </div>

          <div className="mt-[45px]">
            <h2 className="normal-case">{t("change_pwd")}</h2>
          </div>

          <UpdatePasswordForm
            loading={loading}
            password={password}
            setPassword={setPassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            confirmNewPassword={confirmNewPassword}
            setConfirmNewPassword={setConfirmNewPassword}
            handleConfirm={handleConfirmPwdUpdate}
          />
        </div>
      </div>
    </div>
  );
};
