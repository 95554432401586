import axios from "axios";
import * as Configs from "../config";
import { errorHandling, refreshNewToken, setHeader } from "./api";

export class CryptoAssetStore {
  async getCryptoAccount() {
    const url = `${Configs.API_URL}/v1/crypto/account`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getCryptoAddress(param: any) {
    const url = `${Configs.API_URL}/v1/crypto/address${param ? param : ""}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getCryptoFees(param: any) {
    const url = `${Configs.API_URL}/v1/crypto/trading-fees${param ? param : ""}`; 
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async withdrawCrypto(payload: any) {
    const url = `${Configs.API_URL}/v1/crypto/withdraw`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_CRYPTO_ASSET = "cryptoAssetStore";
