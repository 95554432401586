import { useTranslation } from "react-i18next";
import { Button } from "./button";
import { useEffect } from "react";

const DEPOSIT_METHOD = [
  {
    name: "unionpay",
    value: "unionpay",
  },
  {
    name: "alipay",
    value: "alipay",
  },
  {
    name: "wechatpay",
    value: "wechatpay",
  },
  {
    name: "bank_deposit",
    value: "bank_deposit",
  },
];

interface IDepositMethod {
  name: string;
  value: string;
}

interface IOnshoreWalletDepositMethod {
  fromPage?: string;
  method: string;
  isLoading: boolean;
  handleOnClick: (value: string) => void;
  createPaymentQrCb?: (value: string) => void;
}
export const OnshoreWalletDepositMethod = (
  props: IOnshoreWalletDepositMethod
) => {
  const { t } = useTranslation();
  const { isLoading, fromPage, method, handleOnClick, createPaymentQrCb } = props;

  useEffect(() => {
    if (fromPage === "onshore-deposit") return;
    // Create QR when modal is opened
    if (!method) return;
    createPaymentQrCb && createPaymentQrCb(method);
  }, [fromPage, method, createPaymentQrCb]);

  return (
    <div className="mt-m flex flex-row space-x-xs">
      {DEPOSIT_METHOD.map((item: IDepositMethod, i) => {
        return (
          <div key={i}>
            <Button
              value={`${t(`onshore_wallet.${item.name}`)}`}
              theme={item.value === method ? "primary" : "normal"}
              handleOnClick={() => handleOnClick(item.value)}
              disabled={isLoading}
            />
          </div>
        );
      })}
    </div>
  );
};
