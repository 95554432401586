
export enum TransactionStatus {
  all = "all",
  processing = "processing",
  pending = "pending",
  done = "done",
  fail = "fail"
}

export enum TransactionType {
  deposit = "deposit",
  withdraw = "withdraw",
  convert = "convert",
  "transfer-in" = "transfer-in",
  "transfer-out" = "transfer-out",
  exchange = "exchange",
  card = "card",
  "top-up-master-card" = "top-up-master-card",
  charge = "charge",
  transfer = "transfer",
  crypto_withdraw = "crypto_withdraw"
}

export interface ITxnFilterProps {
  status?: string;
  type?: string;
  limit?: number;
  startDate?: string;
  endDate?: string;
}

export interface ITransactionsBase {
  type: TransactionType;
  tx_id?: string;
  status: TransactionStatus;
  amount: number;
  currency: string;
  bank_type?: string;
  fee_currency?: string;
  received_amount?: number | null;
  received_currency?: string;
  created_at?: string;
}

