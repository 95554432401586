import { useTranslation } from "react-i18next";

export const MasterCardNoCardPanel = (props: any) => {
  const { t } = useTranslation();

  const { setShowForm, applyStatus } = props;
  return (
    <div className="w-full lg:w-[545px] bg-white rounded-[10px] drop-shadow p-[20px]">
      <div>
        <h3 className="normal-case">{t("masterCard.name")}</h3>
      </div>

      <div className="flex flex-row mt-[20px]">
        {/* Add Card Button */}
        {!applyStatus ||
          (applyStatus && applyStatus === "no-master-card" && (
            <div onClick={() => setShowForm("add-card")}>
              <div className="bg-[#F0F2F5] px-[20px] py-[12px] rounded-[10px] border border-dotted border-[#00000040] cursor-pointer hover:drop-shadow transition-all duration-300">
                <h1 className="!font-[200] mt-[-3px] text-[#000]/40">+</h1>
              </div>
            </div>
          ))}

        {/* Sample Card */}
        <div className="ml-[20px]">
          <img
            src={"/assets/Rich_Dragon_MasterCard.png"}
            className="w-[250px]"
            alt="sample-card"
          />
        </div>

        {/* Show Master Card Buttons */}
        {applyStatus && applyStatus !== "no-master-card" && (
          <div className="ml-[20px] flex-1">
            <div className="flex flex-row flex-wrap">
              <button className={$disabledBtn}>{t("button.card_info")}</button>
              <button className={$disabledBtn}>
                {t("button.top_up_master_card")}
              </button>
              <button disabled={true} className={$disabledBtn}>
                {t("button.edit_atm_pin")}
              </button>
              <button className={$disabledBtn} disabled={true}>
                {t("button.edit_daily_limit")}
              </button>
              <button className={$disabledBtn} disabled={true}>
                {t("button.lock_unlock_card")}
              </button>
            </div>
          </div>
        )}
      </div>

      {applyStatus && applyStatus === "no-master-card" && (
        <div className="mt-[20px]">
          <ul className="list-disc ml-[20px] mb-[0px]">
            {/* <li>
              {t("masterCard.add_card_note_1")}
              <a
                className="text-primary hover:text-primary no-underline cursor-pointer"
                href={"mailto:info@doo.io"}
              >
                {t("apply_here")}
              </a>
            </li> */}
            <li>{t("masterCard.add_card_note_2")}</li>
            {/* <li> {t("masterCard.add_card_note_3")}</li> */}
          </ul>
        </div>
      )}

      {applyStatus && applyStatus === "applied-master-card" && (
        <div className="mt-[20px]">
          <ul className="list-disc ml-[20px] mb-[0px]">
            <li>{t("masterCard.applied_card_note")}</li>
          </ul>
        </div>
      )}

      {applyStatus && applyStatus === "submitted-request" && (
        <div className="mt-[20px]">
          <ul className="list-disc ml-[20px] mb-[0px]">
            <li>{t("masterCard.submitted_card_request")}</li>
          </ul>
        </div>
      )}
    </div>
  );
};

const $disabledBtn =
  "bg-[#3b3b3b4d] w-full text-black font-[700] py-[5px] mb-[10px] text-[10px] lg:text-[12px]";
