import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { useTranslation } from "react-i18next";

import { PaymentQr } from "./payment-qr";

interface IModalOnshoreWalletDepositPaymentQR {
  isLoading: boolean;
  modalOpen: boolean;
  method: string;
  qrCode: string;
  handleClose: () => void;
}

export const ModalOnshoreWalletDepositPaymentQR = (
  props: IModalOnshoreWalletDepositPaymentQR
) => {
  const { t } = useTranslation();
  const { isLoading, modalOpen, method, qrCode, handleClose } = props;

  return (
    <Modal
      title={``}
      visible={modalOpen}
      closable={false}
      onOk={handleClose}
      onCancel={handleClose}
      footer={null}
      centered={true}
      className="rounded-s"
    >
      <CloseButton handleCancel={handleClose} />

      <div className="flex flex-col justify-start align-start min-h-[220px]">
        <div>
          <h3 className="my-s text-center normal-case">
            {t(`modal.payment_qr`)}
          </h3>
          <PaymentQr
            isLoading={isLoading}
            method={method}
            paymentCode={qrCode}
          />
        </div>
      </div>
    </Modal>
  );
};
