import * as Utils from "../utils";

export const CopyToClipboardIcon = (props: any) => {
  const { className, txt } = props;
  return (
    <div
      className={`${className && "cursor-pointer"}`}
      onClick={() => Utils.copyToClipboard(txt)}
    >
      <img
        src="/assets/copy.png"
        className="w-[15px] h-[15px] object-cover cursor-pointer"
        alt="copy-icon"
      />
    </div>
  );
};
