import { useEffect } from "react";
import { history, useUserStore } from "src/stores";

export const Auth = (props: any) => {
  const { children } = props;
  const { getProfileV2 } = useUserStore();
  useEffect(() => {
    // Check login
    getProfileV2().then((result) => {
      if (!result) return history.push("/");
      if (!result?.status) return history.push("/");
      if (result?.force_pwd_change) return history.push("/update-password");
    });
  }, [getProfileV2]);

  return <>{children}</>;
};
