import { history } from "src/stores";
import { Button } from "./button";
import { useTranslation } from "react-i18next";
import { UserOutlined } from "@ant-design/icons";
import { ChangeLangButton } from "./change-lang-button";

export const Header = (props: any) => {
  const { t } = useTranslation();

  return (
    <div className="hidden lg:flex absolute bg-transparent w-full px-[30px] py-[30px] flex-row align-center items-center z-[100]">
      <div className="flex flex-row align-center items-center flex-1"></div>

      <div className="flex flex-row justify-center items-center">
        {/* Language */}
        <div className="mr-[20px]">
          <ChangeLangButton
            width="90"
            from="header"
            btnStyle="border border-[#00000008]"
            prefix={
              <div>
                <img src={"/assets/earth_black_color.svg"} alt="earth-icon" />
              </div>
            }
          />
        </div>

        {/* User Name */}
        <Button
          value={t("button.profile")}
          txtColorStyle="!text-[#000000A6]"
          prefix={<UserOutlined className="text-[12px] text-[#000]" />}
          handleOnClick={() => history.push("/update-profile")}
        />
      </div>
    </div>
  );
};
