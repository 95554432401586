import { getCurrencyIcon } from "src/utils";
import {
  CRYPTO_ASSET_SELECT_TOKEN_LIST,
  CRYPTO_ASSET_DEPOSIT_FOOTNOTE,
} from "../config/crypto-asset";
import { Button } from "./button";
import { SelectDropdown } from "./select-dropdown";
import QRCode from "react-qr-code";
import { CryptoAssetWalletAddress } from "./crypto-asset-wallet-address";
import { LoadingIcon } from "./loading-icon";
import { useTranslation } from "react-i18next";
import { INetworkOptListProps } from "./crypto-asset-deposit-form";

interface ICryptoAssetDepositFormItemsProps {
  address: string;
  token: string;
  handleSelectToken: (value: string) => void;
  handleSelectNetwork: (value: string) => void;
  network: string;
  setNetwork: (value: string) => void;
  networkOptionList: Array<INetworkOptListProps>;
  isLoading: boolean;
}

export const CryptoAssetDepositFormItems = (
  props: ICryptoAssetDepositFormItemsProps
) => {
  const { t } = useTranslation();
  const {
    address,
    token,
    handleSelectToken,
    handleSelectNetwork,
    network,
    setNetwork,
    networkOptionList,
    isLoading,
  } = props;

  return (
    <div className="flex-1 ml-0 lg:ml-s">
      {/* Select Token */}
      <p className="content">{t("select_token")}</p>
      <div className="w-full lg:w-[500px]">
        <input
          className="text-input"
          type="text"
          value={token}
          disabled={true}
        />
      </div>
      <div className="flex flex-wrap border-primary justify-start items-center mt-m">
        {CRYPTO_ASSET_SELECT_TOKEN_LIST.map((name, i: number) => {
          return (
            <div className="mb-m lg:mb-0 mr-m" key={i}>
              <Button
                value={name}
                btnStyle={`${
                  token === name
                    ? "border-transparent bg-gradient-to-r from-[#FFD6D9] to-[#D41E44]"
                    : "border border-primary"
                }`}
                height="35"
                prefix={
                  <div className="overflow-hidden border rounded-full">
                    <img
                      src={getCurrencyIcon(name)}
                      alt="currency"
                      className="`w-[20px] h-[20px] object-cover"
                    />
                  </div>
                }
                handleOnClick={() => {
                  handleSelectToken(name);
                  setNetwork(""); //Reset
                  return;
                }}
              />
            </div>
          );
        })}
      </div>

      {/* Select Network */}
      <div className="mt-m lg:mt-l flex-1 w-full">
        <p className="content">{t("crypto_asset.choose_network")}</p>
        <div className="mt-xs network-select-dropdown w-full lg:w-[500px] bg-[#efefef4d]">
          <SelectDropdown
            width="100%"
            options={networkOptionList}
            value={network}
            defaultValue={t("crypto_asset.choose_network")}
            handleOnChange={(value: string) => {
              handleSelectNetwork(value);
            }}
            disabled={token === t("select_token") || !token}
          />
        </div>
      </div>

      {/* Deposit Address */}
      <div className="mt-lg">
        <p className="content">{t("crypto_asset.to_address")}</p>
        <div
          className={$containerStyle}
          style={isLoading ? { cursor: "cursor-not-allowed" } : {}}
        >
          {isLoading && (
            <div className="w-full flex justify-center items-center">
              <LoadingIcon color="#D41E44" />
            </div>
          )}

          {!isLoading && address && network && (
            <div className="flex flex-1 flex-col lg:flex-row">
              <div className="overflow-hidden bg-transparent mt-m]">
                <QRCode
                  value={address}
                  style={{ width: 75, height: 75, margin: "auto" }}
                />
              </div>

              <div className="ml-0 lg:ml-lg flex flex-col justify-center">
                <p className="text-left text-content text-s font-[400] mb-xs">
                  {t("crypto_asset.crypto_address")}
                </p>
                <CryptoAssetWalletAddress address={address} gradient={true} />
              </div>
            </div>
          )}
        </div>

        {/* Footnote */}
        <div className="mt-s">
          {CRYPTO_ASSET_DEPOSIT_FOOTNOTE.map((note: string, i: number) => {
            return (
              <div className="flex items-center" key={i}>
                <span className="mx-xs text-s">&#8226;</span>
                <p className="label">{t(`crypto_asset.${note}`)}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const $containerStyle =
  "w-full lg:w-[500px] min-h-[150px] flex justify-start items-center border border-rd-border mt-xs rounded-xs bg-[#efefef4d] p-lg";
