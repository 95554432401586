import { RECEIVER_COUNTRY_LIST } from "src/config/bank-details";
import { SelectDropdown } from "./select-dropdown";
import { useTranslation } from "react-i18next";

export const TransferOutIntBankDetailsForm = (props: any) => {
  const { t } = useTranslation();
  const { bankInfo, setBankInfo } = props;

  const handleFormChange = (val: any, name: string) => {
    let newBankFormObj: any = {
      ...bankInfo,
      type: "international",
      [name]: val,
    };
    setBankInfo(newBankFormObj);
  };

  const renderInputFieldRow = (list: any) => {
    if (!list.length) return null;

    const _getFormType = (name: string) => {
      if (name === "bsbCode" || name === "payeeAccountNo") return "number";
      return "text";
    };

    return (
      <div className="flex flex-row justify-between items-center mt-s">
        {list.map(({ label, value, name }: any, i: number) => (
          <div key={i} className={`flex-1 ${i === 0 && "mr-lg"}`}>
            <p className="content">{t(`transfer_out.${label}`)}</p>
            <input
              className="text-input"
              type={_getFormType(name)}
              value={value}
              name={name}
              onChange={(e: any) => handleFormChange(e.target?.value, name)}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      {/* Payee Country */}
      <div className="mt-s">
        <p className="content">{t("transfer_out.receiver_country")}</p>
        <div className="transfer-out-select-dropdown mt-xs">
          <SelectDropdown
            width="100%"
            options={RECEIVER_COUNTRY_LIST}
            value={t(bankInfo.payeeCountry)}
            handleOnChange={(val: any) => handleFormChange(val, "payeeCountry")}
          />
        </div>
      </div>
      {/* Bank Name */}
      <div className="mt-s">
        <p className="content">{t("transfer_out.bank_name")}</p>
        <input
          className="content"
          type="text"
          value={bankInfo.bankName}
          name="bankName"
          onChange={(e: any) => handleFormChange(e.target?.value, "bankName")}
        />
      </div>

      {/* Other Bank Transfer Required Rows */}
      {renderInputFieldRow([
        { label: "bsb_code", name: "bsbCode", value: bankInfo.bsbCode },
        { label: "swift_code", name: "swiftCode", value: bankInfo.swiftCode },
      ])}

      {renderInputFieldRow([
        {
          label: "payee_account_name",
          name: "payeeAccountName",
          value: bankInfo.payeeAccountName,
        },
        {
          label: "payee_account_number",
          name: "payeeAccountNo",
          value: bankInfo.payeeAccountNo,
        },
      ])}

      {renderInputFieldRow([
        {
          label: "payee_address",
          name: "payeeAddress",
          value: bankInfo.payeeAddress,
        },
        { label: "payee_city", name: "payeeCity", value: bankInfo.payeeCity },
      ])}
    </div>
  );
};

