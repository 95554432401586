import { useState } from "react";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { ModalBankDetails } from "./modal-bank-details";
import { TransferTotalReceiveAmount } from "./transfer-total-receive-amount";
import { getTotalReceiveAmount } from "src/config/charges";
import { generateCode } from "src/utils/generator";
import { useTranslation } from "react-i18next";

export const TransferInForm = (props: any) => {
  const { t } = useTranslation();
  const {
    transferIn,
    amount,
    setAmount,
    currency,
  } = props;
  const [modalOpen, setIsModalOpen] = useState<boolean>(false);
  const [reference, setReference] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  const handleConfirm = async () => {
    if (!amount) return openWarningNotification(t("message.missing_amount"));
    if (amount < 0) return openWarningNotification(t("message.invalid_amount"));
    const result = await transferIn({ currency, amount, reference: reference });
    setIsLoading(false);
    if (!result) return openWarningNotification(t("message.transfer_failed"));
    handleClose();
    openSuccessNotification(t("message.transfer_success"));
    return;
  };

  const handleSubmit = () => {
    if (amount <= 0)
      return openWarningNotification(t("message.invalid_amount"));
    const ref = generateCode(4, 2);
    setReference(`RD${ref}`);
    setIsModalOpen(true);
  };

  return (
    <div className="w-full lg:w-[650px] min-h-[100px] bg-white rounded-[10px] drop-shadow p-[20px]">
      <h3 className="normal-case">{t("transfer_in.form")}</h3>

      <div className="mt-[20px]">
        <p className="content">{t("transfer_in.currency")}</p>
        <input
          className="text-input" type="text"
          value={currency}
          disabled={true}
        />
      </div>
      <div className="mt-[10px]">
        <p className="content">{t("transfer_in.transfer_amount")}</p>
        <input
          className="text-input" type="number"
          value={amount}
          min="0"
          pattern="[0-9]"
          onChange={(e: any) => setAmount(e.target?.value)}
        />
      </div>

      <div className="mt-[20px]" onClick={() => handleSubmit()}>
        <button className="button-gradient">{t("button.submit")}</button>
      </div>

      <TransferTotalReceiveAmount
        type="transfer-in"
        amount={getTotalReceiveAmount("transfer-in", amount)}
        currency={currency}
      />

      {/* Bank Details Modal */}
      <ModalBankDetails
        isLoading={isLoading}
        modalOpen={modalOpen}
        currency={currency}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        // bankType={bankType}
        // handleOpen={handleOpen}
        reference={reference}
      />
    </div>
  );
};

