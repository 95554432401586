export const Button = (props: any) => {
  const {
    width = "100",
    height = "30",
    value = "",
    btnStyle = "",
    handleOnClick = () => {},
    prefix = null,
    suffix = null,
    disabled = false,
    theme = "normal",
  } = props;

  const themeBtnStyle = () => {
    if (theme === "primary") {
      return {
        backgroundColor: "#D41E44",
      };
    }
    if (theme === "normal")
      return {
        borderColor: "#00000008",
      };

    return {};
  };

  const themeTxtStyle = () => {
    if (theme === "primary") {
      return {
        color: "#fff",
      };
    }
    if (theme === "normal")
      return {
        color: "#000",
      };

    return {};
  };

  return (
    <button
      className={`border rounded-[5px] text-[#000] !px-0 !py-[2px] ${btnStyle} ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      onClick={handleOnClick}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        ...themeBtnStyle(),
      }}
      disabled={disabled}
    >
      <div className="flex flex-row justify-center items-center">
        {prefix && prefix}
        {value && (
          <p className={`pl-[5px]`} style={{ ...themeTxtStyle() }}>
            {value}
          </p>
        )}
        {suffix && suffix}
      </div>
    </button>
  );
};
