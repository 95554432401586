import {
  BANK_DETAILS_INFO,
  BANK_TRANSFER_IN_NOTE,
  OVERSEAS_BANK_DETAILS_INFO,
} from "src/config/bank-details";
import { useTranslation } from "react-i18next";
import { CopyToClipboardIcon } from "./copy-to-clipboard-icon";
import { useMemo } from "react";
import { LoadingIcon } from "./loading-icon";

interface IBankDetails {
  isLoading: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  reference: string;
  bankType?: string;
}

export const BankDetails = (props: IBankDetails) => {
  const { t } = useTranslation();
  const { isLoading, handleClose, handleConfirm, reference, bankType } = props;

  const bankInfoDetails = useMemo(() => {
    if (!bankType) return BANK_DETAILS_INFO; // local
    if (bankType === "international") return OVERSEAS_BANK_DETAILS_INFO;
    return BANK_DETAILS_INFO;
  }, [bankType]);

  return (
    <>
      <div className="w-full flex flex-col px-m border rounded-s bg-form-bg p-m !pb-xs">
        {bankInfoDetails.length > 0 &&
          bankInfoDetails.map((item: any, i: number) => {
            return (
              <div className={$row} key={i}>
                <p className="label">{t(item.label)}</p>
                <p className="font-bold pr-xxs">{item.name}</p>
              </div>
            );
          })}

        {/* Reference Number */}
        <div className={$row}>
          <p className="label">{t("onshore_wallet.reference")}</p>
          <div className="flex justify-center items-center">
            <p className="font-bold pr-xxs">{reference}</p>
            <CopyToClipboardIcon txt={reference} />
          </div>
        </div>
      </div>

      <div className="mt-m">
        <div>
          <p className="content">{t("onshore_wallet.bank_info")}</p>
          {BANK_TRANSFER_IN_NOTE.map((note, index: number) => (
            <p key={index} className="content">
              <span className="px-xxs">&#8226;</span>
              {t(note)}
              {index === 0 && (
                <span className="pl-[2px] text-primary font-bold">
                  {t("onshore_wallet.reference")}
                </span>
              )}
            </p>
          ))}
        </div>
      </div>

      <div className="flex flex-row mt-m">
        <div className="flex-1 mr-xs">
          <button className="cancel" onClick={() => handleClose()}>
            {t("button.cancel")}
          </button>
        </div>
        <div className="flex-1">
          <button className="gradient" onClick={() => handleConfirm()}>
            {isLoading ? <LoadingIcon size={"25"} /> : t("button.done")}
          </button>
        </div>
      </div>
    </>
  );
};

const $row = "flex flex-row justify-between items-center pb-xs";
