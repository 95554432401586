import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useUserStore } from "src/stores";
import { LoadingIcon } from "./loading-icon";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";

interface IModalConfirmOpenAccount {
  action: string | null;
  isModalOpen: boolean;
  setIsModalOpen: (val: boolean) => void;
  getOnshoreAccountsCb: () => void;
}

export const ModalConfirmOpenAccount = (props: IModalConfirmOpenAccount) => {
  const { action, isModalOpen, setIsModalOpen, getOnshoreAccountsCb } = props;
  const { t } = useTranslation();
  const { onshoreOpenAccount } = useUserStore();

  const [isLoading, setIsLoading] = useState(false);

  const handleOk = () => setIsModalOpen(false);
  const handleCancel = () => setIsModalOpen(false);

  const openAccCurrency = action?.split("_")[2];

  const handleConfirm = async () => {
    if (!action) return;
    setIsLoading(true);
    const result = await onshoreOpenAccount({ currency: openAccCurrency });
    setIsLoading(false);
    if (!result)
      return openWarningNotification(t("message.open_account_failed"));
    openSuccessNotification(t("message.open_account_success"));
    // Get Onshore Account
    await getOnshoreAccountsCb();
    handleCancel();
    return;
  };

  return (
    <Modal
      title={""}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered={true}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} />

      <div className="flex flex-col justify-start items-center min-h-[170px] h-fit">
        {/* Title */}
        <div>
          <h3 className="my-m text-center normal-case">
            {t("modal.confirm_open_onshore_account")}
          </h3>
        </div>

        {/* Content */}
        <div className="mt-xs">
          <p
            className="title text-txt"
            dangerouslySetInnerHTML={{
              __html: t("modal.are_you_sure", {
                currency: t(String(openAccCurrency?.toLowerCase())),
              }),
            }}
          />
        </div>

        {/* Button */}
        <div className="mx-auto pt-l w-full">
          <button
            className={isLoading ? `gradient cursor-not-allowed` : "gradient"}
            disabled={isLoading}
            onClick={() => handleConfirm()}
          >
            {isLoading ? <LoadingIcon size={"25"} /> : t("button.confirm")}
          </button>
        </div>
      </div>
    </Modal>
  );
};
