import axios from "axios";
import * as Configs from "../config";
import { errorHandling, refreshNewToken, setHeader } from "./api";

export class TransactionStore {
  async cardTopUp(payload: any) {
    const url = `${Configs.API_URL}/v1/transaction/card-top-up`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data.success) return false;
      refreshNewToken(result);
      return result?.data.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async transferIn(payload: any) {
    const url = `${Configs.API_URL}/v1/transaction/transfer-in`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data.success) return false;
      refreshNewToken(result);
      return result?.data.success;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async transferOut(payload: any) {
    const url = `${Configs.API_URL}/v1/transaction/transfer-out`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result?.data.success) return false;
      refreshNewToken(result);
      return result?.data.success;
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_TRANSACTION = "transactionStore";
