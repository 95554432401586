import { ClockCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  LOCAL_CHARGES,
  OVERSEAS_CHARGES,
  SERVICE_CHARGES,
} from "src/config/charges";
import { ITransactionsBase } from "src/type/transaction";
import { getTypeTxt } from "src/utils";

export interface ITransactionRowProps extends ITransactionsBase {
  getCurrencyIcon?: (value: string) => string;
  date: string;
  txtColor: string;
}

export default function TransactionRow(props: ITransactionRowProps) {
  const {
    getCurrencyIcon,
    amount,
    currency,
    date,
    type,
    txtColor,
    bank_type,
    received_amount,
    received_currency,
    status,
  } = props;
  const { t } = useTranslation();

  const displayAmount = useMemo(() => {
    if (type === "transfer-out") return received_amount;
    return amount;
  }, [type, received_amount, amount]);

  const displayCurrency = useMemo(() => {
    if (type === "transfer-out") return received_currency;
    return currency;
  }, [type, currency, received_currency]);

  const renderDisplayFee = (transaction_type = "local") => {
    if (transaction_type === "local") {
      return `${t("handling_fee")} (${LOCAL_CHARGES}%)`;
    }

    if (transaction_type === "international") {
      return `${t(
        "handling_fee"
      )} (${OVERSEAS_CHARGES}HKD + ${LOCAL_CHARGES}%)`;
    }

    if (
      transaction_type === "top-up-master-card" ||
      transaction_type === "apply-master-card" ||
      transaction_type === "charge" || // Master Card
      transaction_type === "transfer" // Master Card
    ) {
      return `${t("handling_fee")} (${SERVICE_CHARGES}%)`;
    }

    return "";
  };

  return (
    <div className="mt-m flex flex-row items-center">
      <div className="overflow-hidden rounded-full">
        <img
          src={getCurrencyIcon && getCurrencyIcon(currency)}
          alt="currency"
          className="w-[25px] h-[25px] object-cover"
        />
      </div>

      <div className="flex flex-col ml-m flex-1">
        {/* Transaction Type */}
        <p className="font-[700]">
          {type ? t(`type.${getTypeTxt(type)}`) : ""}
        </p>
        <p
          className={`text-s`}
          style={{
            color: txtColor,
          }}
        >
          {/* Transaction Status */}
          {status && (
            <>
              <span className="mx-[5px]">&#8226;</span>
              {t(`status.${status}`)}
            </>
          )}
        </p>
      </div>
      <div className="flex flex-col ml-[20px] flex-1 text-right">
        <div className="flex flex-row justify-end lg:items-center">
          <ClockCircleOutlined className="text-s lg:pt-0 pt-[3px]" />
          <p className="label ml-xs">
            {moment(date).format("DD MMM YYYY HH:mm:ss")}
          </p>
        </div>

        <div className="flex flex-row justify-end items-end h-[20px] bg-pri">
          <p
            className={`font-[600] text-[12px] leading-[14px]`}
            style={{ color: txtColor }}
          >
            {Number(displayAmount).toFixed(2)}
            <span className="text-[8px] ml-[3px]">{displayCurrency}</span>
          </p>

          {bank_type && amount !== received_amount && (
            <p className={`break-keep text-[8px] text-label ml-[3px]`}>
              {renderDisplayFee(bank_type)}
            </p>
          )}

          {type && amount !== received_amount && (
            <p className={`break-keep text-[8px] text-label ml-[3px]`}>
              {renderDisplayFee(type)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
