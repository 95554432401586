import { useTranslation } from "react-i18next";
import { CRYPTO_ASSET_ACTION_LIST } from "src/config/crypto-asset";
import { CryptoAssetFormStepper } from "./crypto-asset-deposit-form-stepper";
import { useMemo } from "react";
import { CryptoAssetWithdrawFormItems } from "./crypto-asset-withdraw-form-items";
import { LoadingIcon } from "./loading-icon";
import { openWarningNotification } from "./notification";
import { getMinimumWithdrawalAmount } from "src/utils";
import * as Utils from "../utils";
import { ICryptoBalanceProps } from "src/type/crypto";

export interface ICryptoAssetWithdrawFormProps {
  isFetching: boolean;
  activeIdx: number;
  assetType: string;
  setAssetType: (val: string) => void;
  toAddress: string;
  setToAddress: (val: string) => void;
  amount: number;
  setAmount: (val: number) => void;
  tokenBalance: number;
  walletTokenList: Array<ICryptoBalanceProps>;
  setModalOpen: (val: boolean) => void;
  getCryptoFeesCb: () => Promise<void>;
}

export const CryptoAssetWithdrawForm = (props: ICryptoAssetWithdrawFormProps) => {
  const { t } = useTranslation();
  const {
    isFetching,
    activeIdx,
    assetType,
    setAssetType,
    toAddress,
    setToAddress,
    amount,
    setAmount,
    tokenBalance,
    walletTokenList,
    setModalOpen,
    getCryptoFeesCb,
  } = props;

  const validationStepper: Array<number> = useMemo(() => {
    //Validate Address length
    if (assetType && amount > 0 && toAddress.length) return [0, 1, 2];
    if (assetType && amount > 0) return [0, 1];
    if (!assetType || assetType === t("please_select")) return [];
    return [0]
  }, [assetType, amount, toAddress, t]);

  const getHeight = (index: number) => {
    if (index === 0) return "60px";
    if (index === 1) return "62px";
    return "0";
  };

  const getPanelTitle = (index: number) => CRYPTO_ASSET_ACTION_LIST[index];

  const handleSubmit = () => {
    // Validate All Input Fields
    if (!assetType)
      return openWarningNotification(t("message.missing_asset_type"));
    if (!amount) return openWarningNotification(t("message.missing_amount"));
    if (!toAddress)
      return openWarningNotification(t("message.missing_to_address"));

    if (amount <= 0) return openWarningNotification(t("message.invalid_amount"));

    // Check Minimum Withdraw Amount
    const minimumAmount: number = getMinimumWithdrawalAmount(assetType);
    if (amount < minimumAmount)
      return openWarningNotification(
        t("message.minimum_withdraw_amount", { amount: minimumAmount })
      );

    const blockchain = assetType.split("_")[1]
    const validateAddr = Utils.isValidTokenAddress(toAddress, blockchain)
    if(!validateAddr) return openWarningNotification(t("message.invalid_crypto_address")); 
    
    // Get Handling Fee
    getCryptoFeesCb();
    setModalOpen(true);
  };

  return (
    <div className="w-full lg:w-[650px] min-h-[100px] bg-white rounded-s drop-shadow p-lg">
      <h3 className="normal-case">{t(getPanelTitle(activeIdx))}</h3>
      <div className="min-h-[480px] flex mt-lg">
        {/* Left Col */}
        <CryptoAssetFormStepper
          validationStepper={validationStepper}
          getHeight={getHeight}
        />
        {/* Right Col */}
        <CryptoAssetWithdrawFormItems
          toAddress={toAddress}
          setToAddress={setToAddress}
          assetType={assetType}
          setAssetType={setAssetType}
          amount={amount}
          setAmount={setAmount}
          options={walletTokenList}
          tokenBalance={tokenBalance}
        />
      </div>

      <div className="mx-xl">
        <button
          className="button-gradient"
          onClick={() => handleSubmit()}
          disabled={isFetching}
        >
          {isFetching ? (
            <LoadingIcon color="#fff" size="16" />
          ) : (
            t("button.submit")
          )}
        </button>
      </div>
    </div>
  );
};

