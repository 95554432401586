import { getCurrencyIcon } from "src/utils";
import { SelectDropdown } from "./select-dropdown";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MyAssetBalance } from "./my-asset-balance";

export const ConvertFormRow = (props: any) => {
  const { t } = useTranslation();

  const {
    balance,
    type,
    label,
    selectValue,
    inputValue = "",
    list,
    handleSelect,
    handleInputChange,
    handleAmountSelect,
    isLoading,
  } = props;

  const getInputValue = useMemo(() => {
    if (type === "from") return inputValue;
    if (type === "to") {
      if (Number(inputValue) < 0) return 0;
      return isLoading ? t("calculating") : inputValue;
    }
    return inputValue;
  }, [type, isLoading, inputValue, t]);

  const showMyBalance = () => {
    if (type === "to") return false;
    return true;
  };

  const getSelectLabel = (type: string) => {
    if (type === "from") return t("from");
    return t("to");
  };

  return (
    <div>
      <p className="content">{label}</p>
      <div className="container-bg-gradient">
        <div>
          <p className={`content mb-xs`}>{getSelectLabel(type)}</p>
          <div className="flex items-center min-w-[145px]">
            {selectValue && (
              <div className="overflow-hidden bg-transparent rounded-full h-[20px]">
                <img
                  src={getCurrencyIcon(selectValue)}
                  className="w-[20px] h-[20px] object-cover"
                  alt="token-icon"
                />
              </div>
            )}
            <div className="convert-select-dropdown">
              <SelectDropdown
                width="100%"
                className="!bg-transparent bg-primary lg:min-w-[95px]"
                options={list}
                value={t(selectValue)}
                handleOnChange={(e: any) => handleSelect(e, type)}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex-1">
          <div className="ml-0 lg:ml-lg">
            {showMyBalance() ? (
              <MyAssetBalance
                title={t("amount")}
                balance={balance}
                token={selectValue}
                handleAmountSelect={handleAmountSelect}
              />
            ) : (
              <div className="h-[15px]" />
            )}

            <input
              className={
                type === "to"
                  ? `cursor-not-allowed text-input !bg-transparent`
                  : "text-input !bg-transparent"
              }
              type={type === "to" ? "text" : "number"}
              value={getInputValue}
              onChange={(e) => handleInputChange(e)}
              disabled={type === "to" ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
