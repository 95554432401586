import axios from "axios";
import * as Configs from "../config";
import { errorHandling, refreshNewToken, setHeader } from "./api";

export class CardStore {
  // Card
  async bindCard(payload: any) {
    const url = `${Configs.API_URL}/v1/card/bind-card`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data.success) return false;
      refreshNewToken(result);
      return result?.data.success;
    } catch (e) {
      return errorHandling(e);
    }
  }
  // MasterCard
  async applyMasterCard(payload: any) {
    const url = `${Configs.API_URL}/v1/master-card/apply`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data.success) return false;
      refreshNewToken(result);
      return result?.data.success;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getMasterCardKycStatus() {
    const url = `${Configs.API_URL}/v1/master-card/kyc-status`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getMasterCardTransactions(args?: any) {
    const url = `${Configs.API_URL}/v1/master-card/transactions${
      args ? args : ""
    }`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async masterCardTopUp(payload: any) {
    const url = `${Configs.API_URL}/v1/master-card/submit-top-up-request`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data.success) return false;
      refreshNewToken(result);
      return result?.data.success;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async editMasterCardPin(payload: any) {
    const url = `${Configs.API_URL}/v1/master-card/edit-pin`;
    try {
      const result: any = await axios.put(url, payload, setHeader());
      if (!result || !result.data.success) return false;
      refreshNewToken(result);
      return result?.data.success;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async editMasterCardDailyLimit(payload: any) {
    const url = `${Configs.API_URL}/v1/master-card/edit-daily-limit`;
    try {
      const result: any = await axios.put(url, payload, setHeader());
      if (!result || !result.data.success) return false;
      refreshNewToken(result);
      return result?.data.success;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async updateMasterCard(args: any) {
    const url = `${Configs.API_URL}/v1/master-card${args ? args : ""}`;
    try {
      const result: any = await axios.put(url, {}, setHeader());
      if (!result || !result.data.success) return false;
      refreshNewToken(result);
      return result?.data.success;
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_CARD = "cardStore";
