import { t } from "i18next";

const { notification } = require("antd");

type NotificationType = "success" | "info" | "warning" | "error";

export const openNotificationWithIcon = (
  type: NotificationType,
  title: string,
  message: string
) => {
  return notification[type]({
    message: title,
    description: message,
  });
};

export const openErrorNotification = (message: string) => {
  return openNotificationWithIcon("error", t("sorry"), message);
};

export const openSuccessNotification = (message: string) => {
  return openNotificationWithIcon("success", t("congrats"), message);
};

export const openWarningNotification = (message: string) => {
  return openNotificationWithIcon("warning", t("sorry"), message);
};
