import { useTranslation } from "react-i18next";
import { Select } from "antd";

export const SelectDropdown = (props:any) => {
  const { t, i18n } = useTranslation();
  const {
    options = [],
    className = "bg-transparent focus:ring-1",
    defaultValue = t("please_select"),
    value = "",
    handleOnChange = () => {},
    width = "155px",
    height = "auto",
    disabled = false,
    prefix = null,
  } = props;

  const { Option } = Select;
  if (!options) return null;

  return (
    <div key={i18n.language} className={`flex flex-row justify-center items-center`}>
      {prefix && prefix}
      <Select
        defaultValue={defaultValue}
        value={value}
        className={className}
        onChange={handleOnChange}
        style={{ width, height }}
        disabled={disabled}
      >
        {options.map((item: any, i: number) => (
          <Option key={i} value={item.value}>
            {t(item.label)}
          </Option>
        ))}
      </Select>
    </div>
  );
};
