interface ICryptoAssetFormStepperProps {
  validationStepper: Array<number>;
  getHeight: (index: number) => string;
}

export const CryptoAssetFormStepper = (props: ICryptoAssetFormStepperProps) => {
  const { validationStepper, getHeight } = props;

  return (
    <div className="hidden lg:flex lg:flex-col">
      {new Array(3).fill(3).map((_, i) => {
        return (
          <div key={i}>
            <div
              className="w-[19px] h-[19px] flex justify-center items-center rounded-full"
              style={
                validationStepper.indexOf(i) > -1
                  ? { backgroundColor: "#E5B75B" }
                  : { borderWidth: 1, borderColor: "#E5B75B" }
              }
            >
              <p
                className="text-primary text-s"
                style={{
                  color: validationStepper.indexOf(i) > -1 ? "#000" : "#E5B75B",
                }}
              >
                {i + 1}
              </p>
            </div>
            <div className="flex justify-center items-center">
              <div
                className={`w-[2px] divide-y divide-dashed bg-primary`}
                style={{ height: getHeight(i) }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
