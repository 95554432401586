import axios from "axios";
import * as Configs from "../config";
import { errorHandling, refreshNewToken, setHeader } from "./api";

export class ExchangeStore {
  async getQuotePrice(payload: any) {
    const url = `${Configs.API_URL}/v1/exchange/quote-price`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async executeQuote(payload: any) {
    const url = `${Configs.API_URL}/v1/exchange/execute-quote`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      // return result?.data?.data;
      return result?.data?.success;
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_EXCHANGE = "exchangeStore";
