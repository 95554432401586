import { createContext, useContext } from "react";
import { STORE_CRYPTO_ASSET, CryptoAssetStore } from "./crypto-asset";
import { STORE_EXCHANGE, ExchangeStore } from "./exchange";
import { STORE_ROUTER, RouterStore, history } from "./router";
import { STORE_USER, UserStore } from "./user";
import { STORE_TRANSACTION, TransactionStore } from "./transaction";
import { CardStore, STORE_CARD } from "./card";

function createStores() {
  return {
    [STORE_ROUTER]: new RouterStore(),
    [STORE_CRYPTO_ASSET]: new CryptoAssetStore(),
    [STORE_EXCHANGE]: new ExchangeStore(),
    [STORE_USER]: new UserStore(),
    [STORE_TRANSACTION]: new TransactionStore(),
    [STORE_CARD]: new CardStore(),
  };
}

export const stores = createStores();

export const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

export function useRouterStore() {
  const { routerStore } = useStores();
  return routerStore;
}

export function useUserStore() {
  const { userStore } = useStores();
  return userStore;
}

export function useTransactionStore() {
  const { transactionStore } = useStores();
  return transactionStore;
}

export function useCardStore() {
  const { cardStore } = useStores();
  return cardStore;
}

export function useCryptoAssetStore() {
  const { cryptoAssetStore } = useStores();
  return cryptoAssetStore;
}

export function useExchangeStore() {
  const { exchangeStore } = useStores();
  return exchangeStore;
}

export { history };

export const generalError = { success: false, error: "general_error" };
