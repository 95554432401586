import axios from "axios";
import * as Configs from "../config";
import { errorHandling, refreshNewToken, setHeader } from "./api";

export class UserStore {
  async checkUsername(username: string) {
    const url = `${Configs.API_URL}/v1/user/check-username?username=${username}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      return result?.data?.success;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async onshoreOpenAccount(payload: any) {
    const url = `${Configs.API_URL}/v1/user/onshore/open-account`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      return result?.data?.success;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async onshoreDeposit(payload: any) {
    const url = `${Configs.API_URL}/v1/user/onshore/deposit`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async onshoreWithdraw(payload: any) {
    const url = `${Configs.API_URL}/v1/user/onshore/withdraw`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getCard() {
    const url = `${Configs.API_URL}/v1/user/card`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getMasterCard() {
    const url = `${Configs.API_URL}/v1/user/master-card`;
    try {
      const result: any = await axios.get(url, setHeader());
      /* apply_status: 3
      available: 0
      available_limit: 0
      balance: 0
      card_limit: 0
      card_number: "4242424242454220"
      card_status: 3
      daily_atm_limit: 1000
      daily_purchase_limit: 1000
      expiry_month: "12"
      expiry_year: "23"
      register_name: "YANG WANG"
      user_id: "65606b3f57a53957e5d28d83" */

      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getOnShoreAccounts() {
    const url = `${Configs.API_URL}/v1/user/onshore/accounts`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getProfile(params?: any) {
    const url = `${Configs.API_URL}/v1/user/profile${params ? params : ""}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getProfileV2(params?: any) {
    const url = `${Configs.API_URL}/v2/user/profile${params ? params : ""}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getProfileBalance() {
    const url = `${Configs.API_URL}/v1/user/balance`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getProfileBalanceV2() {
    const url = `${Configs.API_URL}/v2/user/balance`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getSecuritiesAccount() {
    const url = `${Configs.API_URL}/v1/user/securities/account`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getTransactions({ status = "all", type = "all", limit = 5 }: any) {
    const url = `${Configs.API_URL}/v1/user/transactions?status=${status}&type=${type}&limit=${limit}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async updatePendingTransaction() {
    const url = `${Configs.API_URL}/v1/user/pending-crypto-transaction`;
    try {
      const result: any = await axios.put(url, {}, setHeader());
      if (!result || !result.data?.success) return false;
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async login(payload: any) {
    const url = `${Configs.API_URL}/v1/user/login`;
    try {
      const result: any = await axios.post(url, payload);
      if (!result || !result.data?.success) return false;
      const jwt = result.data?.data?.jwt;
      sessionStorage.setItem("rd-trust-token", jwt);
      // return result?.data?.success;
      return result?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async logout() {
    try {
      sessionStorage.removeItem("rd-trust-token");
      return;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async updatePassword(payload: any) {
    const url = `${Configs.API_URL}/v1/user/update-password`;
    try {
      const result: any = await axios.put(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      return result?.data?.success;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async updateProfile(payload: any) {
    const url = `${Configs.API_URL}/v1/user/update-profile`;
    try {
      const result: any = await axios.put(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      return result?.data?.success;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async registerAccount(payload: any) {
    const url = `${Configs.API_URL}/v1/user/register-account`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;

      return result?.data?.success;
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_USER = "userStore";
