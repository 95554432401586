import { useCallback, useEffect, useState } from "react";
import {
  Container,
  LoadingIcon,
  openWarningNotification,
} from "src/components";
import { Auth } from "src/components/auth";
import { MasterCardApplyCardForm } from "src/components/master-card-applycard-form";
import { MasterCardPanel } from "src/components/master-card-card-panel";
import { MasterCardEditDailyLimitForm } from "src/components/master-card-edit-daily-limit-form";
import { MasterCardEditPinForm } from "src/components/master-card-edit-pin-form";
import { MasterCardInfo } from "src/components/master-card-info";
import { MasterCardLockAndUnlockCardForm } from "src/components/master-card-lock-and-unlock-card-form";
import { MasterCardNoCardPanel } from "src/components/master-card-nocard-panel";
import { MasterCardTopUpForm } from "src/components/master-card-top-up-form";
import { TransactionList } from "src/components/transaction-list";
import { useCardStore, useUserStore } from "src/stores";
import { useTranslation } from "react-i18next";

export const MasterCard = (props: any) => {
  const { t } = useTranslation();

  const [masterCard, setMasterCard] = useState<any>(null);
  const [user, setUser] = useState<any | null>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [transactions, setTransactions] = useState<any | null>(null);
  const [applyStatus, setApplyStatus] = useState<string>("no-master-card");
  const [action, setAction] = useState<string>("");
  const [modalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<string | null>(null); // add-card, top-up, edit-daily-limit, edit pin, card-validity
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [allLoaded, setAllLoaded] = useState(false);

  const { getMasterCard } = useUserStore();
  const {
    editMasterCardPin,
    editMasterCardDailyLimit,
    getMasterCardKycStatus,
    getMasterCardTransactions,
    masterCardTopUp,
    updateMasterCard,
  } = useCardStore();
  const { getProfile } = useUserStore();

  const getTransactionsCb = useCallback(
    async ({ startDate = "", endDate = "" }) => {
      const result = await getMasterCardTransactions(
        startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}` : ""
      );
      if (!result || typeof result !== "object") return setTransactions([]);
      setTransactions(result);
      return;
    },
    [getMasterCardTransactions, setTransactions]
  );

  const getMasterCardCb = useCallback(async () => {
    const result = await getMasterCard();
    if (!result) return;
    setMasterCard(result);
    const { apply_status, card_number = "" } = result;
    if (apply_status) {
      if (apply_status === "3" && card_number) return getTransactionsCb({});
      if (apply_status === "2") return setApplyStatus("applied-master-card");
      if (apply_status === "1") return setApplyStatus("submitted-request");
    }
  }, [getMasterCard, setMasterCard, setApplyStatus, getTransactionsCb]);

  const getMasterCardStatusCb = useCallback(async () => {
    const result = await getMasterCardKycStatus();
    if (!result)
      return openWarningNotification(
        t("message.get_application_status_failed")
      );
    if (result?.apply_status) {
      if (result.apply_status === "3" && result.card_number)
        return getTransactionsCb({});
      if (result.apply_status === "2") setApplyStatus("applied-master-card");
      if (result.apply_status === "1") setApplyStatus("submitted-request");
    }
    setMasterCard(result);
  }, [
    getMasterCardKycStatus,
    setMasterCard,
    setApplyStatus,
    getTransactionsCb,
    t,
  ]);

  useEffect(() => {
    // User Profile
    getProfile(`?document_type=1&document_id=1&document_id_expiry_date=1`).then(
      (data) => {
        if (!data) return;
        setUser(data);
      }
    );
    // Get User Master Card
    getMasterCardCb();
  }, [getProfile, getMasterCardCb, setUser]);

  useEffect(() => {
    if (!user) return;
    setAllLoaded(true);
  }, [user, setAllLoaded]);

  const handleClose = () => setIsModalOpen(false);
  return (
    <Auth>
      <Container showNav={true} showMenu={true} scroll={true}>
        {!allLoaded && (
          <div className="flex flex-1 h-screen w-full justify-center items-center">
            <LoadingIcon color="#D41E44" />
          </div>
        )}

        {allLoaded && (
          <div className="flex flex-col lg:flex-row p-[20px] lg:p-[30px] lg:pt-[100px] ">
            {/* Card Components - Without Master Card */}
            {!masterCard?.card_number && (
              <div>
                <MasterCardNoCardPanel
                  setShowForm={setShowForm}
                  applyStatus={applyStatus}
                />
                {showForm === "add-card" && user && (
                  <div className="mt-[20px]">
                    <MasterCardApplyCardForm
                      documentType={user?.document_type}
                      documentId={user?.document_id}
                      documentIdExpiryDate={user?.document_id_expiry_date}
                      setShowForm={setShowForm}
                      getMasterCardStatusCb={getMasterCardStatusCb}
                    />
                  </div>
                )}
              </div>
            )}

            {/* With Master Card */}
            {masterCard && masterCard?.card_number && (
              <div>
                <MasterCardPanel
                  disabled={!masterCard.card_number || ""}
                  cardStatus={masterCard.card_status || ""}
                  setAction={setAction}
                  setIsModalOpen={setIsModalOpen}
                  setShowForm={setShowForm}
                  isUpdating={isUpdating}
                />
                {showForm && (
                  <div className="mt-[20px]">
                    {showForm === "card-info" && (
                      <MasterCardInfo masterCard={masterCard} />
                    )}
                    {showForm === "top-up" && (
                      <MasterCardTopUpForm
                        masterCardTopUp={masterCardTopUp}
                        getTransactionsCb={getTransactionsCb}
                      />
                    )}
                    {showForm === "edit-pin" && (
                      <MasterCardEditPinForm
                        editMasterCardPin={editMasterCardPin}
                      />
                    )}
                    {showForm === "edit-daily-limit" && (
                      <MasterCardEditDailyLimitForm
                        editMasterCardDailyLimit={editMasterCardDailyLimit}
                        getMasterCardCb={getMasterCardCb}
                      />
                    )}
                  </div>
                )}

                {modalOpen && (
                  <MasterCardLockAndUnlockCardForm
                    action={action}
                    modalOpen={modalOpen}
                    handleClose={handleClose}
                    updateMasterCard={updateMasterCard}
                    getMasterCardCb={getMasterCardCb}
                    cardStatus={masterCard.card_status || ""} // 1: 新卡，2: 已分配, 3: 已激活，4：已失效/已註銷，5: 已掛失
                    isUpdating={isUpdating}
                    setIsUpdating={setIsUpdating}
                  />
                )}
              </div>
            )}

            {/* Transactions */}
            <div className="ml-[0px] mt-[20px] lg:ml-[20px] lg:mt-[0px]">
              <TransactionList
                type={"master-card"}
                transactions={transactions}
                getTransactionsCb={getTransactionsCb}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                cardNumber={masterCard?.card_number || ""}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>
        )}
      </Container>
    </Auth>
  );
};
