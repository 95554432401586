import { useEffect } from "react";
import { useLocation } from "react-router";
import { Header } from "./header";
import { SideMenu } from "./side-menu";

export const Container = (props: any) => {
  const { children, showNav = true, showMenu = false, scroll = false } = props;
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <div
      className={`bg-[#F0F2F5] min-h-screen ${
        scroll && "overflow-y-hidden overflow-x-scroll scroll-smooth"
      }`}
    >
      {showNav && <Header />}

      {showMenu && (
        <div className="flex flex-row">
          <SideMenu />
          {children}
        </div>
      )}

      {!showMenu && <>{children}</>}
    </div>
  );
};
