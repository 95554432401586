export const LOCAL_CHARGES = 2; // In Percentage
export const SERVICE_CHARGES = 1; //In Percentage
export const OVERSEAS_CHARGES = 50; // HKD

export const getTotalReceiveAmount = (type: string, amount: number) => {
  if (!type || !amount) return 0;
  let totalReceivedAmount = amount;

  switch (type) {
    case "local":
    case "card":
      totalReceivedAmount = amount - (amount * LOCAL_CHARGES) / 100;
      break;
    case "onshore-deposit":
    case "transfer-in":
    case "master-card":
      const serviceChargesInHKD = (amount * SERVICE_CHARGES) / 100;
      totalReceivedAmount = amount - serviceChargesInHKD;
      break;
    case "international":
      totalReceivedAmount =
        amount - ((amount * LOCAL_CHARGES) / 100 + OVERSEAS_CHARGES);
      break;
  }
  return String(totalReceivedAmount.toFixed(2));
};

// TO BE UPDATED: Transfer In Charges Varies By Currency
export const getTransferInCharge = (currency: string) => {
  switch (currency) {
    case "HKD":
    case "RMB":
    case "USD":
    case "EUR":
    case "SGD":
      return SERVICE_CHARGES;
    default:
      break;
  }
};
