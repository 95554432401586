import { useTranslation } from "react-i18next";
import { BANK_DETAILS_INFO } from "src/config/bank-details";
import { IOnshoreAccount } from "src/types/onshoreAccount";

interface IOnshoreWalletDetailProps {
  selectedAcc: IOnshoreAccount
}

export const OnshoreWalletDetail = (props: IOnshoreWalletDetailProps) => {
  const { selectedAcc } = props;
  const { t } = useTranslation();

  return (
    <div className="w-full bg-white rounded-m drop-shadow p-m">
      <h3 className="normal-case">{t("onshore_wallet.account_info")}</h3>

      {/* Bank Info */}
      <div className="flex flex-col mx-l border rounded-s bg-form-bg mt-m p-m">
        {BANK_DETAILS_INFO.length > 0 &&
          BANK_DETAILS_INFO.map((item: any, i: number) => {
            return (
              <div className={`${$row} ${i === 0 && "!pt-0"}`} key={i}>
                <p className="label">{t(item.label)}</p>
                <p className="font-bold">{item.name}</p>
              </div>
            );
          })}
        {/* Personal Account */}
        <div className={`${$row}`}>
          <p className="label">
            {t("onshore_wallet.personal_account_number")}
          </p>
          <p className="font-bold">{selectedAcc?.personal_account_number}</p>
        </div>
        {/* Type */}
        <div className={`${$row}`}>
          <p className="label">{t("onshore_wallet.account_type")}</p>
          <p className="font-bold">{selectedAcc?.currency}</p>
        </div>
      </div>
    </div>
  );
};
const $row = "flex flex-row justify-between items-center pt-xs";
