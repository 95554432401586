import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export const MasterCardUploadButton = (props: any) => {
  const { t } = useTranslation();
  const { isUpLoading } = props;
  return (
    <button
      className="text-[#D41E44] bg-transparent rounded-[5px]"
      type="button"
    >
      {isUpLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="mt-[8px]">{t("masterCard.upload")}</div>
    </button>
  );
};
