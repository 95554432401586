import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { history } from "src/stores";
import { ISideMenu } from "src/types/sideMenu";
import { IUser } from "src/types/user";

interface ISideMenuList {
  data: ISideMenu[];
  activeIds: string[];
  openExpandModel: (v1: string, v2: ISideMenu) => void;
  setShowActivateAccModal: (val: boolean) => void;
  user: IUser;
}

export const SideMenuList = (props: ISideMenuList) => {
  const { t } = useTranslation();
  const {
    data = [],
    activeIds,
    openExpandModel,
    setShowActivateAccModal,
    user,
  } = props;

  const handleMenu = (item: any) => {
    const requireKycOne = ["/securities", "/master-card"];
    if (requireKycOne.indexOf(item?.page) > -1) {
      if (!user?.kyc_level || user?.kyc_level === "0") {
        return setShowActivateAccModal(true);
      }
    }

    if (!item?.subMenu || item?.subMenu.length === 0) {
      return item?.page && history.push(item?.page);
    }
    const direction = activeIds.indexOf(item.id) > -1 ? "up" : "down";
    openExpandModel(direction, item);
  };

  return (
    <>
      {data.map((item: any, i: number) => {
        return (
          <div key={i} className={`mt-[15px] cursor-pointer`}>
            <div
              className="flex flex-row items-center justify-center lg:justify-start"
              onClick={() => handleMenu(item)}
            >
              {item.icon}
              <h3 className={$menu}>
                {t(item.name)} {!item?.page && `(${t("coming_soon")})`}
              </h3>
              {/* Arrow Icon */}
              <div className="flex-1 justify-end hidden lg:flex">
                {item.subMenu.length > 0 && activeIds.indexOf(item.id) < 0 && (
                  <DownOutlined className={$arrowIcon} />
                )}

                {item.subMenu.length > 0 && activeIds.indexOf(item.id) > -1 && (
                  <UpOutlined className={$arrowIcon} />
                )}
              </div>
            </div>

            <div
              className={`ml-[30px] mt-[5px] transition-all duration-300`}
              style={{
                height: activeIds.indexOf(item.id) < 0 ? "0px" : "auto",
              }}
            >
              {activeIds.indexOf(item.id) > -1 &&
                item.subMenu.map((menu: any, idx: number) => {
                  return (
                    <div className="py-[5px]" key={idx}>
                      <p className="text-blue text-[11px]">{menu.name}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </>
  );
};

const $arrowIcon = "!text-blue text-[10px]";
const $menu =
  "normal-case text-[13px] text-blue cursor-pointer ml-[10px] hidden lg:block";
