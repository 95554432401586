
import { ReactComponent as CloseIcon } from "../svg-icons/close.svg";

interface ICloseButtonProps{
  handleCancel: () => void;
  position?: string;
  disabled?: boolean;
}

export const CloseButton = (props: ICloseButtonProps) => {
  const { handleCancel, position = "absolute", disabled = false } = props;

  return (
    <div
      className={`${
        position === "absolute" && "absolute top-[20px] right-[20px]"
      } 
      ${disabled ? "cursor-not-allowed" : "cursor-pointer"} 
      z-10 w-[20px] h-[20px]`}
      onClick={() => handleCancel()}
    >
      <CloseIcon />
    </div>
  );
};
