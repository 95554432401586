import { getColor, getCurrencyIcon } from "src/utils";
import { useState } from "react";
import TransactionRow from "./transaction-row";
import { LoadingIcon } from "./loading-icon";
import { TransactionFilterButtons } from "./transaction-filter-buttons";
import { useTranslation } from "react-i18next";
import { ITransactionsBase, TransactionStatus } from "src/type/transaction";

export interface ITransactionRowProps extends ITransactionsBase {
  getCurrencyIcon?: (value: string) => string;
}

interface IDashboardTransactionListProps {
  transactions: Array<ITransactionRowProps>;
  getTransactionsCb: (params: {
    status?: string;
    type?: string;
    limit?: number;
    startDate?: string;
    endDate?: string;
  }) => Promise<void>;
}

export const DashboardTransactionList = (
  props: IDashboardTransactionListProps
) => {
  const { t } = useTranslation();
  const { transactions, getTransactionsCb } = props;
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="w-full lg:w-[545px] min-h-[100px] h-full overflow-x-auto bg-white rounded-[10px] drop-shadow p-[20px]">
      <div className="flex justify-between">
        <div className="flex-1">
          <h3 className="normal-case">
            {t("transaction.recent_transaction_history")}
          </h3>
        </div>
        {/* <p
          className="text-primary cursor-pointer"
          onClick={() => history.push("/transactions")}
        >
          {t("transaction.view_all_transactions")}
        </p> */}
      </div>

      <TransactionFilterButtons
        fromPage="dashboard"
        setIsLoading={setIsLoading}
        getTransactionsCb={getTransactionsCb}
      />

      {/* Transactions */}
      <div className="mt-m">
        {isLoading && (
          <div>
            <LoadingIcon />
          </div>
        )}
        {!isLoading && transactions && transactions.length === 0 && (
          <>
            <p className="text-center">
              {t("transaction.no_transaction_record")}
            </p>
          </>
        )}
        {!isLoading &&
          transactions &&
          transactions.length > 0 &&
          transactions.map((item: ITransactionRowProps, i: number) => {
            const {
              type,
              status = TransactionStatus.pending,
              amount,
              currency,
              created_at,
              bank_type,
              received_amount,
              received_currency,
            } = item;
            const txtColor = getColor(status);

            return (
              <div key={i}>
                <TransactionRow
                  getCurrencyIcon={getCurrencyIcon}
                  amount={amount}
                  currency={currency}
                  date={created_at || ""}
                  status={status}
                  type={type}
                  txtColor={txtColor}
                  bank_type={bank_type}
                  received_amount={received_amount}
                  received_currency={received_currency}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
