import { IOnshoreAccount } from "src/types/onshoreAccount";
import { OnshoreWalletToken } from "./onshore-wallet-token";
import { IBalanceProps } from "src/types/profile";
import { useTranslation } from "react-i18next";
import { IUser } from "src/types/user";

interface IOnshoreWalletSummary {
  onshoreAccounts: IOnshoreAccount[];
  balance: IBalanceProps;
  setAction: (value: string) => void;
  resetFormCb?: () => void;
  selectedAcc: IOnshoreAccount | null | undefined;
  setShowActivateAccModal: (val: boolean) => void;
  setConfirmOpenAccModal: (val: boolean) => void;
  user: IUser;
}

export const OnshoreWalletSummary = (props: IOnshoreWalletSummary) => {
  const { t } = useTranslation();
  const {
    onshoreAccounts,
    balance,
    setAction,
    resetFormCb,
    selectedAcc,
    user,
    setShowActivateAccModal,
    setConfirmOpenAccModal,
  } = props;

  return (
    <div className="w-full lg:w-[650px] bg-white rounded-m drop-shadow p-m flex-wrap">
      <h3 className="normal-case">{t("onshore_wallet.summary")}</h3>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full">
          {/* Aggregate Balance & Wallet Token */}
          <div className="mt-m">
            <div className="flex flex-col">
              <div className="flex items-end">
                <h2>
                  {balance?.onshoreBalance
                    ? Number(balance?.onshoreBalance).toFixed(2)
                    : "0.00"}
                </h2>
                <p className="pl-xxs relative top-[2px]">HKD</p>
              </div>
              <p className={`mt-xxs ${$titleStyle}`}>
                &#8776;$
                {balance?.onshoreBalance
                  ? Number(balance?.onshoreBalance / 7.8).toFixed(2)
                  : "0.00"}
                USD
              </p>
            </div>
          </div>

          {/* All Currency Balance */}
          <OnshoreWalletToken
            setAction={setAction}
            resetFormCb={resetFormCb}
            onshoreAccounts={onshoreAccounts}
            selectedAcc={selectedAcc}
            user={user}
            setShowActivateAccModal={setShowActivateAccModal}
            setConfirmOpenAccModal={setConfirmOpenAccModal}
          />
        </div>
      </div>
    </div>
  );
};

const $titleStyle = "text-txt text-xs";
