import {
  CONVERT_SELECT_FROM_TOKEN_LIST,
  CONVERT_SELECT_TO_TOKEN_LIST,
} from "src/config/crypto-asset";
import { ConvertFormRow } from "./convert-form-row";
import { useEffect, useState } from "react";
import { ModalConfirmConvert } from "./modal-confirm-convert";
import { openWarningNotification } from "./notification";
import { getMinimumExchangeUnit } from "src/utils";
import { LoadingIcon } from "./loading-icon";
import { useTranslation } from "react-i18next";
import * as Utils from "../utils";

export const ConvertForm = (props: any) => {
  const {
    fromCurrency,
    toCurrency,
    fromAmount,
    toAmount,
    userBalance,
    exchangeRate,
    latestExchangeTime,
    timeCount,
    quoteId,
    isLoading,
    setTimeCount,
    setFromAmount,
    setToAmount,
    setToCurrency,
    setFromCurrency,
    setIsLoading,
    setQuoteId,
    executeQuote,
    getExchangeRateCb,
    getCryptoAccountCb,
  } = props;

  const { t } = useTranslation();
  const [modalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (Number(fromAmount) > userBalance) return setFromAmount(userBalance);
  }, [fromAmount, userBalance, setFromAmount]);

  const handleConfirm = async () => {
    setIsLoading(true);
    const result = await executeQuote({ quote_id: quoteId });
    setIsLoading(false);
    if (!result) {
      setTimeCount(0);
      openWarningNotification(t("message.execute_quote_failed"));
      return;
    }
    // Execute success
    setIsModalOpen(false);
    // Reset Form
    setFromAmount(0);
    setToAmount(0);
    setTimeCount(0);
    // Fetch Balance Once Success
    getCryptoAccountCb();
  };

  const handleGetQuotePrice = () => {
    const tokenName = fromCurrency.split("_")[0];
    const minimumExchangeUnit = getMinimumExchangeUnit(fromCurrency);
    if (Number(fromAmount) < minimumExchangeUnit)
      return openWarningNotification(
        t("message.minimum_top_up_amount", {
          minimumExchangeUnit,
          fromCurrency: tokenName,
        })
      );
    if (fromCurrency && toCurrency && Number(fromAmount) > 0)
      return getExchangeRateCb();
  };

  const openConfirmModal = () => {
    if (!fromAmount)
      return openWarningNotification(t("message.missing_amount"));
    if (userBalance < Number(fromAmount))
      return openWarningNotification(t("message.insufficient_balance"));

    if (Number(fromAmount) <= 0)
      return openWarningNotification(t("message.insufficient_balance"));
    const tokenName = fromCurrency.split("_")[0];
    const minimumExchangeUnit = getMinimumExchangeUnit(fromCurrency);
    if (Number(fromAmount) < minimumExchangeUnit)
      return openWarningNotification(
        t("message.minimum_top_up_amount", {
          minimumExchangeUnit,
          fromCurrency: tokenName,
        })
      );
    setIsModalOpen(true);
  };

  const handleSelect = (e: any, type: string) => {
    if (type === "from") setFromCurrency(e);
    if (type === "to") setToCurrency(e);
    // Update balance
    setQuoteId("");
    setToAmount("");
    setTimeCount(0);
  };

  const handleInputChange = (e: any) => {
    setFromAmount(e.target.value);
    if (!e.target.value) return setToAmount("");
    setQuoteId("");
    setToAmount("");
    setTimeCount(0);
  };

  const handleAmountSelect = () => setFromAmount(userBalance);

  const handleModalClose = () => {
    if (isLoading) return;
    setIsModalOpen(false);
  };

  return (
    <div className="w-full h-fit lg:w-[650px] bg-white rounded-[10px] drop-shadow p-[20px]">
      <h3 className="normal-case">{t("convert")}</h3>
      <div>
        <div className="mt-[20px]">
          {/* From Panel */}
          <ConvertFormRow
            type="from"
            label={t("select_token")}
            balance={userBalance}
            selectValue={fromCurrency}
            inputValue={fromAmount}
            handleSelect={handleSelect}
            handleInputChange={handleInputChange}
            handleAmountSelect={handleAmountSelect}
            list={CONVERT_SELECT_FROM_TOKEN_LIST}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />

          <div className="mt-[20px] flex justify-center rotate-90">
            <img
              src={"/assets/swap.png"}
              className="w-[30px] h-[30px] object-cover"
              alt="token-icon"
            />
          </div>

          <div>
            {/* To Panel */}
            <ConvertFormRow
              label={t("convert_token")}
              type="to"
              selectValue={toCurrency}
              inputValue={toAmount}
              handleSelect={handleSelect}
              handleInputChange={handleInputChange}
              list={CONVERT_SELECT_TO_TOKEN_LIST}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </div>
        </div>
        <div className="flex flex-row justify-center items-center">
          {isLoading && (
            <div className="w-full">
              <LoadingIcon size={"35"} color="#D41E44" />
            </div>
          )}

          {/* Exchange Rate */}
          {!isLoading && quoteId && (
            <div className="w-full">
              <p className="mt-[10px] text-[10px] text-center">
                {`${t("exchange_rate_info")} 1 ${Utils.getTokenLabel(
                  fromCurrency
                )} = ${Number(exchangeRate).toFixed(2)} ${toCurrency}`}
              </p>
              {timeCount > 0 && (
                <p className="mt-[5px] text-[10px] text-primary text-center">
                  {t("exchange_rate_validity", { timeCount })}
                </p>
              )}
            </div>
          )}
        </div>

        <div className="mt-[20px]">
          <button
            className={$btn}
            onClick={() =>
              quoteId ? openConfirmModal() : handleGetQuotePrice()
            }
          >
            {quoteId ? t("button.submit") : t("button.get_quote")}
          </button>
        </div>
      </div>

      {/* Confirm Exchange Modal */}
      <ModalConfirmConvert
        isLoading={isLoading}
        modalOpen={modalOpen}
        fromCurrency={fromCurrency}
        toCurrency={toCurrency}
        fromAmount={fromAmount}
        toAmount={toAmount}
        exchangeRate={exchangeRate}
        handleClose={() => handleModalClose()}
        handleConfirm={handleConfirm}
        handleOpen={() => setIsModalOpen(true)}
        latestExchangeTime={latestExchangeTime}
        timeCount={timeCount}
      />
    </div>
  );
};

const $btn =
  "w-full bg-gradient-to-r from-[#FFD6D9] to-[#D41E44] text-black text-[14px] font-[700]";
