import { useState } from "react";
import { openWarningNotification } from "./notification";
import { useTranslation } from "react-i18next";
import { ModalOnshoreWalletDepositPaymentQR } from "./modal-onshore-wallet-deposit-payment-qr";
import { LoadingIcon } from "./loading-icon";
import { IDepositRequest, IPaymentResponse } from "src/types/onshoreDeposit";
import { useUserStore } from "src/stores";

interface IOnshoreWalletDepositQRForm {
  amount: number;
  currency: string;
  method: string;
  qrCode: string;
  isLoading: boolean;
  setAmount: (value: number) => void;
  setQrCode: (value: string) => void;
  setIsLoading: (value: boolean) => void;
  errorMessage: string;
  showErrorMessage: (value: string) => void;
}

export const OnshoreWalletDepositQRForm = (
  props: IOnshoreWalletDepositQRForm
) => {
  const { t } = useTranslation();
  const { onshoreDeposit } = useUserStore();

  const {
    amount,
    currency,
    method,
    qrCode,
    isLoading,
    setAmount,
    setQrCode,
    setIsLoading,
    errorMessage,
    showErrorMessage,
  } = props;

  const [modalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleClose = () => {
    setIsModalOpen(false);
    // Reset to default
    setQrCode("");
    return;
  };

  const openQrModal = async () => {
    if (!amount) return openWarningNotification(t("message.missing_amount"));
    if (amount <= 0)
      return openWarningNotification(t("message.invalid_amount"));
    setIsLoading(true);
    const payload: IDepositRequest = {
      type: "qr",
      amount,
      currency,
      method,
    };
    // Create Payment QR
    const result: IPaymentResponse = await onshoreDeposit(payload);
    setIsLoading(false);
    if (!result || !result?.qr_code) {
      showErrorMessage("message.create_payment_qr_failed");
      return;
    }
    const { qr_code } = result;
    setQrCode(qr_code);
    setIsModalOpen(true);
  };

  return (
    <div className="w-auto min-h-[100px] bg-white rounded-xs mt-m">
      <h3 className="normal-case">
        {t("pay_with", {
          method: t(`onshore_wallet.${method}`),
        })}
      </h3>
      <div className="mt-m">
        <p className="label">{t("payment_method")}</p>
        <input
          className="input"
          type="text"
          value={t(`onshore_wallet.${method}`)}
          disabled={true}
        />
      </div>

      <div className="mt-xs">
        <p className="label">{t("onshore_wallet.onshore_deposit_transfer_amount")}</p>
        <input
          className="input"
          type="number"
          value={amount}
          min="0"
          pattern="[0-9]"
          onChange={(e: any) => setAmount(Number(e.target?.value))}
        />
      </div>

      {errorMessage && (
        <div className="mt-xs">
          <p className="text-txt">{t(errorMessage)}</p>
        </div>
      )}

      <div className="mt-m" onClick={() => openQrModal()}>
        <button className="gradient" disabled={isLoading}>
          {isLoading ? <LoadingIcon size={"25"} /> : t("button.submit")}
        </button>
      </div>

      {/* Payment QR Modal */}
      <ModalOnshoreWalletDepositPaymentQR
        isLoading={isLoading}
        modalOpen={modalOpen}
        qrCode={qrCode}
        method={method}
        handleClose={handleClose}
      />
    </div>
  );
};
