import { CryptoAssetFormStepper } from "./crypto-asset-deposit-form-stepper";
import { CryptoAssetDepositFormItems } from "./crypto-asset-deposit-form-items";
import { useMemo, useState } from "react";
import {
  CRYPTO_ASSET_ACTION_LIST,
  CRYPTO_ASSET_BITCOIN_NETWORK,
  CRYPTO_ASSET_ETHEREUM_NETWORK,
  CRYPTO_ASSET_USDT_NETWORK,
} from "src/config/crypto-asset";
import { useCryptoAssetStore } from "src/stores";
import { useTranslation } from "react-i18next";
import { openWarningNotification } from "./notification";

interface ICryptoAssetDepositFormProps {
  activeIdx: number;
  network: string;
  setNetwork: (val: string) => void;
  token: string;
  setToken: (val: string) => void;
  depositAddress: string;
  setDepositAddress: (val: string) => void;
}

export interface INetworkOptListProps {
  value: string;
  label?: string;
  name: string;
  chain_id?: number;
}

export const CryptoAssetDepositForm = (props: ICryptoAssetDepositFormProps) => {
  const { t } = useTranslation();
  const {
    network,
    setNetwork,
    token,
    setToken,
    activeIdx,
    depositAddress,
    setDepositAddress,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getCryptoAddress } = useCryptoAssetStore();

  const validationStepper = useMemo(() => {
    if (
      token !== t("select_token") &&
      network &&
      network !== t("choose_network")
    )
      return [0, 1, 2];
    if (token && token !== t("select_token")) return [0];
    if (network && network !== t("choose_network")) return [0, 1];
    return [0];
  }, [token, network, t]);

  const networkOptionList: Array<INetworkOptListProps> = useMemo(() => {
    if (!token) return [];
    if (token === "BTC") return CRYPTO_ASSET_BITCOIN_NETWORK;
    if (token === "USDT") return CRYPTO_ASSET_USDT_NETWORK;
    return CRYPTO_ASSET_ETHEREUM_NETWORK;
  }, [token]);

  const getPanelTitle = (index: number) => CRYPTO_ASSET_ACTION_LIST[index];

  const handleSelectToken = (name: string) => {
    if (name === token) return setToken(t("select_token")); // Reset
    setToken(name);
  };

  const handleSelectNetwork = (value: string) => {
    setNetwork(value);
    getWalletAddress(value);
  };

  const getHeight = (index: number) => {
    if (index === 0) return "125px";
    if (index === 1) return "68px";
    return "0";
  };

  const getWalletAddress = async (type: string) => {
    if (!type) setDepositAddress("");
    setIsLoading(true);
    const result = await getCryptoAddress(`?type=${type}`);
    if (!result)
      return openWarningNotification(t("message.get_address_failed"));
    setIsLoading(false);
    const { address } = result;
    setDepositAddress(address);
  };

  return (
    <div className="w-full lg:w-[650px] min-h-[100px] bg-white rounded-s drop-shadow p-lg">
      <h3 className="normal-case">
        {t(getPanelTitle(activeIdx))}

        {t("crypto_asset.crypto_asset_label")}
      </h3>
      <div className="min-h-[480px] flex mt-lg">
        {/* Left Col */}
        <CryptoAssetFormStepper
          validationStepper={validationStepper}
          getHeight={getHeight}
        />
        {/* Right Col */}
        <CryptoAssetDepositFormItems
          isLoading={isLoading}
          address={depositAddress}
          token={token}
          handleSelectToken={handleSelectToken}
          network={network}
          setNetwork={setNetwork}
          handleSelectNetwork={handleSelectNetwork}
          networkOptionList={networkOptionList}
        />
      </div>
    </div>
  );
};
