export const getMinimumWithdrawalAmount = (asset: string) => {
  let amount = 0;
  switch(asset){
    case "USDC_ERC":
    case "USDT_ERC":
      amount = 10
      break;
    case "USDT_TRC":
      amount = 5
      break;
    case "ETH":
    case "BTC":
      amount = 1
      break;
  }
  return amount
}

export const isValidTokenAddress = (address:string, type: string) => {
  if(type === "ERC") return /^(0x)?[0-9a-fA-F]{40}$/.test(address);
  // Tron addresses start with 'T' followed by base58 characters.
  if(type === "TRC") return /^T[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{33}$/.test(address);
  return false;
}
