import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { LoadingIcon } from "./loading-icon";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { useTranslation } from "react-i18next";

export const MasterCardLockAndUnlockCardForm = (props: any) => {
  const { t } = useTranslation();

  const {
    action,
    cardStatus,
    modalOpen,
    handleClose,
    updateMasterCard,
    getMasterCardCb,
    isUpdating,
    setIsUpdating,
  } = props;

  const handleConfirm = async () => {
    if (action === t("button.lock_card") && Number(cardStatus) === 5)
      return openWarningNotification(t("message.update_failed")); // 5-已激活
    if (action === t("button.unlock_card") && Number(cardStatus) < 5)
      return openWarningNotification(t("message.update_failed")); // 3-已掛失

    let actionType = "";
    if (action === "lock_card") actionType = "lock";
    if (action === "unlock_card") actionType = "unlock";

    setIsUpdating(true);
    const result = await updateMasterCard(`?action=${actionType}`);
    setIsUpdating(false);
    if (!result) return openWarningNotification(t("message.update_failed"));
    openSuccessNotification(t("message.update_success"));
    // Get Master Card
    getMasterCardCb();
    handleClose();
    return;
  };

  return (
    <Modal
      visible={modalOpen}
      onOk={handleClose}
      onCancel={handleClose}
      footer={null}
      centered={true}
      className="rounded-xs"
    >
      <CloseButton handleCancel={handleClose} />
      <div className="flex flex-col justify-start align-start min-h-[220px]">
        <div>
          <h3 className="my-lg text-center normal-case">
            {`${t("masterCard.confirm")} ${t(`button.${action}`)}`}
          </h3>
          <p className="content text-center mt-xl">
            {t("masterCard.are_you_sure", { action: t(`button.${action}`) })}
          </p>
          <div className="mt-lg w-full">
            <button className="button-gradient" onClick={() => handleConfirm()}>
              {isUpdating ? <LoadingIcon size={"25"} /> : t("button.confirm")}
            </button>
          </div>
          <div className="mt-lg w-full">
            <button className="button-secondary !bg-[#eee]" onClick={() => handleClose()}>
              {t("button.cancel")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

