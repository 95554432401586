import { openSuccessNotification } from "src/components/notification";

export const copyToClipboard = (value: string) => {
  const element = document.createElement("textarea");
  element.value = value;
  document.body.appendChild(element);
  element.select();
  document.execCommand("copy");
  openSuccessNotification("複製成功");
  document.body.removeChild(element);
};
