import { EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export const UpdateProfileAccountCenter = (props: any) => {
  const { displayName, setIsModalOpen, actionList, setActionList } = props;
  const { t } = useTranslation();

  const handleModalOpen = (type: string) => {
    setIsModalOpen(true);
    const hasExist = actionList.find((item: any) => item === type);
    if (!hasExist) {
      actionList.push(type);
      setActionList(actionList);
      return;
    }
  };

  return (
    <div className="w-full lg:w-[545px] min-h-[100px] bg-white rounded-s drop-shadow p-lg">
      <h3 className="normal-case">{t("account_center")}</h3>
      {/* Account Center Form */}
      <div className="mt-l">
        <div className="relative">
          <p className={$titleStyle}>{t("display_name")}</p>
          <div
            className="flex-1"
            onClick={() => handleModalOpen("display_name")}
          >
            <p className={$inputStyle}>{displayName}</p>
          </div>
          <EditOutlined
            className={$icon}
            onClick={() => handleModalOpen("display_name")}
          />
        </div>

        <div className="relative mt-s">
          <p className={$titleStyle}>{t("account_password")}</p>
          <div className="flex-1" onClick={() => handleModalOpen("password")}>
            <p className={`${$inputStyle} pt-s`}>*********</p>
          </div>
          <EditOutlined
            className={$icon}
            onClick={() => handleModalOpen("password")}
          />
        </div>
      </div>

      <div className="mt-l">
        <button
          className="button-gradient"
          onClick={() => handleModalOpen("password")}
        >
          {t("edit_account_password")}
        </button>
      </div>
    </div>
  );
};

// const $btn ="bg-gradient-to-r from-[#FFD6D9] to-[#D41E44] w-full text-black text-[14px] font-[700]";
const $icon =
  "!text-content text-m absolute bottom-[12px] right-[20px] cursor-pointer";
const $inputStyle =
  "bg-white border pl-m mt-xs rounded-xs w-full h-[40px] flex justify-start items-center";
const $titleStyle = "text-content";
