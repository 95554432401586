import { useState } from "react";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { LoadingIcon } from "./loading-icon";
import { useTranslation } from "react-i18next";

export const MasterCardEditDailyLimitForm = (props: any) => {
  const { t } = useTranslation();

  const { editMasterCardDailyLimit, getMasterCardCb } = props;
  const [dailyPurchaseLimit, setDailyPurchaseLimit] = useState<number>(0);
  const [dailyAtmLimit, setDailyAtmLimit] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleConfirm = async () => {
    if (isLoading) return;
    if (Number(dailyPurchaseLimit) <= 0 || Number(dailyAtmLimit) <= 0)
      return openWarningNotification(t("message.invalid_amount"));
    if (!dailyPurchaseLimit)
      return openWarningNotification(t("message.missing_daily_purchase_limit"));
    if (!dailyAtmLimit)
      return openWarningNotification(t("message.missing_daily_atm_limit"));
    setIsLoading(true);
    const result = await editMasterCardDailyLimit({
      dailyPurchaseLimit: Number(dailyPurchaseLimit),
      dailyAtmLimit: Number(dailyAtmLimit),
    });
    setIsLoading(false);
    if (!result) return openWarningNotification(t("message.update_failed"));
    openSuccessNotification(t("message.update_success"));
    // Reset Form
    setDailyPurchaseLimit(0);
    setDailyAtmLimit(0);
    getMasterCardCb();
    return;
  };

  return (
    <div className="w-full lg:w-[545px] bg-white rounded-s drop-shadow p-lg">
      <h3 className="normal-case">{t("masterCard.modify_daily_limit_form")}</h3>

      <div className="mt-lg">
        <p className="content">{`${t("masterCard.daily_purchase_limit")} (HKD)`}</p>
        <input
          className="text-input"
          type="number"
          value={dailyPurchaseLimit}
          min="0"
          pattern="[0-9]"
          onChange={(e: any) => setDailyPurchaseLimit(e.target?.value)}
        />
      </div>
      <div className="mt-s">
        <p className="content">{`${t("masterCard.daily_atm_limit")} (HKD)`}</p>
        <input
          className="text-input"
          type="number"
          value={dailyAtmLimit}
          min="0"
          pattern="[0-9]"
          onChange={(e: any) => setDailyAtmLimit(e.target?.value)}
        />
      </div>

      <div className="mt-lg" onClick={() => handleConfirm()}>
        <button className="button-gradient" disabled={isLoading}>
          {isLoading ? <LoadingIcon size={"25"} /> : t("button.submit")}
        </button>
      </div>
    </div>
  );
};

