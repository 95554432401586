import { useState } from "react";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { TransferTotalReceiveAmount } from "./transfer-total-receive-amount";
import { getTotalReceiveAmount } from "src/config/charges";
import { LoadingIcon } from "./loading-icon";
import { useTranslation } from "react-i18next";

export const MasterCardTopUpForm = (props: any) => {
  const { t } = useTranslation();
  const { getTransactionsCb, masterCardTopUp, currencyType = "HKD" } = props;
  const [amount, setAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleConfirm = async () => {
    if (isLoading) return;
    if (!amount) return openWarningNotification(t("message.missing_amount"));
    if (Number(amount) <= 0)
      return openWarningNotification(t("message.invalid_amount"));
    setIsLoading(true);
    const result = await masterCardTopUp({
      amount: Number(amount),
      currency: currencyType,
    });
    setIsLoading(false);
    if (!result) return openWarningNotification(t("message.submission_failed"));
    openSuccessNotification(t("message.submission_successful"));
    // Reset Form
    setAmount(0);
    // Fetch Transactions
    getTransactionsCb({});
    return;
  };

  return (
    <div className="w-full lg:w-[545px] bg-white rounded-s drop-shadow p-lg">
      <h3 className="normal-case">{t("masterCard.top_up_form")}</h3>

      <div className="mt-lg">
        <p className="content">{t("masterCard.currency")}</p>
        <input
          className="text-input"
          type="text"
          value={currencyType}
          disabled={true}
        />
      </div>
      <div className="mt-s">
        <p className="content">{t("masterCard.transfer_amount")}</p>
        <input
          className="text-input"
          type="number"
          value={amount}
          min="0"
          pattern="[0-9]"
          onChange={(e: any) => setAmount(e.target?.value)}
        />
      </div>

      <div className="mt-lg" onClick={() => handleConfirm()}>
        <button className="button-gradient" disabled={isLoading}>
          {isLoading ? <LoadingIcon size={"25"} /> : t("button.submit")}
        </button>
      </div>

      <TransferTotalReceiveAmount
        type="master-card"
        currency={currencyType}
        amount={getTotalReceiveAmount("master-card", amount)}
      />
    </div>
  );
};

