interface InputFieldProps {
  name: string;
  value: string;
  type?: string;
  placeholder?: string;
  title: string;
  customStyle?: string;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputField = (props: InputFieldProps) => {
  const {
    name,
    value,
    type = "text",
    placeholder,
    title,
    customStyle,
    onChange,
    required = true,
  } = props;

  return (
    <div
      className={`w-full ${customStyle ? `${customStyle} flex-1` : "flex-1"}`}
    >
      <p className="label">
        {title}
        {required && "*"}
      </p>
      <input
        name={name}
        value={value || ""}
        placeholder={placeholder}
        className={$inputStyle}
        type={type}
        onChange={onChange}
      />
    </div>
  );
};

const $inputStyle =
  "border bg-transparent px-xs py-xxs mt-xxs rounded-s w-full h-[40px] focus-visible:!outline-input-ring";
