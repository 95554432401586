import { useState } from "react";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { LoadingIcon } from "./loading-icon";
import { useTranslation } from "react-i18next";

export const MasterCardEditPinForm = (props: any) => {
  const { t } = useTranslation();

  const { editMasterCardPin } = props;
  const [oldPin, setOldPin] = useState<string>("");
  const [newPin, setNewPin] = useState<string>("");
  const [confirmNewPin, setConfirmNewPin] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleConfirm = async () => {
    if (isLoading) return;
    if (!oldPin)
      return openWarningNotification(t("message.please_fill_in_old_password"));
    if (!newPin)
      return openWarningNotification(t("message.please_fill_in_new_password"));

    if (!confirmNewPin)
      return openWarningNotification(
        t("message.please_fill_in_confirm_new_password")
      );
    if (oldPin === newPin)
      return openWarningNotification(
        t("message.please_fill_in_different_password")
      );
    if (newPin !== confirmNewPin)
      return openWarningNotification(t("message.password_not_match"));

    setIsLoading(true);
    const result = await editMasterCardPin({
      newPin: confirmNewPin,
    });
    setIsLoading(false);
    if (!result) return openWarningNotification(t("message.update_failed"));
    openSuccessNotification(t("message.update_success"));
    // Reset Form
    setOldPin("");
    setNewPin("");
    setConfirmNewPin("");
    return;
  };

  return (
    <div className="w-full lg:w-[545px] bg-white rounded-s drop-shadow p-lg">
      <h3 className="normal-case">{t("masterCard.modify_atm_pin_form")}</h3>

      <div className="mt-lg">
        <p className="content">{t("old_password")}</p>
        <input
          className="text-input"
          type="password"
          value={oldPin}
          min="0"
          pattern="[0-9]"
          onChange={(e: any) => setOldPin(e.target?.value)}
        />
      </div>
      <div className="mt-s">
        <p className="content">{t("new_password")}</p>
        <input
          className="text-input"
          type="password"
          value={newPin}
          min="0"
          pattern="[0-9]"
          onChange={(e: any) => setNewPin(e.target?.value)}
        />
      </div>

      <div className="mt-s">
        <p className="content">{t("masterCard.confirm_new_pin")}</p>
        <input
          className="text-input"
          type="password"
          value={confirmNewPin}
          min="0"
          pattern="[0-9]"
          onChange={(e: any) => setConfirmNewPin(e.target?.value)}
        />
      </div>

      <div className="mt-lg" onClick={() => handleConfirm()}>
        <button className="button-gradient" disabled={isLoading}>
          {isLoading ? <LoadingIcon size={"25"} /> : t("button.submit")}
        </button>
      </div>

      <div className="mt-m">
        <div>
          <p className="content">{t("masterCard.note")}</p>
          <p className="content">
            <span className="mx-xs">&#8226;</span>
            {t("masterCard.edit_atm_pin_note_1")}
          </p>
          <p className="content">
            <span className="mx-xs">&#8226;</span>
            {t("masterCard.edit_atm_pin_note_2")}
            <span className="pl-[2px] text-primary font-bold">
              {t("masterCard.edit_atm_pin_note_3")}
            </span>
            {t("masterCard.edit_atm_pin_note_4")}
          </p>
          <p className="content">
            <span className="mx-xs">&#8226;</span>
            {t("masterCard.edit_atm_pin_note_5")}
          </p>
        </div>
      </div>
    </div>
  );
};
