import {
  Container,
  LoadingIcon,
  openSuccessNotification,
  openWarningNotification,
} from "src/components";
import { Auth } from "src/components/auth";
import { CryptoAssetWalletSummary } from "src/components/crypto-asset-wallet-summary";
import { CryptoAssetDepositForm } from "src/components/crypto-asset-deposit-form";
import { CryptoAssetWithdrawForm } from "src/components/crypto-asset-withdraw-form";
import { TransactionList } from "src/components/transaction-list";
import { useTranslation } from "react-i18next";
import { ModalConfirmWithdrawCrypto } from "../components/modal-confirm-withdraw-crypto";
import { history } from "src/stores";
import * as Utils from "../utils";
import { useCryptoAssetHookStore } from "src/hooks/crypto-asset";

export const CryptoAsset = () => {
  const { t } = useTranslation();

  //TODO: update hooks to separate folder
  const {
    activeIdx,
    setActiveIdx,
    totalBalanceInUsd,
    totalBalanceInHkd,
    walletTokenList,
    tokenBalance,
    setTokenBalance,
    assetType,
    setAssetType,
    amount,
    setAmount,
    toAddress,
    setToAddress,
    handlingFees,
    modalOpen,
    setModalOpen,
    isLoading,
    setIsLoading,
    isFetching,
    network,
    setNetwork,
    depositAddress,
    setDepositAddress,
    token,
    setToken,
    transactions,
    allLoaded,
    getCryptoFeesCb,
    getTransactionsCb,
    withdrawCrypto,
  } = useCryptoAssetHookStore();

  const handleCryptoWithdraw = async () => {
    setIsLoading(true);
    const blockchain = assetType.split("_")[1];
    const validateAddr = Utils.isValidTokenAddress(toAddress, blockchain);

    // Check Required Fields
    if (!validateAddr)
      return openWarningNotification(t("message.invalid_crypto_address"));
    if (!assetType)
      return openWarningNotification(t("message.missing_asset_type"));
    if (!amount) return openWarningNotification(t("message.missing_amount"));
    if (!toAddress)
      return openWarningNotification(t("message.missing_to_address"));

    // Check Balance and amount
    if (!tokenBalance || tokenBalance < 0 || tokenBalance < amount)
      return openWarningNotification(t("message.insufficient_balance"));
    if (amount <= 0)
      return openWarningNotification(t("message.invalid_amount"));

    // Check Minimum Withdraw Amount
    const minimumAmount: number = Utils.getMinimumWithdrawalAmount(assetType);
    if (amount < minimumAmount)
      return openWarningNotification(
        t("message.submission_successful", { amount: minimumAmount })
      );
    const result = await withdrawCrypto({
      asset: assetType,
      amount: String(amount),
      toAddress,
    });

    setIsLoading(false);
    if (!result)
      return openWarningNotification(t("message.withdraw_crypto_failed"));
    openSuccessNotification(t("message.withdraw_crypto_success"));

    // Reset Form
    setAssetType("");
    setAmount(0);
    setToAddress("");
    // Close Modal
    setModalOpen(false);
    // Fetch Transaction
    getTransactionsCb({
      status: "crypto_all",
      type: "crypto_all",
      limit: 0,
    });
    return;
  };

  const resetDepositForm = () => {
    setToken(t("select_token"));
    setNetwork("");
    setDepositAddress("");
  };

  const resetWithdrawForm = () => {
    setAssetType("");
    setTokenBalance(0);
    setAmount(0);
    setToAddress("");
  };

  const handleActionIdx = (idx: number) => {
    if (idx === 2) return history.push("/convert");
    setActiveIdx(idx);
    // Reset Form For Action Changed
    resetWithdrawForm();
    resetDepositForm();
  };
  return (
    <Auth>
      <Container showNav={true} showMenu={true} scroll={true}>
        {!allLoaded && (
          <div className="flex flex-1 h-screen w-full justify-center items-center">
            <LoadingIcon color="#D41E44" />
          </div>
        )}
        {allLoaded && (
          <div className="flex flex-1 flex-col lg:flex-row p-[20px] lg:p-[30px] lg:pt-[100px]">
            <div>
              {/* Left Panel */}
              <div className="mb-[20px]">
                <CryptoAssetWalletSummary
                  balance={totalBalanceInUsd}
                  balanceInHkd={totalBalanceInHkd}
                  activeIdx={activeIdx}
                  handleActionIdx={handleActionIdx}
                  walletTokenList={walletTokenList}
                />
              </div>

              {activeIdx === 1 && (
                <CryptoAssetWithdrawForm
                  isFetching={isFetching}
                  activeIdx={activeIdx}
                  assetType={assetType}
                  setAssetType={setAssetType}
                  toAddress={toAddress}
                  setToAddress={setToAddress}
                  amount={amount}
                  setAmount={setAmount}
                  tokenBalance={tokenBalance}
                  walletTokenList={walletTokenList}
                  setModalOpen={setModalOpen}
                  getCryptoFeesCb={getCryptoFeesCb}
                />
              )}

              {activeIdx === 0 && (
                <CryptoAssetDepositForm
                  activeIdx={activeIdx}
                  network={network}
                  setNetwork={setNetwork}
                  token={token}
                  setToken={setToken}
                  depositAddress={depositAddress}
                  setDepositAddress={setDepositAddress}
                />
              )}
            </div>

            {/* Right Panel */}
            {/* Transactions */}
            <div className="mt-[20px] lg:mt-[0px] ml-[0px] lg:ml-[20px]">
              {transactions && (
                <TransactionList
                  type={"crypto"}
                  transactions={transactions}
                  getTransactionsCb={getTransactionsCb}
                />
              )}
            </div>

            {/* Confirm Modal */}
            <ModalConfirmWithdrawCrypto
              isFetching={isFetching}
              isLoading={isLoading}
              modalOpen={modalOpen}
              assetType={assetType}
              amount={amount}
              toAddress={toAddress}
              fees={handlingFees}
              handleClose={() => setModalOpen(false)}
              handleConfirm={handleCryptoWithdraw}
            />
          </div>
        )}
      </Container>
    </Auth>
  );
};
