export const getCurrencyIcon = (currency: string) => {
  let src = "/assets/hkd.png";
  switch (currency) {
    case "BTC":
    case "Bitcoin":
      src = "/assets/btc.png";
      break;
    case "ETH":
    case "Ethereum":
      src = "/assets/eth.png";
      break;
    case "USDT":
    case "USDT_ERC":
    case "USDT_TRC":
      src = "/assets/usdt.png";
      break;
    case "BNB":
      src = "/assets/bnb.png";
      break;
    case "MATIC":
      src = "/assets/matic.png";
      break;
    case "EUR":
      src = "/assets/eur.png";
      break;
    case "RMB":
      src = "/assets/rmb.png";
      break;
    case "USD":
      src = "/assets/usd.png";
      break;
    case "SGD":
      src = "/assets/sgd.png";
      break;
    case "USDC":
    case "USDC_ERC":
      src = "/assets/usdc.png";
      break;
    default:
      break;
  }
  return src;
};

export const getTokenName = (value: string) => {
  switch (value) {
    case "USDT_ERC":
    case "USDT_TRC":
      return "USDT";
    case "USDC_ERC":
      return "USDC";
    default:
      return "";
  }
};
