import { useCallback, useEffect, useState } from "react";
import { Container, LoadingIcon } from "src/components";
import { Auth } from "src/components/auth";
import { TransactionList } from "src/components/transaction-list";
import { TransferOutForm } from "src/components/transfer-out-form";
import { TransferOutPanel } from "src/components/transfer-out-panel";
import { useTransactionStore, useUserStore } from "src/stores";

export const TransferOut = () => {
  const [transactions, setTransactions] = useState<any | null>(null);
  const [amount, setAmount] = useState<number>(0);
  const [currency, setCurrency] = useState<string | null>("HKD");
  const [bankType, setBankType] = useState<string>("local");
  const [allLoaded, setAllLoaded] = useState(true);

  const { getTransactions } = useUserStore();
  const { transferOut } = useTransactionStore();

  const getTransactionsCb = useCallback(
    async (filter = { status: "all", type: "transfer-out" }) => {
      const result = await getTransactions(filter);
      if (!result || result !== "object") return setTransactions([]);
      setTransactions(result);
    },
    [getTransactions, setTransactions]
  );

  useEffect(() => {
    getTransactionsCb();
  }, [getTransactionsCb]);

  useEffect(() => {
    if (!transactions) return;
    setAllLoaded(true);
  }, [transactions, setAllLoaded]);

  const handleCurrencyClick = (value: string) => {
    setAmount(0);
    if (value !== "HKD") setBankType("international");
    if (value === "HKD") setBankType("local");
    setCurrency(value);
  };

  return (
    <Auth>
      <Container showNav={true} showMenu={true} scroll={true}>
        {!allLoaded && (
          <div className="flex flex-1 h-screen w-full justify-center items-center">
            <LoadingIcon color="#D41E44" />
          </div>
        )}

        {allLoaded && (
          <div className="flex flex-col lg:flex-row p-[20px] lg:p-[30px] lg:pt-[100px] ">
            <div>
              <div className="mb-[20px]">
                <TransferOutPanel
                  currency={currency}
                  handleCurrencyClick={handleCurrencyClick}
                />
              </div>
              <TransferOutForm
                transferOut={transferOut}
                currency={currency}
                amount={amount}
                setAmount={setAmount}
                bankType={bankType}
                setBankType={setBankType}
              />
            </div>

            {/* Transactions */}
            <div className="mt-[20px] lg:mt-[0px] ml-[0px] lg:ml-[20px]">
              <TransactionList
                type={"transfer-out"}
                transactions={transactions}
                getTransactionsCb={getTransactionsCb}
              />
            </div>
          </div>
        )}
      </Container>
    </Auth>
  );
};
