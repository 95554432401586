import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, LoadingIcon } from "src/components";
import { Auth } from "src/components/auth";
import { SecuritiesWithoutAccount } from "src/components/securities-without-account";
import { useUserStore } from "src/stores";
import { aesDecryption } from "src/utils/decryption";

export const Securities = () => {
  const { t } = useTranslation();
  const [account, setAccount] = useState<any | null>(null);
  const [allLoaded, setAllLoaded] = useState(true);
  const { getProfileV2, getSecuritiesAccount } = useUserStore();

  const getAccountCb = useCallback(async () => {
    const result = await getSecuritiesAccount();
    if (!result) return;
    setAccount(result);
  }, [getSecuritiesAccount, setAccount]);

  const getUserCb = useCallback(async () => {
    const user = await getProfileV2();
    if (!user) {
      setAccount({});
      return;
    }

    // Only Fetch Account If kyc_level >= 2
    if (user?.kyc_level && user?.kyc_level >= "2") {
      await getAccountCb();
      return;
    }
  }, [getProfileV2, setAccount, getAccountCb]);

  useEffect(() => {
    getUserCb();
  }, [getUserCb]);

  useEffect(() => {
    if (!account) return;
    setAllLoaded(true);
  }, [account, setAllLoaded]);

  return (
    <Auth>
      <Container showNav={true} showMenu={true} scroll={true}>
        {!allLoaded && (
          <div className="flex flex-1 h-screen w-full justify-center items-center">
            <LoadingIcon color="#D41E44" />
          </div>
        )}

        {allLoaded && (
          <div className="flex-1 flex flex-col lg:flex-row p-m lg:p-l lg:pt-[100px]">
            <div className="w-full h-full flex flex-col justify-center items-center bg-white rounded-m drop-shadow p-m">
              {account && (
                <div>
                  <h3 className="text-center normal-case">
                    {t("securities.account_info")}
                  </h3>
                  <div className="min-w-[300px] flex flex-col mt-l border rounded-s bg-form-bg p-m">
                    <div className={`${$rowStyle} pb-xs`}>
                      <p className={$labelStyle}>{t("securities.login_id")}</p>
                      <p className={$txtStyle}>{account?.login_id}</p>
                    </div>
                    <div className={`${$rowStyle}`}>
                      <p className={$labelStyle}>{t("securities.password")}</p>
                      <p className={$txtStyle}>
                        {aesDecryption(account?.password)}
                      </p>
                    </div>
                  </div>
                  {/* Instructions */}
                  <div className="mt-s">
                    <p className="content text-center">
                      {t("securities.instructions")}
                    </p>
                  </div>
                  <div className="mt-s">
                    <button
                      className="gradient"
                      onClick={() =>
                        window.open(
                          "https://ecgtrading.emperorcapital.com",
                          "_blank"
                        )
                      }
                    >
                      {t("button.go_to_platform")}
                    </button>
                  </div>
                </div>
              )}
              {!account && <SecuritiesWithoutAccount />}
            </div>
          </div>
        )}
      </Container>
    </Auth>
  );
};

const $labelStyle = "font-normal text-txt";
const $txtStyle = "text-primary font-bold ml-m";
const $rowStyle = "flex flex-row justify-between items-center";
