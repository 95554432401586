import { Container } from "../components";
import { LoginContainer } from "src/components/login-left-panel";

export const Application = (props: any) => {
  return (
    <Container showNav={false}>
      <div className="flex flex-col lg:flex-row">
        {/* Left Col - Banner, change lang & desc */}
        <LoginContainer from="apply-now" />
      </div>
    </Container>
  );
};
