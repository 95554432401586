import { useTranslation } from "react-i18next";
import {
  OVERSEAS_CHARGES,
  LOCAL_CHARGES,
  getTransferInCharge,
  SERVICE_CHARGES,
} from "src/config/charges";

export const TransferTotalReceiveAmount = (props: any) => {
  const { t } = useTranslation();
  const { type = null, amount, currency = "HKD" } = props;

  const charges = LOCAL_CHARGES;
  const serviceCharges = SERVICE_CHARGES;
  const additionalFees = OVERSEAS_CHARGES;

  return (
    <div className="flex flex-row items-center mt-s">
      <p className={`${txtStyle}`}>{t("card.final_transfer_amount")}</p>
      <p className={`${txtStyle} ml-xxxs text-primary`}>
        {amount > 0 && amount} {currency}
      </p>

      {/* TO BE UPDATED: Transfer In Charges Varies By Currency */}
      {type === "onshore-deposit" && (
        <p className={`${txtStyle} ml-xxxs`}>
          ({getTransferInCharge(currency)}% {t("handling_fee")})
        </p>
      )}

      {type === "transfer-in" && (
        <p className={`${txtStyle} ml-xxxs`}>
          ({getTransferInCharge(currency)}% {t("handling_fee")})
        </p>
      )}

      {type === "local" ||
        (type === "card" && (
          <p className={`${txtStyle} ml-xxxs`}>
            ({charges}% {t("handling_fee")})
          </p>
        ))}

      {type === "master-card" && (
        <p className={`${txtStyle} ml-xxxs`}>
          ({serviceCharges}% {t("handling_fee")})
        </p>
      )}

      {type === "international" && (
        <p className={`${txtStyle} ml-xxxs`}>
          ({charges}% + ${additionalFees} {currency}
          {t("handling_fee")})
        </p>
      )}
    </div>
  );
};

const txtStyle = `font-normal text-xs text-txt`;
