import { beforeUpload } from "src/utils";
import { UploadModal } from "./upload-modal";
import { MasterCardUploadButton } from "./master-card-upload-button";
import { useTranslation } from "react-i18next";

export const UploadPassport = (props: any) => {
  const { t } = useTranslation();
  const { passportImage, setPassportImage, isUpLoading, setIsUpLoading } =
    props;

  return (
    <div className="min-w-[250px] flex-1 mt-lg">
      <p className="content">{t("masterCard.passport_image")}</p>

      <div
        className={`px-s py-xs mt-xs border border-rd-border-upload bg-rd-upload-bg h-[220px]`}
      >
        <p className={`text-[#000]`}>{t("masterCard.passport_image")}</p>
        <p className={`content break-word`}>
          {t("masterCard.passport_document_type_required")}
        </p>
        <div className="master-card-upload rounded-xs drop-shadow-2xl overflow-hidden m-auto mt-s w-[195px] h-[120px] border border-[#D41E44]">
          <UploadModal
            beforeUpload={beforeUpload}
            setData={setPassportImage}
            setIsUpLoading={setIsUpLoading}
            title={t("masterCard.passport_image")}
          >
            {!passportImage && (
              <MasterCardUploadButton isUpLoading={isUpLoading} />
            )}

            {passportImage && (
              <img
                src={passportImage}
                alt="passport"
                style={{ width: "100%" }}
                className="object-cover"
              />
            )}
          </UploadModal>
        </div>
      </div>
    </div>
  );
};
