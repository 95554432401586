import { useEffect, useMemo } from "react";
import QRCodeStyling from "qr-code-styling";

interface IQrCanvas {
  method: string;
  paymentCode: string;
}

export const QrCanvas = (props: IQrCanvas) => {
  const { method, paymentCode } = props;

  const getImage = (method: string) => {
    switch (method) {
      case "unionpay":
        return "/assets/yunshanfu.png";
      case "alipay":
        return "/assets/alipay.png";
      case "wechatpay":
        return "/assets/wechatpay.png";
      default:
        return "";
    }
  };
  const getColor = (method: string) => {
    switch (method) {
      case "unionpay":
        return "#0066CC";
      case "alipay":
        return "#00A1E9";
      case "wechatpay":
        return "#1AAD19";
      default:
        return "#D41E44";
    }
  };

  const qrCode = useMemo(() => {
    if (!paymentCode) return null;
    const imageSrc = getImage(method);
    const imageColor = getColor(method);

    return new QRCodeStyling({
      width: 180,
      height: 180,
      type: "svg",
      data: paymentCode,
      image: imageSrc,
      dotsOptions: {
        color: imageColor,
        type: "dots",
      },
      backgroundOptions: {
        color: "#fff",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 0,
        imageSize: 0.5,
        hideBackgroundDots: false,
      },
      cornersSquareOptions: {
        type: "extra-rounded",
      },
      cornersDotOptions: {
        type: "dot",
      },
    });
  }, [method, paymentCode]);

  useEffect(() => {
    if (!qrCode) return;
    const qr = document.getElementById("qr-canvas");
    if (!qr) return;
    qr.innerHTML = "";
    qrCode.append(qr);
    return () => {
      qr.innerHTML = "";
    };
  }, [qrCode]);

  return <>{qrCode && <div id="qr-canvas"></div>}</>;
};
