import * as Utils from "../utils";
import { useTranslation } from "react-i18next";

interface IMyAssetBalanceProps {
  title: string;
  balance: number; 
  token:  string;
  handleAmountSelect: ()=> void;
}

export const MyAssetBalance = (props: IMyAssetBalanceProps) => {
  const { t } = useTranslation();
  const { title, balance, token, handleAmountSelect } = props;

  return (
    <div className="flex justify-between">
      <p className="content" style={{ fontSize: 12 }}>
        {title}
      </p>

      {balance > 0 && (
        <div
          className="flex flex-wrap cursor-pointer"
          onClick={() => handleAmountSelect()}
        >
          <p className="content" style={{ fontSize: 12 }}>
            {t("my_asset")}
          </p>
          <p className={`text-primary px-[3px]`}>
            {balance > 0 && Number(Number(balance).toFixed(4))}
          </p>
          <p className="text-primary">{Utils.getTokenLabel(token)}</p>
        </div>
      )}
    </div>
  );
};
