import { SelectDropdown } from "./select-dropdown";
import { LOCAL_BANK_LIST } from "../config/bank-details";
import { useTranslation } from "react-i18next";

export const TransferOutLocalBankDetailsForm = (props: any) => {
  const { t } = useTranslation();
  const { bankInfo, setBankInfo } = props;

  const handleFormChange = (val: any, name: string) => {
    let newBankFormObj: any = {
      ...bankInfo,
      type: "local",
      [name]: val,
    };
    setBankInfo(newBankFormObj);
  };

  return (
    <div>
      {/* Bank Name */}
      <div className="mt-s">
        <p className="content">{t("transfer_out.bank_name")}</p>
        <div className="transfer-out-select-dropdown mt-xs">
          <SelectDropdown
            width="100%"
            options={LOCAL_BANK_LIST}
            value={t(bankInfo.bankName)}
            handleOnChange={(val: any) => handleFormChange(val, "bankName")}
          />
        </div>
      </div>

      {/* Payee Account Name */}
      <div className="mt-s">
        <p className="content">{t("transfer_out.account_name")}</p>
        <input
          className="text-input"
          type="text"
          value={bankInfo.payeeAccountName}
          name="payeeAccountName"
          onChange={(e: any) =>
            handleFormChange(e.target?.value, "payeeAccountName")
          }
        />
      </div>

      {/* Payee Account No */}
      <div className="mt-s">
        <p className="content">{t("transfer_out.account_number")}</p>
        <input
          className="text-input"
          type="number"
          value={bankInfo.payeeAccountNo}
          name="payeeAccountNo"
          onChange={(e: any) =>
            handleFormChange(e.target?.value, "payeeAccountNo")
          }
        />
      </div>
    </div>
  );
};

