import { Router, Switch, Route, Redirect } from "react-router";
import { history } from "./stores";

// Components
import { Home } from "./pages";
import { Dashboard } from "./pages/dashboard";
import { TransferIn } from "./pages/transfer-in";
import { TransferOut } from "./pages/transfer-out";
// import { Card } from "./pages/card";
import { UpdatePassword } from "./pages/update-password";
import { CryptoAsset } from "./pages/crypto-asset";
import { Convert } from "./pages/convert";
import { Application } from "./pages/application";
import { Securities } from "./pages/securities";
import { MasterCard } from "./pages/master-card";
import { UpdateProfile } from "./pages/update-profile";
import { OnshoreWallet } from "./pages/onshore-wallet";

export default function Routers() {
  return (
    <Router history={history}>
      <Switch>
        <Route path={"/"} exact component={Home} />
        <Route path={"/apply-now"} exact component={Application} />
        <Route path={"/update-password"} exact component={UpdatePassword} />
        <Route path={"/update-profile"} exact component={UpdateProfile} />
        {/* <Route path={"/card"} exact component={Card} /> */}
        <Route path={"/master-card"} exact component={MasterCard} />
        <Route path={"/convert"} exact component={Convert} />
        <Route path={"/crypto-asset"} exact component={CryptoAsset} />
        <Route path={"/dashboard"} exact component={Dashboard} />
        <Route path={"/transfer-in"} exact component={TransferIn} />
        <Route path={"/transfer-out"} exact component={TransferOut} />
        <Route path={"/securities"} exact component={Securities} />
        <Route path={"/wallet"} exact component={OnshoreWallet} />
        <Route path="*">
          <Redirect from={"/"} to={"/"} />
        </Route>
      </Switch>
    </Router>
  );
}
