import { useCallback, useEffect, useMemo, useState } from "react";
import { Container, LoadingIcon } from "src/components";
import { Auth } from "src/components/auth";
import { TransactionList } from "src/components/transaction-list";
import { useUserStore } from "src/stores";
import { OnshoreWalletSummary } from "src/components/onshore-wallet-summary";
import { OnshoreWalletDetail } from "src/components/onshore-wallet-detail";
import { OnshoreWalletDeposit } from "src/components/onshore-wallet-deposit";
import { IOnshoreAccount } from "src/types/onshoreAccount";
import { IBalanceProps } from "src/types/profile";
import { ModalActivate } from "src/components/modal-activate";
import { ModalConfirmOpenAccount } from "src/components/modal-confirm-open-account";

export const OnshoreWallet = (props: any) => {
  const [onshoreAccounts, setOnshoreAccounts] = useState<IOnshoreAccount[]>([]);
  const [transactions, setTransactions] = useState<any | null>(null);
  const [allLoaded, setAllLoaded] = useState(false);
  const [action, setAction] = useState<string | null>(null); // detail, deposit, open_account
  const [balance, setBalance] = useState<IBalanceProps>({
    cardBalance: 0,
    cryptoBalance: 0,
    onshoreBalance: 0,
    securitiesBalance: 0,
  });
  const [user, setUser] = useState<any | null>(null);
  const [showActivateAccModal, setShowActivateAccModal] =
    useState<boolean>(false);

  const [showConfirmOpenAccModal, setConfirmOpenAccModal] =
    useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const [qrCode, setQrCode] = useState<string>("");
  const [errorMessage, showErrorMessage] = useState<string>("");

  const {
    getTransactions,
    getOnShoreAccounts,
    getProfileBalanceV2,
    getProfileV2,
  } = useUserStore();

  const getTransactionsCb = useCallback(
    async (filter: any) => {
      const result = await getTransactions(filter || {});
      if (!result) return setTransactions([]);
      setTransactions(result);
    },
    [getTransactions]
  );

  const getOnshoreAccountsCb = useCallback(async () => {
    const result: IOnshoreAccount[] = await getOnShoreAccounts();
    if (!result) return;
    setOnshoreAccounts(result);
    if (result.length === 0) return;
    const currency: string =
      result.find((item) => item?.currency === "HKD")?.currency ||
      result[0].currency;
    setAction(`detail_${currency.toLowerCase()}`);
  }, [getOnShoreAccounts, setOnshoreAccounts, setAction]);

  useEffect(() => {
    getProfileV2().then((result) => {
      if (!result) return;
      setUser(result);
    });
  }, [getProfileV2]);

  useEffect(() => {
    getOnshoreAccountsCb();

    getProfileBalanceV2().then((result) => {
      if (!result) return;
      setBalance(result);
    });

    // Get all Transactions
    getTransactionsCb({
      status: "all",
      type: "onshore",
      limit: 0,
    });
  }, [getOnshoreAccountsCb, getProfileBalanceV2, getTransactionsCb]);

  useEffect(() => {
    if (!balance) return;
    if (!transactions) return;
    setAllLoaded(true);
  }, [balance, transactions, setAllLoaded, getTransactionsCb]);

  const selectedAcc = useMemo(() => {
    if (!action || !onshoreAccounts) return null;
    // Select The First Acc
    const currency = action.split("_")[1];
    return onshoreAccounts.find(
      (item) => item?.currency.toLowerCase() === currency.toLowerCase()
    );
  }, [action, onshoreAccounts]);

  const resetFormCb = () => {
    if (action && action.includes("deposit")) {
      setAmount(0);
      showErrorMessage("");
      setQrCode("");
    }
    return;
  };

  return (
    <Auth>
      <Container showNav={true} showMenu={true} scroll={true}>
        {!allLoaded && (
          <div className="flex flex-1 h-screen w-full justify-center items-center">
            <LoadingIcon color="#D41E44" />
          </div>
        )}

        {allLoaded && (
          <div className="flex flex-1 flex-col lg:flex-row p-m lg:p-l lg:pt-[100px]">
            <div>
              {/* Left Panel */}
              <div className="mb-m">
                <OnshoreWalletSummary
                  onshoreAccounts={onshoreAccounts}
                  setAction={setAction}
                  balance={balance}
                  selectedAcc={selectedAcc}
                  user={user}
                  setShowActivateAccModal={setShowActivateAccModal}
                  setConfirmOpenAccModal={setConfirmOpenAccModal}
                  resetFormCb={resetFormCb}
                />
              </div>
              {/* Action Panels */}
              {action && action.includes("detail") && selectedAcc && (
                <OnshoreWalletDetail selectedAcc={selectedAcc} />
              )}
              {action && action.includes("deposit") && selectedAcc && (
                <OnshoreWalletDeposit
                  currency={selectedAcc?.currency}
                  reference={selectedAcc?.personal_account_number}
                  amount={amount}
                  qrCode={qrCode}
                  setAmount={setAmount}
                  setQrCode={setQrCode}
                  errorMessage={errorMessage}
                  showErrorMessage={showErrorMessage}
                />
              )}
            </div>

            {/* Right Panel */}
            {/* Transactions */}
            <div className="mt-m lg:mt-0 ml-0 lg:ml-m">
              {transactions && (
                <TransactionList
                  type={"all"}
                  transactions={transactions}
                  getTransactionsCb={getTransactionsCb}
                  setTransactions={setTransactions}
                />
              )}
            </div>
          </div>
        )}

        {/* Modals */}
        <ModalActivate
          isModalOpen={showActivateAccModal}
          setIsModalOpen={setShowActivateAccModal}
        />

        <ModalConfirmOpenAccount
          action={action}
          isModalOpen={showConfirmOpenAccModal}
          setIsModalOpen={setConfirmOpenAccModal}
          getOnshoreAccountsCb={getOnshoreAccountsCb}
        />
      </Container>
    </Auth>
  );
};
