import { useTranslation } from "react-i18next";

export const MasterCardInfo = (props: any) => {
  const { masterCard = {} } = props;
  const { t } = useTranslation();
  const {
    card_number,
    balance,
    cvv = t("masterCard.cvv_placeholder"),
    card_limit,
    card_status,
    available_limit,
    daily_atm_limit,
    daily_purchase_limit,
    currency_type = "HKD",
    expiry_month,
    expiry_year,
  } = masterCard;

  const getCardStatus = (cardStatus: string) => {
    if (cardStatus && Number(cardStatus) === 5)
      return t("masterCard.card_reported_lost");
    if (cardStatus && Number(cardStatus) < 5)
      return t("masterCard.card_activated");
    return t("masterCard.unknown");
  };

  return (
    <div className="w-full lg:w-[545px] bg-white rounded-s drop-shadow p-lg">
      <h3 className="normal-case">{t("masterCard.card_details")}</h3>

      {/* Card Info */}
      <div className="flex flex-col mx-l border rounded-xs bg-[#F0F2F5] mt-lg p-lg">
        <div className={`${$row} !pt-0`}>
          <p className="content">{t("masterCard.card_number")}</p>
          <p className="bank-info-label">{card_number}</p>
        </div>

        <div className={$row}>
          <p className="content">{t("masterCard.card_status")}</p>
          <p className="bank-info-label">{getCardStatus(card_status)}</p>
        </div>

        <div className={$row}>
          <p className="content">{t("masterCard.expiration_date")}</p>
          <p className="bank-info-label">
            {expiry_month}/{expiry_year}
          </p>
        </div>
        <div className={$row}>
          <p className="content">{t("masterCard.cvv")}</p>
          <p className="bank-info-label">{cvv}</p>
        </div>
        <div className={$row}>
          <p className="content">{t("masterCard.balance")}</p>
          <p className="bank-info-label">
            {Number(balance) * 100} {currency_type}
          </p>
        </div>

        <div className={$row}>
          <p className="content">{t("masterCard.card_limit")}</p>
          <p className="bank-info-label">
            {Number(card_limit) * 100} {currency_type}
          </p>
        </div>
        <div className={$row}>
          <p className="content">{t("masterCard.available_limit")}</p>
          <p className="bank-info-label">
            {Number(available_limit) * 100} {currency_type}
          </p>
        </div>
        <div className={$row}>
          <p className="content">{t("masterCard.daily_atm_limit")}</p>
          <p className="bank-info-label">
            {Number(daily_atm_limit) * 100} {currency_type}
          </p>
        </div>
        <div className={$row}>
          <p className="content">{t("masterCard.daily_purchase_limit")}</p>
          <p className="bank-info-label">
            {Number(daily_purchase_limit) * 100} {currency_type}
          </p>
        </div>
      </div>
    </div>
  );
};

const $row = "flex flex-row justify-between items-center pt-s";
