export const getMinimumExchangeUnit = (currency: string) => {
  switch (currency) {
    case "USD":
    case "USDT_ERC":
    case "USDT_TRC":
      return 20; // Minimum around 155 HKD
    case "USDC_ERC":
      return 50; // Minimum around 290 HKD
    case "HKD":
      return 100;
    default:
      break;
  }
  return 10;
};
