import { t } from "i18next";
import { openWarningNotification } from "src/components";

export const errorHandling = (e: any) => {
  const msg = e?.response?.data?.msg || null;
  switch (msg) {
    case "jwt_unauthorized":
      sessionStorage.removeItem("rd-trust-token");
      return;
    case "login_password_incorrect":
      openWarningNotification(t("message.login_password_incorrect"));
      break;
    case "password_incorrect":
      openWarningNotification(t("message.password_incorrect"));
      break;
    case "unable_to_get_profile":
      openWarningNotification(t("message.get_profile_failed"));
      break;
    case "username_not_found":
      // case "user_crypto_address_not_exist":
      openWarningNotification(t("message.username_not_found"));
      return;
    case "not_enough_fiat_balance":
      openWarningNotification(t("message.insufficient_balance"));
      return;
    case "card_is_not_verified":
      openWarningNotification(t("message.card_verification_failed"));
      break;
    // case "user_master_card_not_found":
    // openWarningNotification("message.master_card_not_found");
    // break;
    case "gc_card_not_found":
      openWarningNotification(t("message.gc_card_not_found"));
      break;
    case "validation_not_passed":
      openWarningNotification(t("message.validation_not_passed"));
      break;
    case "unknown_error":
      openWarningNotification(t("message.unknown_error"));
      break;
    default:
      break;
  }
  return msg;
};

export const isResultSuccess = (result: any) => {
  return result && result["data"] && result["data"]["success"];
};

export const refreshNewToken = (result: any) => {
  const token = result["data"]["refreshToken"] || null;
  if (!token) return;
  return sessionStorage.setItem("rd-trust-token", token);
};

export const setHeader = () => {
  let config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("rd-trust-token")}`,
    },
  };

  return config;
};
