import { useTranslation } from "react-i18next";
import { LoadingIcon } from "./loading-icon";

export const LoginPasswordForm = (props: any) => {
  const { t } = useTranslation();
  const { setStage, password, setPassword, handleLogin, loading } = props;
  return (
    <>
      <div>
        <p className="content">{t("password")}</p>
        <input
          className="text-input drop-shadow"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target?.value)}
        />
      </div>

      <div className="mt-[30px]">
        <button className="button-gradient" onClick={() => handleLogin()}>
          {loading ? <LoadingIcon size={"25"} /> : t("button.continue")}
        </button>
      </div>

      <div className="mt-[10px]">
        <button className="button-secondary" onClick={() => setStage("username")}>
          {t("button.goBack")}
        </button>
      </div>
    </>
  );
};