import { useTranslation } from "react-i18next";
import { getCurrencyIcon } from "src/utils";
import * as Utils from "../utils";

export const ModalConfirmConvertRow = (props: any) => {
  const { t } = useTranslation();
  const { label, value, amount } = props;

  const getSelectLabel = (type: string) => {
    if (type === "from") return t("from");
    return t("to");
  };

  return (
    <div className={$bgStyle}>
      <div>
        <p className={`label mb-xs`}>
          {getSelectLabel(label)}
        </p>
        <div className="flex items-col items-center min-w-[90px]">
          <div className="overflow-hidden bg-transparent rounded-full">
            <img
              src={getCurrencyIcon(value)}
              className="w-[20px] h-[20px] object-cover"
              alt="token-icon"
            />
          </div>
          <p className={`content ml-s`}>{Utils.getTokenLabel(value)}</p>
        </div>
      </div>

      <div className="flex flex-col items-end">
        <p className="label">{t("amount")}</p>
        {label === "from" && (
          <p className="text-[12px] text-error mt-xs">- {amount}</p>
        )}
        {label === "to" && (
          <p className="text-s text-[#389e0d] mt-xs">+ {amount}</p>
        )}
      </div>
    </div>
  );
};

const $bgStyle = "h-[110px] flex justify-between items-center border-rd-border rounded-xs bg-gradient-to-b from-[#F1F1F1] to-[#efefef4d] px-lg";
