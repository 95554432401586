import SignatureCanvas from "react-signature-canvas";
import { UploadId } from "./master-card-upload-Id";
import { UploadPassport } from "./master-card-upload-passport";
import { useTranslation } from "react-i18next";

export const MasterCardApplyCardFormBody = (props: any) => {
  const { t } = useTranslation();

  const {
    documentId,
    documentType,
    documentIdExpiryDate,
    faceImage,
    backImage,
    passportImage,
    isUpLoading,
    setFaceImage,
    setBackImage,
    setPassportImage,
    setIsUpLoading,
    sigCanvasRef,
    handleSignSave,
    handleSignClear,
  } = props;

  const getDocumentType = (txt: string) => {
    if (txt === "HKID") return t("masterCard.id_card");
    if (txt === "password") return t("masterCard.passport");
    return txt;
  };

  return (
    <div>
      <div className="mt-lg">
        <p className="content">{t("profile.document_type")}</p>
        <input
          className={`text-input bg-rd-document-type-bg`}
          type="text"
          value={getDocumentType(documentType)}
          disabled={true}
        />
      </div>

      <div className="flex mt-lg justify-between">
        <div className="min-w-[240px] mr-l">
          <p className="content">{t("profile.document_id")}</p>
          <input
            className={`text-input !bg-[#F0F2F5]`}
            type="text"
            value={documentId}
            disabled={true}
          />
        </div>

        <div className="min-w-[240px]">
          <p className="content">{t("profile.document_id_expiry_date")}</p>
          <input
            className={`text-input bg-rd-document-type-bg`}
            type="text"
            value={documentIdExpiryDate}
            disabled={true}
          />
        </div>
      </div>

      {documentType === "HKID" && (
        <UploadId
          faceImage={faceImage}
          backImage={backImage}
          isUpLoading={isUpLoading}
          setFaceImage={setFaceImage}
          setBackImage={setBackImage}
          setIsUpLoading={setIsUpLoading}
        />
      )}

      {documentType === "passport" && (
        <UploadPassport
          passportImage={passportImage}
          isUpLoading={isUpLoading}
          setPassportImage={setPassportImage}
          setIsUpLoading={setIsUpLoading}
        />
      )}

      <div className="mt-lg">
        <p className="content">{t("masterCard.signature")}</p>
        <div className="bg-rd-offWhite-bg h-[200px] mt-xs">
          <SignatureCanvas
            ref={sigCanvasRef}
            penColor="#D41E44"
            canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
            onEnd={() => handleSignSave()}
          />
          <div className="mt-lg flex justify-end">
            {/* <button className={$normalBtn} onClick={handleSignSave}>
              保存
            </button> */}
            <button className={`button-outline ml-m`} onClick={handleSignClear}>
              {t("button.clear")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
