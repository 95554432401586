import { useTranslation } from "react-i18next";

const FILES = [
  "Rich Dragon - Discretionary Trust Deed - v2.pdf",
  "Rich Dragon Trust Application Form_.pdf",
  "Rich Dragon_selfprove.pdf",
];

const ZIP_FILE = "Rich Dragon Trust Customer Identification.zip";

export const SecuritiesWithoutAccount = () => {
  const { t } = useTranslation();

  const openFile = (file: string) => {
    if (!file) return;
    window.open(`/assets/${file}`, "_target");
  };

  return (
    <div>
      <>
        <div className="w-[450px]">
          <div className="flex justify-center items-center bg-form-bg px-s py-s">
            <p>
              {t("securities.account_verification_required")}
              <span
                className="text-primary cursor-pointer pl-xxxs"
                onClick={() => openFile(ZIP_FILE)}
              >
                {t("securities.download_zip")}
              </span>
            </p>
          </div>

          <div className="mt-l">
            <h2 className="normal-case">{t("apply_now")}</h2>
          </div>
        </div>
        <ol className="mt-l">
          <li>
            {t("apply_step_1")}
            <br />
            {FILES.map((item, i) => {
              return (
                <div
                  className={$downloadBox}
                  key={i}
                  onClick={() => openFile(item)}
                >
                  <p className={$fileTxt}>{item}</p>
                  <p className="min-w-[80px] text-primary text-right">
                    {t("download")}
                  </p>
                </div>
              );
            })}
          </li>
          <li className="mt-xs">
            {t("apply_step_2")}
            <a
              className="ml-xxs text-primary hover:text-primary no-underline cursor-pointer"
              href={"mailto:info@doo.io"}
            >
              info@doo.io
            </a>
          </li>
          <li className="mt-xs">{t("apply_step_3")}</li>
          <li className="mt-xs">{t("apply_step_4")}</li>
        </ol>
      </>
    </div>
  );
};

const $downloadBox =
  "cursor-pointer flex flex-row items-center justify-between bg-form-bg px-s py-xs mt-xs";
const $fileTxt = "text-s font-[600]";
