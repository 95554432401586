import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { LoadingIcon } from "./loading-icon";
import { useTranslation } from "react-i18next";
import { ICryptoHandlingFeesProps } from "src/type/crypto";

interface ModalConfirmWithdrawCryptoProps {
  isFetching: boolean;
  isLoading: boolean;
  modalOpen: boolean;
  assetType: string;
  amount: number;
  toAddress: string;
  fees: ICryptoHandlingFeesProps;
  handleClose: () => void;
  handleConfirm: () => Promise<void>;
}

export const ModalConfirmWithdrawCrypto = (
  props: ModalConfirmWithdrawCryptoProps
) => {
  const { t } = useTranslation();
  const {
    isFetching,
    isLoading,
    modalOpen,
    assetType,
    amount,
    toAddress,
    fees,
    handleClose,
    handleConfirm,
  } = props;

  const labels = ["network", "amount", "to_address", "fees", "received_amount"];

  const getLabelValue = (
    label: string,
    asset: string,
    amount: number,
    toAddress: string,
    fees: ICryptoHandlingFeesProps
  ) => {
    const tokenName = asset.split("_")[0];
    const network = asset.split("_")[1];
    let blockchain = "";
    if (network === "TRC") blockchain = "TRON";
    if (network === "ERC" || network === "ETH") blockchain = "ETHEREUM";
    if (network === "BTC") blockchain = "BITCOIN";

    let name = label;
    let value = "";

    let color = "text-black";
    switch (name) {
      case "network":
        value = blockchain;
        break;
      case "amount":
        value = `- ${amount} ${tokenName}`;
        color = "text-error";
        break;
      case "to_address":
        value = toAddress;
        break;
      case "fees":
        const { handlingFee, currency } = fees;
        value = isFetching
          ? `${t("calculating")}`
          : `${handlingFee} ${currency}`;
        break;
      case "received_amount":
        value = `+ ${amount} ${tokenName}`;
        color = "text-success";
        break;
      default:
        break;
    }
    return <p className={`${color} font-[700]`}>{value}</p>;
  };

  return (
    <Modal
      title={``}
      visible={modalOpen}
      onOk={handleClose}
      onCancel={handleClose}
      footer={null}
      centered={true}
      className="rounded-xs"
    >
      <CloseButton handleCancel={handleClose} />
      <div className="flex flex-col justify-start align-start min-h-[220px]">
        <div>
          <h3 className="my-lg text-center normal-case">
            {t("crypto_asset.confirm_to_withdraw")}
          </h3>

          <p className="content mt-xl text-center">
            {t("crypto_asset.are_you_sure")}
          </p>
          {/* Body */}
          <div className="!bg-[#F0F2F5] h-auto my-m px-lg py-m rounded-xs">
            <div className="flex flex-col justify-between">
              {labels.map((label: string, i: number) => {
                return (
                  <div key={i}>
                    <div className="flex flex-row justify-between pt-s">
                      <p>{t(`crypto_asset.${label}`)}</p>
                      {getLabelValue(label, assetType, amount, toAddress, fees)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Submit Form Button */}
          <div className="mt-l">
            <button
              className="button-gradient"
              onClick={() => handleConfirm()}
              disabled={isLoading}
            >
              {isLoading ? <LoadingIcon size={"25"} /> : t("button.confirm")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
