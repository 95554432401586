import { CopyToClipboardIcon } from "./copy-to-clipboard-icon";
export const CryptoAssetWalletAddress = (props: any) => {
  const { address, gradient = false } = props;

  return (
    <div className="w-full">
      {address && (
        <div className="relative flex flex-1 items-center break-words">
          <div className="w-full lg:w-[330px] mr-m">
          <input
            className={`${
              gradient
                ? `bg-gradient-to-b from-[#F1F1F1] text-input`
                : `border text-input`
            }`}
            type="text"
            value={address}
            disabled={true}
          />
          </div>
          {/* Copy Icon */}
          <CopyToClipboardIcon txt={address} />
        </div>
      )}
    </div>
  );
};