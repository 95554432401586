import { t } from "i18next";
export const TRANSACTION_LIST = [
  {
    title: "兌換",
    amount: 5000,
    currency: "usdt",
    date: "Fri Apr 12 2023 19:08:55 GMT-0500 (HKT)",
  },
  {
    title: "兌換",
    amount: 1200,
    currency: "usdt",
    date: "Sat Apr 13 2023 19:08:55 GMT-0500 (HKT)",
  },
  {
    title: "轉入",
    amount: 500,
    currency: "hkd",
    status: "failed",
    date: "Sun Apr 14 2023 19:08:55 GMT-0500 (HKT)",
  },
  {
    title: "轉入",
    amount: 100,
    currency: "hkd",
    date: "Mon Apr 15 2023 19:08:55 GMT-0500 (HKT)",
  },
  {
    title: "轉入",
    amount: 1,
    currency: "usd",
    date: "Thur Apr 11 2022 19:08:55 GMT-0500 (HKT)",
  },
];

// export const TXN_TYPE_LIST: any = [
//   {
//     key: "0",
//     value: "all",
//     label: t("type.all"),
//   },
//   {
//     key: "1",
//     value: "transfer_in",
//     label: t("type.transfer_in"),
//   },
//   {
//     key: "2",
//     value: "transfer_out",
//     label: t("type.transfer_out"),
//   },
//   {
//     key: "3",
//     value: "convert",
//     label: t("type.convert"),
//   },
// ];

export const TXN_TYPE_LIST: any = [
  {
    key: "0",
    value: "all",
    label: "type.all",
  },
  {
    key: "1",
    value: "onshore_deposit",
    label: "type.onshore_deposit",
  },
  {
    key: "2",
    value: "onshore_withdraw",
    label: "type.onshore_withdraw",
  },
  {
    key: "4",
    value: "crypto_deposit",
    label: "type.crypto_deposit",
  },
  {
    key: "5",
    value: "crypto_withdraw",
    label: "type.crypto_withdraw",
  },
  {
    key: "6",
    value: "convert",
    label: "type.convert",
  },
];

export const TXN_STATUS_LIST: any = [
  {
    key: "0",
    value: "all",
    label: "status.all",
  },
  {
    key: "1",
    value: "pending",
    label: "status.pending",
  },
  {
    key: "2",
    value: "done",
    label: "status.done",
  },
  {
    key: "3",
    value: "fail",
    label: "status.fail",
  },
];

// Crypto
export const CRYPTO_TXN_TYPE_LIST: any = [
  {
    key: "0",
    value: "crypto_all",
    label: "type.all",
  },
  {
    key: "1",
    value: "deposit",
    label: t("type.deposit"),
  },
  {
    key: "2",
    value: "withdraw",
    label: t("type.withdraw"),
  },
  // {
  //   key: "3",
  //   value: "convert",
  //   label: t("type.convert"),
  // },
];

// Onshore Wallet
export const ONSHORE_WALLET_TYPE_LIST: any = [
  {
    key: "0",
    value: "onshore",
    label: "type.onshore",
  },
  {
    key: "1",
    value: "onshore_deposit",
    label: "type.onshore_deposit",
  },
  {
    key: "2",
    value: "onshore_withdraw",
    label: "type.onshore_withdraw",
  },
];
