import { useCallback, useEffect, useRef, useState } from "react";
import {
  Container,
  LoadingIcon,
  openWarningNotification,
} from "src/components";
import { Auth } from "src/components/auth";
import { TransactionList } from "src/components/transaction-list";
import {
  useCryptoAssetStore,
  useExchangeStore,
  useUserStore,
} from "src/stores";
import { ConvertForm } from "../components/convert-form";
import { CONVERT_GET_TOKEN_BALANCE_LIST } from "src/config/crypto-asset";
import { useTranslation } from "react-i18next";

export const Convert = (props: any) => {
  const { t } = useTranslation();
  const [fromCurrency, setFromCurrency] = useState<string>("USDT_ERC");
  const [fromAmount, setFromAmount] = useState<any | number>(0);
  const [userBalance, setUserBalance] = useState<number | null>(null);
  const [toCurrency, setToCurrency] = useState<string>("HKD");
  const [toAmount, setToAmount] = useState<any | number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allLoaded, setAllLoaded] = useState(false);
  const [transactions, setTransactions] = useState<any | null>(null);
  const [exchangeRate, setExchangeRate] = useState<any>();
  const [quoteId, setQuoteId] = useState<string | null>("");
  const [latestExchangeTime, setLatestExchangeTime] = useState<any>(null);
  const [timeCount, setTimeCount] = useState<any>(null);
  const { getTransactions } = useUserStore();
  const { getCryptoAccount } = useCryptoAssetStore();
  const { getQuotePrice, executeQuote } = useExchangeStore();

  const timerRef: any = useRef(null);

  const getExchangeRateCb = useCallback(async () => {
    const TIME_ALLOW: any = 1 * 15;
    const tokenName: any = fromCurrency.split("_")[0];
    let tokenNetwork: any = fromCurrency.split("_")[1];
    if (tokenNetwork === "ERC") tokenNetwork = "ETHEREUM";
    if (tokenNetwork === "TRC") tokenNetwork = "TRON";
    const body = {
      from: {
        currency: tokenName,
        type: "crypto",
        network: tokenNetwork,
        amount: fromAmount,
      },
      to: {
        currency: toCurrency,
        type: "fiat",
      },
    };
    setIsLoading(true);
    const result = await getQuotePrice(body);
    setIsLoading(false);
    if (!result)
      return openWarningNotification(t("message.quote_price_failed"));
    const { amount, rate, quote_id } = result;
    setQuoteId(quote_id);
    setIsLoading(true);

    const nowTime: any = new Date();
    setLatestExchangeTime(nowTime);
    setExchangeRate(rate);
    setToAmount(amount);
    // Timer Start From 15s
    setTimeCount(TIME_ALLOW);
    // const tokenPairExchangeRate = getExchangeRate(fromCurrency, toCurrency);
    // setToAmount(String(tokenPairExchangeRate * fromAmount));
    setIsLoading(false);
  }, [
    fromCurrency,
    toCurrency,
    fromAmount,
    getQuotePrice,
    setExchangeRate,
    setToAmount,
    setTimeCount,
    setIsLoading,
    t,
  ]);

  const getTransactionsCb = useCallback(
    async (filter: any) => {
      const result = await getTransactions(filter || {});
      if (!result || typeof result !== "object") return setTransactions([]);
      setTransactions(result);
    },
    [getTransactions, setTransactions]
  );

  const getCryptoAccountCb = useCallback(() => {
    if (CONVERT_GET_TOKEN_BALANCE_LIST.indexOf(fromCurrency) > -1) {
      getCryptoAccount().then((result) => {
        if (!result) return;
        const { balances } = result;
        if (!balances) return;
        const tokenName = fromCurrency.split("_")[0];
        let tokenNetwork: any = fromCurrency.split("_")[1]; // ERC or TRC
        if (tokenNetwork === "ERC") tokenNetwork = "ETHEREUM";
        if (tokenNetwork === "TRC") tokenNetwork = "TRON";
        const asset = balances.find(
          (item: any) =>
            item?.asset === tokenName && item.blockchain === tokenNetwork
        );
        setUserBalance(asset?.balance ? Number(asset?.balance) : 0);
      });
    }
    getTransactionsCb({
      status: "all",
      type: "convert",
      limit: 0,
    });
  }, [fromCurrency, getTransactionsCb, getCryptoAccount, setUserBalance]);

  useEffect(() => {
    getCryptoAccountCb();
  }, [getCryptoAccountCb]);

  useEffect(() => {
    if (!transactions) return;
    setAllLoaded(true);
  }, [transactions, setAllLoaded, getTransactionsCb]);

  useEffect(() => {
    if (timeCount > 0) {
      timerRef.current = setInterval(() => {
        setTimeCount((timeCount: number) => timeCount - 1);
      }, 1000);
    }
    // Auto Fetch Exchange Rate Every 15s
    if (timeCount === 1) {
      if (fromCurrency && toCurrency && Number(fromAmount) > 0)
        getExchangeRateCb();
    }
    return () => {
      clearInterval(timerRef.current);
    };
  }, [
    timeCount,
    fromCurrency,
    fromAmount,
    toCurrency,
    setTimeCount,
    getExchangeRateCb,
  ]);

  return (
    <Auth>
      <Container showNav={true} showMenu={true} scroll={true}>
        {!allLoaded && (
          <div className="flex flex-1 h-screen w-full justify-center items-center">
            <LoadingIcon color="#D41E44" />
          </div>
        )}

        {allLoaded && (
          <div className="flex flex-col lg:flex-row p-[20px] lg:p-[30px] lg:pt-[100px] ">
            <ConvertForm
              fromCurrency={fromCurrency}
              toCurrency={toCurrency}
              fromAmount={fromAmount}
              toAmount={toAmount}
              userBalance={userBalance}
              exchangeRate={exchangeRate}
              latestExchangeTime={latestExchangeTime}
              timeCount={timeCount}
              quoteId={quoteId}
              isLoading={isLoading}
              setTimeCount={setTimeCount}
              setFromAmount={setFromAmount}
              setToAmount={setToAmount}
              setFromCurrency={setFromCurrency}
              setToCurrency={setToCurrency}
              setIsLoading={setIsLoading}
              setQuoteId={setQuoteId}
              executeQuote={executeQuote}
              getExchangeRateCb={getExchangeRateCb}
              getCryptoAccountCb={getCryptoAccountCb}
            />
            {/* Transactions */}
            <div className="mt-[20px] lg:mt-[0px] ml-[0px] lg:ml-[20px]">
              <TransactionList
                type="convert"
                transactions={transactions}
                getTransactionsCb={getTransactionsCb}
              />
            </div>
          </div>
        )}
      </Container>
    </Auth>
  );
};
