import { CRYPTO_ASSET_WITHDRAW_FOOTNOTE } from "../config/crypto-asset";
import { SelectDropdown } from "./select-dropdown";
import { useTranslation } from "react-i18next";
import { MyAssetBalance } from "./my-asset-balance";
import { useEffect } from "react";
import { getMinimumWithdrawalAmount } from "src/utils";
import { ICryptoBalanceProps } from "src/type/crypto";

interface CryptoAssetWithdrawFormItemsProps {
  toAddress: string;
  setToAddress: (value: string) => void;
  amount: number;
  setAmount: (value: number) => void;
  assetType: string;
  setAssetType: (value: string) => void;
  options: Array<ICryptoBalanceProps>;
  tokenBalance: number;
}

export const CryptoAssetWithdrawFormItems = (
  props: CryptoAssetWithdrawFormItemsProps
) => {
  const { t } = useTranslation();
  const {
    toAddress,
    setToAddress,
    amount,
    setAmount,
    assetType,
    setAssetType,
    options,
    tokenBalance,
  } = props;

  useEffect(() => {
    if (Number(amount) > tokenBalance) return setAmount(tokenBalance);
  }, [amount, tokenBalance, setAmount]);

  return (
    <div className="flex-1 ml-0 lg:ml-s">
      {/* Select Token */}
      <div>
        <p className="content">
          {t("select_token")}
        </p>
        <div className="withdraw-crypto-select-dropdown mt-xs lg:w-[550px] w-full">
          <SelectDropdown
            width="100%"
            options={options}
            value={assetType || t("please_select")}
            handleOnChange={(val: string) => {
              setAssetType(val);
              setAmount(0);
              setToAddress("");
            }}
          />
        </div>
      </div>

      {/* Amount */}
      <div className="mt-m flex-1">
        <div className="w-full lg:w-[550px]">
          <MyAssetBalance
            title={t("crypto_asset.amount")}
            balance={tokenBalance}
            token={assetType}
            handleAmountSelect={() => setAmount(tokenBalance)}
          />
        </div>
        <div className="w-full lg:w-[550px]">
        <input
          className="text-input"
          type="number"
          value={amount}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setAmount(Number(e.target.value))
          }
          disabled={!assetType}
          />
        </div>
      </div>

      {/* To Address */}
      <div className="mt-m">
        <p className="content">
          {t("crypto_asset.to_address")}
        </p>
        
        <div className="w-full lg:w-[550px]">
        <input
          className="text-input"
          type="text"
          value={toAddress}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setToAddress(e.target.value)
          }
          disabled={!amount}
          />
        </div>

        {/* Footnote */}
        <div className="mt-s">
          {CRYPTO_ASSET_WITHDRAW_FOOTNOTE.map((note: string, i: number) => {
            return (
              <div className="flex items-center" key={i}>
                <span className="mx-xs text-s">&#8226;</span>
                <p className="label">
                  {t(`crypto_asset.${note}`)}
                  {i === 0 && (
                    <span className="px-[3px] text-primary">
                      {assetType &&
                        `${getMinimumWithdrawalAmount(assetType)} ${
                          assetType?.split("_")[0]
                        }`}
                    </span>
                  )}
                  {i === 1 && (
                    <span className="px-[3px] text-primary">
                      {amount > 0 && `${amount} ${assetType?.split("_")[0]}`}
                    </span>
                  )}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
