import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { LoadingIcon } from "./loading-icon";
import { useTranslation } from "react-i18next";

export const ModalEditProfileForm = (props: any) => {
  const { t } = useTranslation();
  const {
    formType,
    isLoading,
    newDisplayName,
    setNewDisplayName,
    password,
    newPassword,
    setPassword,
    setNewPassword,
    confirmNewPassword,
    setConfirmNewPassword,
    modalOpen,
    handleClose,
    handleOnModalConfirm,
  } = props;

  const getModalTitle = (type: string) => {
    if (!type) return "";
    if (type === "display_name") return t("display_name");
    if (type === "password") return t("account_password");
  };
  return (
    <Modal
      title={``}
      visible={modalOpen}
      onOk={handleClose}
      onCancel={() => handleClose(formType)}
      footer={null}
      centered={true}
      className="rounded-xs"
    >
      <CloseButton handleCancel={() => handleClose(formType)} />
      <div className="mx-auto justify-start align-start">
        <div>
          <h3 className="my-lg text-center normal-case">
            {`${t("edit")} ${getModalTitle(formType)}`}
          </h3>
        </div>
        <div className="mt-l">
          {/* Display Name Form Body */}
          {formType === "display_name" && (
            <div className="mt-l">
              <div>
                <p className="content">{t("display_name")}</p>
                <input
                  className="text-input"
                  type="text"
                  value={newDisplayName}
                  onChange={(e: any) => setNewDisplayName(e.target?.value)}
                />
              </div>
            </div>
          )}
          {/* Password Form Body */}
          {formType === "password" && (
            <div className="mt-l">
              <div>
                <p className="content">{t("old_password")}</p>
                <input
                  className="text-input"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target?.value)}
                />
              </div>

              <div className="mt-s">
                <p className="content">{t("new_password")}</p>
                <input
                  className="text-input"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target?.value)}
                />
              </div>

              <div className="mt-s">
                <p className="content">{t("confirm_new_password")}</p>
                <input
                  className="text-input"
                  type="password"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target?.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="w-full">
          <div
            className="mx-auto mt-xl"
            onClick={() => handleOnModalConfirm(formType)}
          >
            <button className="button-gradient">
              {isLoading ? <LoadingIcon size={"25"} /> : t("button.confirm")}
            </button>
          </div>

          <div className="mt-lg">
            <button
              className="button-secondary"
              onClick={() => handleClose(formType)}
            >
              {t("button.cancel")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
